import { modules, perms } from "../../api/codes";
import OrderList from "../../pages/orders/order_list/order_list";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteOrder = [
  {
    path: "/orders",
    content: (
      <ProtectedElement module={modules.Order} perm={perms.viewList} page>
        <OrderList />
      </ProtectedElement>
    ),
  },
];
