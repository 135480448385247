// import { Info } from "lucide-react";
import React from "react";

export function Form({ methods, children, onSubmit }) {
  const { handleSubmit } = methods;

  return <form onSubmit={handleSubmit(onSubmit)}>{children}</form>;
}

const FieldSetContainer = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        maxWidth: "400px",
      }}
    >
      {children}
    </div>
  );
};

export function FieldSet({ children }) {
  return <FieldSetContainer>{children}</FieldSetContainer>;
}

export function Field({ label, children, info }) {
  // const [tooltipOpen, setTooltipOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setTooltipOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setTooltipOpen(true);
  // };
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <div
        style={{
          flex: "1",
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        {/* {label && <div className="input-label">{label}</div>} */}
        {children}
      </div>
      {info && (
        <div>
          {/* <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              tabIndex={-1}
              title={info}
              // placement="top"
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton
                sx={{
                  color: "text.primary",
                  marginLeft: "-10px",
                  marginTop: "3px",
                }}
                onClick={handleTooltipOpen}
              >
                <Info />
              </IconButton>
            </Tooltip>
          </ClickAwayListener> */}
        </div>
      )}
    </div>
  );
}
