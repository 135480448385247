import { createToast } from "../components/Toast/ToastProvider";

const unknownError = (error) => {
  if (error?.code === "ERR_CANCELED") {
    return;
  }
  createToast("Something went wrong!", "error");
  console.error(error);
};

export default unknownError;
