import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import {
  OtherKYC,
  OtherKYCVerification,
} from "../../pages/seller/other_kyc/other_kyc";
import { SellerProfileList } from "../../pages/seller/profile_kyc/profile_list";
import { VerifySellerProfile } from "../../pages/seller/profile_kyc/verify_profile";
import Seller from "../../pages/seller/seller";
import NewSeller from "../../pages/seller/sellers/new_sellers";
import Sellers from "../../pages/seller/sellers/sellers";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteSeller = [
  {
    path: "/sellers",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.SellerCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <Seller />
      </ProtectedElement>
    ),
  },
  {
    path: "/sellers/sellers",
    content: (
      <ProtectedElement module={modules.Seller} perm={perms.viewList} page>
        <Sellers isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/sellers/sellers/new",
    content: (
      <ProtectedElement module={modules.Seller} perm={perms.add} page>
        <NewSeller isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/sellers/profile_kyc",
    content: (
      <ProtectedElement module={modules.ProfileKYC} perm={perms.viewList} page>
        <SellerProfileList isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/sellers/profile_kyc/verify/:id",
    content: (
      <ProtectedElement
        module={modules.ProfileKYC}
        perm={perms.verifySellerProfile}
        page
      >
        <VerifySellerProfile isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/sellers/other_kyc",
    content: (
      <ProtectedElement module={modules.OtherKYC} perm={perms.viewList} page>
        <OtherKYC isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/sellers/other_kyc/verify/:code/:id",
    content: (
      <ProtectedElement
        module={modules.OtherKYC}
        perm={perms.verifySellerOtherKYC}
        page
      >
        <OtherKYCVerification isGlobal={false} />
      </ProtectedElement>
    ),
  },
];
