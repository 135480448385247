export const idToname = (value, ids, params_key) => {
  const key = params_key ? params_key : "printable_name";
  if (!value) {
    return undefined;
  }
  if (!ids) {
    return "";
  }
  return ids.filter((d) => d?.id?.toString() === value?.toString())?.[0]?.[key];
};

export const idTonameMany = (values, ids, key) => {
  if (!values) {
    return undefined;
  }
  if (!ids) {
    return [];
  }
  return values.map((d) => idToname(d, ids, key));
};
