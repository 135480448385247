import { useRef, useState } from "react";
import "./sortBy.scss";
import { ReactComponent as DownArrow } from "../../images/arrowBlock.svg";
import { useOutsideClick } from "../Inputs/Inputs";

export function useSortBy({ options, defaultValue }) {
  const [value, setValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setOpen(false));
  return {
    value: value,
    SortByButton: (
      <div ref={ref} className="sort-by-wrapper">
        <div className="sort-by-button" onClick={() => setOpen(!open)}>
          Sort by : {options?.find((o) => o.value === value)?.name}
          <DownArrow height={8} width={8} />
        </div>
        {open && (
          <div className="sort-options">
            {options?.map((o, index) => (
              <div
                key={index}
                className="sort-option"
                onClick={() => {
                  setValue(o.value);
                  setOpen(false);
                }}
              >
                {o.name}
              </div>
            ))}
          </div>
        )}
      </div>
    ),
  };
}
