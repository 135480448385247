import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../Inputs/Inputs";
import { Input } from "semantic-ui-react";
import Loading from "../Loading/Loading";
import { useFetch } from "../../utils/useFetch";
import Pagination from "../Pagination/Pagination";
import { useModal } from "../Modaler";

export const APIDropdown = ({
  url,
  hasPagination,
  hasMultipleSelection,
  label,
  placeholder,
  onChange,
  required,
  disabled,
  value,
  error,
  onDelete,
  displayAs,
  parameterForSet = "id",
  parameterForGet = "printable_name",
  beforeRemoveMsg,
  apiData,
  module,
  onChangeFullData,
  runApi,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(undefined);
  const [search, setSearch] = useState("");
  const ref = useRef();
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();
  const internalFetchData = useFetch(
    url +
      (hasPagination
        ? `${url?.includes("?") ? "&" : "?"}limit=${limit}&offset=${offset}`
        : ""),
    module,
    runApi
  );
  const { data, loading, refetch } = apiData ? apiData : internalFetchData;

  const options = hasPagination ? data?.results || data?.result : data;
  const modaler = useModal();

  useEffect(() => {
    if (!loading) {
      setCount(data?.count);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!value) {
      setValues(undefined);
    } else if (value !== undefined) {
      setValues(value);
    }
  }, [value, setValues]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const set = (v) => {
    // setValues(v[parameterForSet]);
    onChange(v[parameterForSet]);
    if (onChangeFullData) {
      onChangeFullData(v);
    }
  };

  const unset = () => {
    // setValues(undefined);
    if (beforeRemoveMsg !== false) {
      modaler.opener(
        <div style={{ padding: "20px", width: "300px" }}>
          <div style={{ fontWeight: "bold" }}>
            {beforeRemoveMsg || "Are you sure ?"}
          </div>
          <div className="p-10" />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              gap: "10px",
              width: "min-content",
            }}
          >
            <div
              className="button btn-red"
              style={{
                padding: "3px 10px",
                width: "80px",
              }}
              onClick={() => {
                modaler.closer();
              }}
            >
              No
            </div>
            <div
              className="button"
              style={{ padding: "3px 10px", width: "80px" }}
              onClick={() => {
                modaler.closer();
                onChange(null);
              }}
            >
              Yes
            </div>
          </div>
        </div>
      );
    } else {
      onChange(null);
    }
  };

  const addValue = (v) => {
    if (values === undefined || values === "") {
      // setValues([v[parameterForSet]]);
      if (onChange) {
        onChange([v[parameterForSet]]);
      }
      return;
    }
    if (!AlreadyAdded(v)) {
      // setValues([...values, v[parameterForSet]]);
      if (onChange) {
        onChange([...values, v[parameterForSet]]);
      }
    }
  };

  const AlreadyAdded = (v) => {
    if (!hasMultipleSelection)
      return values ? values === v[parameterForSet] : false;
    if (values === undefined) return false;
    return (
      values.findIndex(
        (x) => x?.toString() === v[parameterForSet]?.toString()
      ) !== -1
    );
  };

  const removeValue = (v) => {
    if (values) {
      if (beforeRemoveMsg !== false) {
        modaler.opener(
          <div style={{ padding: "20px", width: "300px" }}>
            <div style={{ fontWeight: "bold" }}>
              {beforeRemoveMsg || "Are you sure ?"}
            </div>
            <div className="p-10" />
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                width: "min-content",
              }}
            >
              <div
                className="button btn-red"
                style={{
                  padding: "3px 10px",
                  width: "80px",
                }}
                onClick={() => {
                  modaler.closer();
                }}
              >
                No
              </div>
              <div
                className="button"
                style={{ padding: "3px 10px", width: "80px" }}
                onClick={() => {
                  modaler.closer();
                  const newValues = values.filter(
                    (x) => x?.toString() !== v?.toString()
                  );
                  if (onDelete) onDelete(v);
                  if (newValues.length === 0) {
                    // setValues(undefined);
                    if (onChange) {
                      onChange(undefined);
                    }
                  } else {
                    // setValues(newValues);
                    if (onChange) {
                      onChange(newValues);
                    }
                  }
                }}
              >
                Yes
              </div>
            </div>
          </div>
        );
      } else {
        const newValues = values.filter((x) => x?.toString() !== v?.toString());
        if (onDelete) onDelete(v);
        if (newValues.length === 0) {
          // setValues(undefined);
          if (onChange) {
            onChange(undefined);
          }
        } else {
          // setValues(newValues);
          if (onChange) {
            onChange(newValues);
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          "select-box" +
          (values === undefined ? " placeholder-shown" : "") +
          (disabled ? " disabled" : "")
        }
        ref={ref}
      >
        <label htmlFor={label}>
          {label} {required && label ? "*" : ""}
        </label>
        <div className={"spacer" + (open ? " active" : "")} />
        <div className={"options-container-multi" + (open ? " active" : "")}>
          <div className="option hidden">
            <input
              type="radio"
              className="radio"
              id={label}
              name={label}
              autoComplete="on"
              required={required && values === undefined}
              onInvalid={(e) => {
                e.preventDefault();
                setErrorMessage(e.target.validationMessage);
              }}
              onChange={(e) => {
                if (!!errorMessage) {
                  setErrorMessage(e.target.validationMessage);
                }
              }}
            />
          </div>
          {refetch && (
            <div className="select-all" onClick={() => refetch()}>
              Refresh
            </div>
          )}
          <div className="search-input">
            <Input
              type="text"
              placeholder="Search"
              icon="search"
              fluid
              value={search}
              autoComplete="on"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="options">
            {options && options?.length && options?.length > 0 ? (
              options
                .filter((o) =>
                  o[parameterForGet]
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                ?.map((o, idx) => {
                  if (AlreadyAdded(o)) return null;
                  return (
                    <div
                      key={idx}
                      className="option"
                      onClick={() => {
                        setErrorMessage("");
                        if (hasMultipleSelection) {
                          addValue(o);
                        } else {
                          set(o);
                          setOpen((v) => !v);
                        }
                      }}
                    >
                      <input type="radio" className="radio" autoComplete="on" />
                      {displayAs ? displayAs(o) : o[parameterForGet]}
                    </div>
                  );
                })
            ) : loading ? (
              <div style={{ scale: "0.7" }}>
                <Loading />
              </div>
            ) : (
              <div style={{ padding: "3px" }}>No data available</div>
            )}
          </div>
          {hasPagination ? (
            <div style={{ paddingRight: "10px" }}>
              <Pagination
                count={count}
                setLimit={setLimit}
                setOffset={setOffset}
              />
            </div>
          ) : null}
        </div>

        <div
          className={
            "box-selected" +
            (values === undefined || values.length === 0
              ? " placeholder-shown"
              : "")
          }
          onClick={() => {
            if (!disabled) setOpen((v) => !v);
          }}
        >
          {values === undefined || values.length === 0
            ? placeholder
            : (hasMultipleSelection ? values : [values])?.map((v, idx) => {
                if (loading) {
                  return "...";
                }
                return (
                  <span
                    className={hasMultipleSelection ? "box-selected-item" : ""}
                    key={idx}
                    onClick={(e) => {
                      if (!disabled) {
                        if (hasMultipleSelection) {
                          e.stopPropagation();
                          removeValue(v);
                        }
                      }
                    }}
                  >
                    {(options &&
                      options?.length &&
                      options?.length > 0 &&
                      options?.find(
                        (xx) =>
                          xx[parameterForSet]?.toString() === v?.toString()
                      )?.[parameterForGet]) ||
                      v}{" "}
                    {!disabled && hasMultipleSelection && (
                      <span style={{ fontSize: "10px" }}>&#10005;</span>
                    )}
                  </span>
                );
              })}
          {!disabled && !hasMultipleSelection && value && (
            <span
              className="box-selected-item"
              style={{
                fontSize: "10px",
                marginLeft: "auto",
                marginRight: "25px",
                width: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!disabled) {
                  unset();
                }
              }}
            >
              &#10005;
            </span>
          )}
        </div>
        {!!errorMessage && values === undefined && (
          <div className="error-message">{errorMessage}</div>
        )}
      </div>
    </>
  );
};
