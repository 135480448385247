import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const Users = () => {
  return (
    <CardsPage items={items.find((item) => item.title === "Users").childrens} />
  );
};

export default Users;
