import { useHistory } from "react-router-dom";
import {
  country_url,
  delivery_boy_check_url,
  delivery_boy_sections_url,
  delivery_boy_temp_url,
  state_url,
} from "../../../api/urls";
import { useFetch } from "../../../utils/useFetch";
import { useSteps } from "../../../utils/useStep";
import { validators } from "../../../utils/zodValidations";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Field, FieldSet, Form } from "../../../components/form";
import { Input } from "../../../components/input";
import BackButton from "../../../components/BackButton/BackButton";
import { Header } from "semantic-ui-react";
import { FormSteps } from "../../../components/formSteps";
import { api } from "../../../api/api";
import { serialize } from "../../../utils/serialize";
import unknownError from "../../../utils/unknownError";
import { useToast } from "../../../components/Toast";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../../components/Loading/Loading";
import { TitleLine } from "../../../components/lineTitle";

export function NewPendingRegistration({ edit, view }) {
  const title = "Add user";
  const apiUrl = delivery_boy_temp_url;
  const backUrl = "/logistics/pending_registrations";
  const { step, setStep, next, canGoToStep } = useSteps(6, {
    freeMoving: true,
  });
  const countryData = useFetch(country_url);
  const stateData = useFetch(state_url);
  const history = useHistory();

  const schemas = [
    z.object({
      first_name: validators.stringRequired,
      last_name: validators.stringRequired,
      dob: validators.dateRequired,
      photo: validators.imageRequired,
      country: validators.numberRequired,
      mobile_number: validators.numberRequired,
    }),
    // .superRefine((values, ctx) => {
    //   if (countryData?.data) {
    //     const country = countryData.data.find((c) => c.id === values.country);
    //     if (
    //       country?.country_regex &&
    //       !RegExp(country?.country_regex).test(
    //         `${country?.country_mobile_code}${values.mobile_number}`
    //       )
    //     ) {
    //       ctx.addIssue({
    //         message: "Invalid mobile no",
    //         path: ["mobile_number"],
    //       });
    //     }
    //   }
    // }),
    z.object({
      address: validators.stringRequired,
      landmark: validators.stringRequired,
      state: validators.numberRequired,
      city: validators.stringRequired,
      country_address: validators.numberRequired,
      pincode: validators.numberRequired,
    }),
    z.object({
      bank_name: validators.stringRequired,
      account_holder_name: validators.stringRequired,
      ifsc_code: validators.stringRequired,
      acccount_number: validators.stringRequired,
    }),
    z.object({ vehicle: validators.stringRequired }),
    z.any(),
  ];
  const methods = useForm({
    resolver: zodResolver(schemas[step]),
    shouldFocusError: true,
  });

  const [isGetting, setIsGetting] = useState(true);
  const params = useParams();
  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: module },
          });
          const data = res.data?.[0]?.form_data || {};
          data.id = res.data?.[0]?.id;
          data.country_mobile_code = data?.mobile_number?.slice(0, 2);
          data.mobile_number = data?.mobile_number?.slice(2);
          const documents = [];
          for (let i = 0; i < Object.keys(data).length; i++) {
            if (data[`documents[${i}][doc_name]`]) {
              documents.push({
                doc_file: data[`documents[${i}][doc_file]`],
                doc_name: data[`documents[${i}][doc_name]`],
                doc_type: data[`documents[${i}][doc_type]`],
                doc_expiry: data[`documents[${i}][doc_expiry]`],
                doc_number: data[`documents[${i}][doc_number]`],
                doc_section: data[`documents[${i}][doc_section]`],
              });
              delete data[`documents[${i}][doc_name]`];
              delete data[`documents[${i}][doc_file]`];
              delete data[`documents[${i}][doc_type]`];
              delete data[`documents[${i}][doc_expiry]`];
              delete data[`documents[${i}][doc_number]`];
              delete data[`documents[${i}][doc_section]`];
            }
          }
          documents.forEach((d) => {
            data[`${d.doc_name}_number`] = d.doc_number;
            data[`${d.doc_name}_file`] = d.doc_file;
            data[`${d.doc_name}_expiry`] = d.doc_expiry;
          });
          methods.reset(data);

          setIsGetting(false);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view, methods, apiUrl]);

  const loading = methods.formState.isSubmitting;
  const toast = useToast();
  const fieldData = useFetch(delivery_boy_sections_url);
  const steps = [
    {
      title: "Personal info",
      onSubmit: async () => {
        try {
          const data = methods.getValues();
          const res = await api.post(delivery_boy_check_url, {
            mobile_no: `${data.country_mobile_code}${data.mobile_number}`,
            country: data.country_mobile_code,
          });
          if (res.status === 200) {
            next();
          } else {
            toast.open("Mobile number already exists", "error");
          }
        } catch (e) {
          if (e.response.status === 400 && e.response?.data?.data) {
            toast.open(e.response.data.data, "error");
          } else {
            unknownError(e);
          }
        }
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Name"
            info="Your first name"
            name="first_name"
            placeholder="Enter name"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Last name"
            info="Your last name"
            name="last_name"
            placeholder="Enter last name"
            required
            disabled={view}
          />
          <Input.Date
            methods={methods}
            label="Date of birth"
            name="dob"
            placeholder="Enter last name"
            required
            disabled={view}
          />
          <Input.Select
            methods={methods}
            label="Country"
            name="country"
            placeholder="Select country"
            options={countryData.data}
            loading={countryData.loading}
            onChangeFullData={(d) => {
              methods.setValue("country_mobile_code", d.country_mobile_code);
            }}
            required
            disabled={view}
          />
          <Input.Number
            methods={methods}
            label="Mobile no."
            name="mobile_number"
            placeholder="Enter mobile number"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload photo"
            name="photo"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Email id"
            name="email_id"
            placeholder="Enter your email id"
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Address details",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Address"
            name="address"
            placeholder="Street | bld | area | plot"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Landmark"
            name="landmark"
            placeholder="Enter landmark"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="City / town"
            name="city"
            placeholder="City / town"
            disabled={view}
            required
          />
          <Input.Select
            methods={methods}
            label="Select state"
            name="state"
            placeholder="Select state"
            options={stateData.data}
            loading={stateData.loading}
            required
            disabled={view}
          />
          <Input.Select
            methods={methods}
            label="Select country"
            name="country_address"
            placeholder="Select country"
            options={countryData.data}
            loading={countryData.loading}
            required
            disabled={view}
          />
          <Input.Number
            methods={methods}
            label="Pincode"
            name="pincode"
            placeholder="Enter pincode"
            required
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Bank details",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Bank name"
            name="bank_name"
            placeholder="Enter bank name"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Account holder name"
            name="account_holder_name"
            placeholder="Enter Account holder name"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Account number"
            name="acccount_number"
            placeholder="Enter Account number"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="IFSC Code"
            name="ifsc_code"
            placeholder="Enter IFSC code"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="UPI ID"
            name="upi_id"
            placeholder="Enter Account number"
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Select vehicle",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Radio
            methods={methods}
            label="Select vehicle"
            name="vehicle"
            options={[
              { id: "Motorcycle", printable_name: "Motorcycle" },
              { id: "Car", printable_name: "Car" },
            ]}
            required
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Upload documents",
      onSubmit: async () => {
        const fields = fieldData.data
          .map((d) => d.docs.map((doc) => ({ ...doc, section: d.section.id })))
          .flat();
        const formData = methods.getValues();
        const data = methods.getValues();
        const documents = fields.map((field) => {
          delete data[`${field.doc_name}_number`];
          delete data[`${field.doc_name}_expiry`];
          delete data[`${field.doc_name}_file`];
          return {
            doc_name: field.doc_name,
            doc_number: formData[`${field.doc_name}_number`] || "",
            doc_expiry: formData[`${field.doc_name}_expiry`] || null,
            doc_file: formData[`${field.doc_name}_file`] || null,
            doc_type: field.id || null,
            is_verified: false,
            doc_kyc: field.doc_kyc,
            doc_certificate: field.doc_certificate,
            doc_section: field.section,
          };
        });
        if (edit) {
          data.mobile_number = `${data.country_mobile_code}${data.mobile_number}`;
          data.documents = documents;
          try {
            const response = await api.patch(
              `${apiUrl}${data.id}/`,
              serialize({ form_data: data })
            );
            if (response.status === 200) {
              toast.open("Edited data successfully", "success");
              history.push(backUrl);
            } else {
              unknownError();
            }
          } catch (e) {
            unknownError();
          }
        } else {
          const data = methods.getValues();
          data.mobile_number = `${data.country_mobile_code}${data.mobile_number}`;
          data.documents = documents;
          try {
            const response = await api.post(apiUrl, serialize(data));
            if (response.status === 200) {
              toast.open("Added data successfully", "success");
              history.push(backUrl);
            } else {
              unknownError();
            }
          } catch (e) {
            unknownError();
          }
        }
      },
      body: (
        <>
          <DocumentSection
            methods={methods}
            view={view}
            fieldData={fieldData}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
      </div>
      <div style={{ padding: "10px" }} />
      {isGetting ? (
        <Loading />
      ) : (
        <Form methods={methods} onSubmit={steps[step].onSubmit}>
          <FormSteps
            currentStep={step}
            setCurrentStep={setStep}
            steps={steps}
            canGoToStep={canGoToStep}
            submit={async () => {
              return true;
              // if (!view) {
              //   const valid = await methods.trigger();
              //   return valid;
              // } else {
              //   return true;
              // }
            }}
          />
        </Form>
      )}
    </div>
  );
}

function DocumentSection({ methods, view, fieldData }) {
  if (fieldData.loading || !fieldData.data) {
    return <Loading />;
  }

  const fields = fieldData?.data?.map((d) => d.docs).flat();

  return (
    <>
      {fieldData.data.map((section) => {
        if (
          !section.docs.some((field) => fields.find((f) => f.id === field.id))
        )
          return null;
        return (
          <FieldSet key={section.section.id}>
            <TitleLine right>{section.section.printable_name}</TitleLine>
            <div style={{ padding: "0px" }} />
            {section.docs.map((field) => {
              if (!fields.find((f) => f.id === field.id)) return null;
              const name = field.doc_name;
              return (
                <Fragment key={field.id}>
                  {field.doc_num_require && (
                    <Input.Text
                      methods={methods}
                      label={`${name} number`}
                      name={`${name}_number`}
                      placeholder={`${name} number`}
                      disabled={view}
                      required
                    />
                  )}
                  {field.expiryrequire && (
                    <Input.Date
                      methods={methods}
                      label={`${name} expiry`}
                      name={`${name}_expiry`}
                      placeholder={`${name} expiry`}
                      disabled={view}
                      required
                    />
                  )}
                  {field.filerequire && (
                    <Input.File
                      methods={methods}
                      label={`${name} file`}
                      name={`${name}_file`}
                      placeholder={`${name} file`}
                      disabled={view}
                      required
                    />
                  )}
                </Fragment>
              );
            })}
            <div style={{ padding: "5px" }} />
          </FieldSet>
        );
      })}
    </>
  );
}
