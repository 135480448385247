import { useState, useEffect } from "react";
import { Popup } from "semantic-ui-react";
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import { items } from "../../data/routes";
import { perms } from "../../api/codes";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export default function Sidebar() {
  const [open, setOpen] = useState(() => {
    const savedState = localStorage.getItem("sidebarOpen");
    return savedState !== null
      ? JSON.parse(savedState)
      : window.innerWidth > 500;
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--sidebar-width",
      open ? "270px" : "80px"
    );
    localStorage.setItem("sidebarOpen", JSON.stringify(open));
  }, [open]);

  const toggleSidebar = () => setOpen(!open);

  return (
    <div className={`sidebar ${open ? "open" : ""}`}>
      <button className="sidebar-btn" onClick={toggleSidebar}>
        <ArrowIcon />
      </button>
      <div className="sidebar-body">
        {items.map((item, index) => (
          <ProtectedElement
            key={index}
            module={
              item.childrens
                ? item.childrens
                    .filter((ch) => ch.module)
                    .map((ch) => ch.module)
                : item.module
            }
            perm={perms.viewList}
          >
            <SidebarItem item={item} sidebarOpen={open} />
          </ProtectedElement>
        ))}
      </div>
    </div>
  );
}

function SidebarItem({ item, sidebarOpen }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(item.path);

  return (
    <Link
      to={item.path}
      className={`sidebar-item ${isActive ? "active" : ""} ${
        !sidebarOpen ? "bar-close" : ""
      }`}
    >
      <div className="sidebar-title">
        <div className="item-left">
          {item.icon && (
            <Popup
              className="sidebar-popup"
              content={item.title}
              position="right center"
              disabled={sidebarOpen}
              trigger={
                <button
                  className={`sidebar-icon ${!sidebarOpen && "bar-close"}`}
                >
                  <item.icon height={18} width={18} />
                </button>
              }
            />
          )}
          {sidebarOpen && (
            <span style={{ textTransform: "capitalize" }}>{item.title}</span>
          )}
        </div>
      </div>
    </Link>
  );
}
