import { tagon_customer_url } from "../../../api/urls";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { TextChip } from "../../../components/TextChip/TextChip";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { useModal } from "../../../components/Modaler";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { InputNumber } from "../../../components/Inputs/Inputs";
import { useFetch } from "../../../utils/useFetch";
import { TitleLine } from "../../../components/lineTitle";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import {
  userAddressFormatter,
  userNameFormatter,
} from "../../../utils/addresssFormatter";

export function B2BTransactionHistory() {
  const params = useParams();
  const backUrl = "/b2b_store/eligible_users";
  const apiUrl = `${tagon_customer_url}${params.id}/extenddue/`;
  const title = "Transaction History";
  const module = modules.B2BEligibleUsers;
  const modaler = useModal();

  const userData = useFetch(tagon_customer_url + params.id + "/");

  const { data, pagination } = useFetchWithPagination(apiUrl);

  const columns = [
    {
      headerName: "Trasaction Date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Transaction ID",
      field: "paid_order_id",
    },
    {
      headerName: "Balance Credit",
      field: "balance",
      renderCell: (params) => `${params.row.currency_sym}${params.value}`,
    },
    {
      headerName: "Used Credit",
      field: "amount",
      renderCell: (params) => `${params.row.currency_sym}${params.value}`,
    },
    {
      headerName: "Due Date",
      field: "duedate",
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Payment Proof",
      field: "transaction",
      renderCell: (params) =>
        params.value ? (
          <button
            className="btn-small"
            onClick={() => {
              modaler.opener(
                <div
                  style={{
                    height: "400px",
                    width: "400px",
                    maxWidth: "1000px",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="close-btn"
                    style={{ position: "absolute" }}
                    onClick={modaler.closer}
                  >
                    &#10005;
                  </div>
                  <img
                    src={params.value}
                    alt="Payment Proof"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>,
                false
              );
            }}
          >
            View
          </button>
        ) : (
          "N/A"
        ),
    },
    {
      headerName: "Status",
      field: "is_paid",
      renderCell: (params) => (
        <TextChip
          color={
            params?.value === "PAID"
              ? "#03A326"
              : params?.value === "EXTEND"
              ? "#FFA500"
              : "#b91c1c"
          }
          text={
            params?.value === "PAID"
              ? "Paid"
              : params?.value === "EXTEND"
              ? "Extended"
              : "Unpaid"
          }
        />
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <>
                {params.row.is_paid === "UNPAID" && (
                  <ProtectedElement module={module} perm={perms.b2bExtendDue}>
                    <div
                      className="option"
                      onClick={() => {
                        modaler.opener(
                          <ExtendDueDate
                            d={params.row}
                            refetch={data.refetch}
                            closer={modaler.closer}
                          />,
                          false
                        );
                      }}
                    >
                      <div className="purple-title">Extend due date</div>
                    </div>
                  </ProtectedElement>
                )}
                {params.row.is_paid === "UNPAID" && (
                  <ProtectedElement module={module} perm={perms.b2bExtendDue}>
                    <div
                      className="option"
                      onClick={() => {
                        modaler.opener(
                          <ChangePaidUnpaidStatus
                            d={params.row}
                            refetch={data.refetch}
                            closer={modaler.closer}
                          />,
                          false
                        );
                      }}
                    >
                      <div className="purple-title">
                        {params.row.is_paid === "PAID"
                          ? "Mark as Unpaid"
                          : "Mark as Paid"}
                      </div>
                    </div>
                  </ProtectedElement>
                )}
              </>
            );
          }}
        />
      ),
    },
  ];

  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton href={backUrl} />
      <div className="p-10" />
      <PersonalInfo data={userData} />
      <div className="p-10" />
      <CreditInfo data={userData} />
      <div className="p-10" />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
        noDataMessage="NO PENDING DUES"
      />
      {pagination}
    </>
  );
}

function ExtendDueDate({ d, refetch, closer }) {
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState("");

  const toast = useToast();
  return (
    <div style={{ padding: "40px", width: "500px", maxWidth: "100%" }}>
      <InputNumber
        disabled
        label="Enter credit amount"
        placeholder="Enter credit amount"
        value={d.balance}
      />
      <div style={{ padding: "5px" }} />
      <InputNumber
        label="Next credit settlement period (In Days)"
        placeholder="Next credit settlement period (In Days)"
        required
        value={days}
        onChange={(v) => setDays(v)}
      />
      <div style={{ padding: "10px" }} />
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          className="btn-red"
          onClick={() => {
            closer();
          }}
        >
          Cancel
        </button>
        <button
          className="btn"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await api.post(tagon_customer_url + d?.user + "/extenddue/", {
                id: d.id,
                days: days,
                pay_status: "EXTEND",
              });
              closer();
              refetch();
              toast.open("Status change", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setLoading(false);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function PersonalInfo({ data }) {
  return (
    <>
      <TitleLine right>Personal Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Image",
            renderCell: (params) => (
              <DashboardImage
                width="50px"
                height="50px"
                src={params?.row?.user?.profile_image}
                circle
              />
            ),
          },
          {
            headerName: "Name",
            renderCell: (params) => `${userNameFormatter(params?.row?.user)}`,
          },
          {
            headerName: "Date of Birth",
            renderCell: (params) =>
              params?.row?.user?.birthday
                ? moment(params?.row?.user?.birthday).format("DD-MM-YYYY")
                : "-",
          },
          {
            headerName: "Contact",
            renderCell: (params) => `${params?.row?.user?.mobile_number}`,
          },
          {
            headerName: "Address",
            renderCell: (params) => (
              <div className="wrap" style={{ maxWidth: "250px" }}>
                {userAddressFormatter(params?.row?.add?.[0])}
              </div>
            ),
          },
        ]}
        rows={[{ ...data?.data }]}
        loading={data.loading}
      />
    </>
  );
}

function CreditInfo({ data }) {
  return (
    <>
      <TitleLine right>Credit Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Assiged Credit",
            field: "user",
            renderCell: (params) => params?.value?.bnplCredit,
          },
          {
            headerName: "Used Credit",
            field: "credit",
            renderCell: (params) => params?.value?.[0]?.amount,
          },
          {
            headerName: "Remaining Credit",
            field: "user",
            renderCell: (params) =>
              (
                Number(params.row?.user?.bnplCredit) -
                  Number(params.row?.credit?.[0]?.amount) || 0
              ).toFixed(2),
          },

          {
            headerName: "Credit Period",
            field: "user",
            renderCell: (params) => `${params?.value?.credit_period} days`,
          },
        ]}
        rows={[{ ...data?.data }]}
        loading={data.loading}
      />
    </>
  );
}

function ChangePaidUnpaidStatus({ d, refetch, closer }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return (
    <div style={{ padding: "40px" }}>
      <div style={{ fontSize: "20px", fontWeight: "500" }}>Are you sure?</div>
      <div style={{ padding: "20px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <button
          className="btn-red"
          onClick={() => {
            closer();
          }}
        >
          No
        </button>
        <button
          className="btn"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await api.post(tagon_customer_url + d?.user + "/extenddue/", {
                id: d.id,
                pay_status: d?.is_paid === "PAID" ? "UNPAID" : "PAID",
              });
              closer();

              refetch();
              toast.open("Status change", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setLoading(false);
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
}
