import { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import "./more_info_modal.scss";
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { na, na2 } from "../../utils/na";
import moment from "moment";
import { horoscope_des_url } from "../../api/urls";
import { api } from "../../api/api";
import unknownError from "../../utils/unknownError";
import NewHoroscopeDescription from "./new_horoscope_description";
import Loading from "../../components/Loading/Loading";
import DeleteConfirmModal from "../masters/logistic_partner/delete_confirm_modal";
import { ProtectedElement } from "../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../api/codes";
import { DashboardImage } from "../../components/DashboardImage/DashboardImage";

const MoreInfoModal = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [hdes, setHdes] = useState();
  const [hdesRefetcher, setHdesRefetcher] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(horoscope_des_url + "?sign=" + data.code);
        setHdes(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.code, open, hdesRefetcher]);

  const hdesRefetch = () => {
    setHdesRefetcher((v) => !v);
  };
  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Annexture
            data={data}
            hdes={hdes}
            hdesRefetch={hdesRefetch}
            horoscopeCode={data.code}
          />
        </div>
      </div>
    </Modal>
  );
};

const Annexture = ({ data, hdes, hdesRefetch, horoscopeCode }) => {
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <HeadingSection data={data} />
      <ProfileDetailsSection data={data} />
      <DescriptionSection
        data={data}
        hdes={hdes}
        refetch={hdesRefetch}
        horoscopeCode={horoscopeCode}
      />
    </div>
  );
};

const HeadingSection = ({ data: d, actions }) => {
  return (
    <div className="heading-section">
      <div className="left-section">
        <h3>
          <b>Horoscope Details</b>
        </h3>
      </div>
    </div>
  );
};

const ProfileDetailsSection = ({ data: d, cancel, is_return }) => {
  return (
    <div className="product-info">
      <div className="image-section">
        <DashboardImage src={d.horoscope_image} height="100px" width="100px" />,
      </div>
      <div className="details-section">
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Name : </b>
              {na(d.horoscope_name)}
            </div>
            <div>
              <b>Start date : </b>
              {na2(d.start_date, moment(d.start_date).format("DD-MM-YYYY"))}
            </div>
            <div>
              <b>End date : </b>
              {na2(d.end_date, moment(d.end_date).format("DD-MM-YYYY"))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DescriptionSection = ({ data, hdes, refetch, horoscopeCode }) => {
  const tabs = ["Daily", "Weekly", "Monthly", "Yearly"];
  const [active, setActive] = useState(0);
  return (
    <Accordian
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
          }}
        >
          <div>Horoscope description</div>
        </div>
      }
      alwaysOpen
      body={
        <>
          <div className="ps-tabs">
            {tabs.map((tab, idx) => (
              <div
                key={idx}
                className={"ps-tab " + (active === idx ? "active" : "")}
                onClick={() => setActive(idx)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="p-5" />
          {tabs.map((tab, idx) => (
            <div
              key={idx}
              style={{ display: active === idx ? "block" : "none" }}
            >
              <ProtectedElement module={modules.Horoscope} perm={perms.add}>
                <NewHoroscopeDescription
                  refetch={refetch}
                  horoscopeTitle={data.id}
                  horoscopeInterval={tab.toUpperCase()}
                  horoscopeCode={horoscopeCode}
                  trigger={
                    <div
                      className="button"
                      style={{
                        padding: "2px 10px",
                        width: "fit-content",
                        marginLeft: "auto",
                      }}
                    >
                      Add new
                    </div>
                  }
                />
              </ProtectedElement>
            </div>
          ))}
          <div className="p-5" />
          {hdes === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {hdes &&
              hdes.filter(
                (x) => x.horoscope_entry === tabs[active].toUpperCase()
              ).length > 0
                ? hdes
                    .filter(
                      (x) => x.horoscope_entry === tabs[active].toUpperCase()
                    )
                    .map((d, idx) => (
                      <div className="address-box" key={idx}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "5px",
                          }}
                        >
                          <ProtectedElement
                            module={modules.Horoscope}
                            perm={perms.edit}
                          >
                            <NewHoroscopeDescription
                              refetch={refetch}
                              editData={d}
                              trigger={
                                <div className="i-btn">
                                  <EditIcon width={10} height={10} />
                                </div>
                              }
                              horoscopeCode={horoscopeCode}
                            />
                          </ProtectedElement>
                          <ProtectedElement
                            module={modules.Horoscope}
                            perm={perms.delete}
                          >
                            <DeleteConfirmModal
                              data={d}
                              refetch={refetch}
                              dropdownCloser={() => false}
                              route={horoscope_des_url}
                              appentToUrl={`?sign=${horoscopeCode}`}
                            />
                          </ProtectedElement>
                        </div>
                        <div>
                          <b>Date : </b>
                          {na2(
                            d.horoscope_date,
                            moment(d.horoscope_date).format("DD-MM-YYYY")
                          )}
                        </div>
                        {d?.horoscope_json && Array.isArray(d.horoscope_json)
                          ? d.horoscope_json.map((j, idx) => (
                              <>
                                {j.category ? (
                                  <div key={idx}>
                                    <b>{j.category} : </b>
                                    {j.description}
                                  </div>
                                ) : null}
                              </>
                            ))
                          : null}
                      </div>
                    ))
                : "No data entered yet."}
            </div>
          )}
        </>
      }
    />
  );
};

const Accordian = ({ head, body, footer, alwaysOpen = false }) => {
  const [open, setOpen] = useState(alwaysOpen);
  const height = 10000;

  return (
    <div className={"acc " + (open ? "open" : "")}>
      <div
        className="acc-head"
        onClick={() => (!alwaysOpen ? setOpen(!open) : null)}
      >
        <b>{head}</b>
        {!alwaysOpen && (
          <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
        )}
      </div>
      <div
        className={"acc-body " + (open ? "open" : "")}
        style={{ maxHeight: open ? height : 0 }}
      >
        {body}
        {footer}
      </div>
    </div>
  );
};

export default MoreInfoModal;
