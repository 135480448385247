import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import moment from "moment";
import _ from "lodash";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const LogDetails = ({ data: d }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window"
      style={{
        height: "90vh",
        width: "600px",
        maxWidth: "90vw",
      }}
    >
      <div
        className="close-btn"
        style={{ position: "absolute" }}
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>

      <div
        style={{
          height: "90vh",
          maxHeight: "90vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: "20px",
            borderBottom: "1px solid #00000080",
            paddingBottom: "10px",
          }}
        >
          <div className="p-10" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <TitleSubtitle title={d.username} subTitle={d.email} />
            <TitleSubtitle
              title={
                moment(d.created_date).format("DD-MM-YYYY") +
                " " +
                moment(d.created_date).format("hh:mm:ss A")
              }
              subTitle={d.remote_ip_address}
              align="right"
            />
          </div>
          <div className="p-10" />
          <div
            style={{
              fontSize: "16px",
            }}
          >
            <b>{d.action}d</b> {d.module_lable} <b>{d?.created_data?.id}</b>
          </div>
        </div>

        <div style={{ overflow: "auto", flex: 1, padding: "20px" }}>
          <Diff oldData={d.created_data} newData={d.change_data} />
        </div>
      </div>
    </Modal>
  );
};

function Diff({ oldData, newData }) {
  let diffs = compareObjects(oldData, newData);
  const ignoreKeys = ["updated_at", "created_at", "created", "updated", "id"];
  diffs = diffs.filter(
    (diff) => !ignoreKeys.find((ik) => diff?.path?.find((p) => p === ik))
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {diffs.length === 0 ? "No changes have been made" : ""}
      {diffs.map((diff, index) => (
        <div
          key={index}
          style={{ display: "flex", flexDirection: "column", gap: "5px" }}
        >
          <div style={{ color: "#0000004D" }}>
            {diff.action} <b>{diff.path.join(" > ")}</b>
          </div>
          {renderDiffContent(diff)}
        </div>
      ))}
    </div>
  );
}

function compareObjects(oldObj, newObj, path = []) {
  const diffs = [];

  const allKeys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);

  for (const key of allKeys) {
    const oldValue = oldObj[key];
    const newValue = newObj[key];
    const currentPath = [...path, key];

    if (!_.isEqual(oldValue, newValue)) {
      if (_.isPlainObject(oldValue) && _.isPlainObject(newValue)) {
        diffs.push(...compareObjects(oldValue, newValue, currentPath));
      } else if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        diffs.push({
          action: "Changed",
          path: currentPath,
          oldValue,
          newValue,
          isArray: true,
        });
      } else {
        diffs.push({
          action:
            oldValue === undefined
              ? "Added"
              : newValue === undefined
              ? "Removed"
              : "Changed",
          path: currentPath,
          oldValue,
          newValue,
        });
      }
    }
  }

  return diffs;
}

function renderDiffContent(diff) {
  if (diff.isArray) {
    return renderArrayDiff(diff);
  }

  switch (diff.action) {
    case "Added":
      return (
        <div>
          Added: <b>{formatValue(diff.newValue)}</b>
        </div>
      );
    case "Removed":
      return (
        <div>
          Removed: <b>{formatValue(diff.oldValue)}</b>
        </div>
      );
    case "Changed":
      return (
        <div>
          From <b>{formatValue(diff.oldValue)}</b> To{" "}
          <b>{formatValue(diff.newValue)}</b>
        </div>
      );
    default:
      return null;
  }
}

function renderArrayDiff(diff) {
  const oldArray = diff.oldValue || [];
  const newArray = diff.newValue || [];

  const added = newArray.filter((item) => !oldArray.includes(item));
  const removed = oldArray.filter((item) => !newArray.includes(item));
  // const changed = newArray.filter(
  //   (item) =>
  //     oldArray.includes(item) &&
  //     oldArray.indexOf(item) !== newArray.indexOf(item)
  // );

  return (
    <>
      {added.length > 0 && (
        <div>
          Added: <b>{added.map(formatValue).join(", ")}</b>
        </div>
      )}
      {removed.length > 0 && (
        <div>
          Removed: <b>{removed.map(formatValue).join(", ")}</b>
        </div>
      )}
      {/* {changed.length > 0 && (
        <div>
          Reordered: <b>{changed.map(formatValue).join(", ")}</b>
        </div>
      )} */}
    </>
  );
}

function formatValue(v) {
  if (_.isString(v) && v?.match(/\.(jpeg|jpg|gif|png|webp)$/) != null) {
    return (
      <div style={{ marginTop: "3px", width: "min-content" }}>
        <DashboardImage src={v} height="100px" width="100px" />
      </div>
    );
  }
  if (_.isString(v) && isDate(v)) {
    return moment(v).format("DD-MM-YYYY");
  }
  return _.toString(v);
}

function isDate(v) {
  const formats = ["DD-MM-YYYY", "DD/MM/YYYY"];
  return moment(v, formats, true).isValid();
}

function TitleSubtitle({ title, subTitle, align = "left" }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        alignItems: align === "right" ? "flex-end" : "flex-start",
      }}
    >
      <div>{title}</div>
      <div style={{ color: "#0000004D" }}>{subTitle}</div>
    </div>
  );
}

export default LogDetails;
