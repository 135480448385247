import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const Seller = () => {
  return (
    <CardsPage
      items={items.find((item) => item.title === "Sellers").childrens}
    />
  );
};

export default Seller;
