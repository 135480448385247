import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import "./Pagination.scss";

const Pagination = ({
  count,
  setLimit,
  setOffset,
  pages = [25, 50, 75, 100],
}) => {
  const [active, setActive] = useState(1);
  const [rpp, setRpp] = useState(pages[0]);
  const [cpages, setCpages] = useState(1);
  useEffect(() => {
    if (count) {
      setCpages(Math.ceil(count / rpp));
    }
  }, [rpp, count]);
  useEffect(() => {
    if (active > cpages) {
      setActive(cpages);
    }
  }, [cpages, active, setActive]);
  useEffect(() => {
    if (setLimit && setOffset) {
      setLimit(rpp);
      setOffset(rpp * (active - 1));
    }
  }, [active, setLimit, setOffset, rpp]);
  return (
    <div className="footer">
      <div>Total : {count}</div>
      <div>|</div>
      <div>Rows per page :</div>
      <Dropdown
        inline
        compact
        direction="left"
        options={pages
          .map((o) => {
            return {
              key: o,
              text: o,
              value: o,
            };
          })
          .filter((o, idx) => idx === 0 || pages[idx - 1] <= count)}
        value={rpp}
        onChange={(_e, { value }) => setRpp(value)}
        scrolling
        style={{ marginLeft: "-5px" }}
      />
      <div>|</div>

      <div className="pagination">
        {active !== 1 && (
          <button
            className="pagi-btn"
            onClick={() => {
              setActive((v) => (v > 1 ? v - 1 : 1));
            }}
          >
            {"<"}
          </button>
        )}
        <button
          className={"pagi-btn " + (1 === active ? "active" : "")}
          onClick={() => {
            setActive(1);
          }}
        >
          1
        </button>
        {active - 2 > 1 && (
          <button className="pagi-btn" style={{ cursor: "default" }}>
            ...
          </button>
        )}
        {active - 1 > 1 && (
          <button
            className="pagi-btn"
            onClick={() => {
              setActive(active - 1);
            }}
          >
            {active - 1}
          </button>
        )}
        {active !== 1 && active !== cpages && (
          <button className="pagi-btn active">{active}</button>
        )}
        {active + 1 < cpages && (
          <button
            className="pagi-btn"
            onClick={() => {
              setActive(active + 1);
            }}
          >
            {active + 1}
          </button>
        )}
        {active + 2 < cpages && (
          <button className="pagi-btn" style={{ cursor: "default" }}>
            ...
          </button>
        )}
        {cpages !== 1 && (
          <button
            className={"pagi-btn " + (cpages === active ? "active" : "")}
            onClick={() => {
              setActive(cpages);
            }}
          >
            {cpages}
          </button>
        )}
        {active !== cpages && (
          <button
            className="pagi-btn"
            onClick={() => {
              setActive((v) => (v < cpages ? v + 1 : cpages));
            }}
          >
            {">"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;
