import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./generate_bill.scss";
import "./more_info_modal.scss";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { order_list_url } from "../../../api/urls";
import { InputSelect } from "../../../components/Inputs/Inputs";

const ChangePaidStatus = ({
  data,
  refetch,
  actionRoute,
  actionName,
  statusCode,
  options,
}) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const toast = useToast();
  const [paidStatus, setPaidStatus] = useState(null);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title" style={{ width: "100px" }}>
          {actionName}
        </div>
      }
      className="modal-window small-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div
        className="modal-content"
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <InputSelect
          value={paidStatus}
          onChange={(v) => setPaidStatus(v)}
          required
          options={["PAID", "UNPAID", "PARTIAL PAID"]}
          placeholder="Select paid status"
          label="Paid status"
        />
        <div style={{ padding: "10px" }} />
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <button
            className="btn-red"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn"
            disabled={saving}
            onClick={() => {
              setSaving(true);
              if (data) {
                try {
                  api
                    .patch(`${order_list_url}${data.id}/${actionRoute}/`, {
                      paid_status: paidStatus,
                    })
                    .then((_res) => {
                      if (refetch) {
                        refetch();
                        toast.open("Action performed successfully", "success");
                        setOpen(false);
                      }
                      setSaving(false);
                    })
                    .catch((err) => {
                      unknownError(err);
                      setOpen(false);
                      setSaving(false);
                    });
                } catch (err) {
                  unknownError(err);
                  setOpen(false);
                  setSaving(false);
                }
              }
            }}
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePaidStatus;
