import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputImage,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import objToFormdata from "../../../utils/objToFormdata";
import { useToast } from "../../../components/Toast";
import {
  country_url,
  first_category_url,
  gfirst_category_url,
  ghbrand_url,
  gpbrand_url,
  gsecond_category_url,
  gthird_category_url,
  hbrand_url,
  pbrand_url,
  second_category_url,
  third_category_url,
} from "../../../api/urls";
import { urlTofile } from "../../../utils/urlTofile";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";
import { objToParams } from "../../../utils/objToParams";
import { modules } from "../../../api/codes";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  brand_image_upload: "",
  brand_name: "",
  is_global: false,
  is_premium: false,
  is_hot: false,
  // branded_products: "",
  country: "",
  category_first: "",
  category_second: "",
  category_third: "",
};

const module = modules.Brand;

const NewBrand = ({ edit, view, isGlobal, type }) => {
  const title =
    type === "premium"
      ? "Premium Brand"
      : type === "hot"
      ? "Luxury Brand"
      : "Brand";
  const currentRoute =
    type === "premium"
      ? "premium_brand"
      : type === "hot"
      ? "luxury_brand"
      : "brand";
  const apiUrl = isGlobal
    ? type === "premium"
      ? gpbrand_url
      : ghbrand_url
    : type === "premium"
    ? pbrand_url
    : hbrand_url;
  const backUrl = isGlobal
    ? `/global_store/${currentRoute}`
    : `/masters/${currentRoute}`;
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: module },
          });
          res.data.brand_image_upload = await urlTofile(
            res.data.brand_image_upload
          );
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view, apiUrl]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        delete newData.docs_data;
        if (isGlobal) {
          newData.is_global = true;
        }
        if (type === "premium") {
          newData.is_premium = true;
          newData.is_hot = false;
        }
        if (type === "hot") {
          newData.is_premium = false;
          newData.is_hot = true;
        }
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", objToFormdata(newData), {
                headers: {
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, objToFormdata(newData), {
                headers: {
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          onChange={(v) => set("description", v)}
        />
        <InputText
          label="Brand name"
          placeholder="Brand name"
          disabled={view}
          value={data.brand_name}
          error={errors["brand_name"]}
          required
          onChange={(v) => set("brand_name", v)}
        />
        <InputImage
          label="Brand image"
          placeholder="Brand image"
          disabled={view}
          value={data.brand_image_upload}
          error={errors["brand_image_upload"]}
          required
          onChange={(v) => set("brand_image_upload", v)}
        />
        <APIDropdown
          label="First category"
          placeholder="First category"
          required
          disabled={view}
          value={data.category_first}
          url={isGlobal ? gfirst_category_url : first_category_url}
          onChange={(v) => set("category_first", v)}
          error={errors["category_first"]}
          hasMultipleSelection
          module={module}
        />
        <APIDropdown
          label="Second category"
          placeholder="Second category"
          required
          disabled={view}
          value={data.category_second}
          url={
            (isGlobal ? gsecond_category_url : second_category_url) +
            "?" +
            objToParams({
              category_first: data.category_first,
            })
          }
          onChange={(v) => set("category_second", v)}
          error={errors["category_second"]}
          hasMultipleSelection
          module={module}
        />
        <APIDropdown
          label="Third category"
          placeholder="Third category"
          required
          disabled={view}
          value={data.category_third}
          url={
            (isGlobal ? gthird_category_url : third_category_url) +
            "?" +
            objToParams({
              category_second: data.category_second,
            })
          }
          onChange={(v) => set("category_third", v)}
          error={errors["category_third"]}
          hasMultipleSelection
          module={module}
        />
        <APIDropdown
          label="Country"
          placeholder="Country"
          required
          disabled={view}
          value={data.country}
          url={country_url}
          onChange={(v) => set("country", v)}
          error={errors["country"]}
          hasMultipleSelection
          module={module}
        />
        {/* {isGlobal && (
          <div className="two-inputs">
            <InputCheckBox
              disabled={view}
              value={data.is_premium}
              label="is premium"
              onChange={(v) => set("is_premium", v)}
            />
            <InputCheckBox
              disabled={view}
              value={data.is_hot}
              label="is hot"
              onChange={(v) => set("is_hot", v)}
            />
          </div>
        )} */}
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewBrand;
