import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const GlobalStore = () => {
  return (
    <CardsPage
      items={items.find((item) => item.title === "Global Store").childrens}
    />
  );
};

export default GlobalStore;
