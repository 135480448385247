import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import ContactUs from "../../pages/logs/contact_us/contact_us";
import ERPLogs from "../../pages/logs/erp_logs/erp_logs";
import FCMResponse from "../../pages/logs/fcm_response/fcm_response";
import Logs from "../../pages/logs/logs";
import OrderLogs from "../../pages/logs/order_logs/order_logs";
import PaymentLogs from "../../pages/logs/payment_logs/payment_logs";
import PGResponse from "../../pages/logs/pg_response/pg_response";
import PGWebhook from "../../pages/logs/pg_webhook/pg_webhook";
import RewardLog from "../../pages/logs/reward_log/reward_log";
import SMSResponse from "../../pages/logs/sms_response/sms_reponse";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteLogs = [
  {
    path: "/logs",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.LogCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <Logs />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/erp_logs",
    content: (
      <ProtectedElement module={modules.ERPLogs} perm={perms.viewList} page>
        <ERPLogs />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/reward_logs",
    content: (
      <ProtectedElement module={modules.RewardLog} perm={perms.viewList} page>
        <RewardLog />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/fcm_response",
    content: (
      <ProtectedElement module={modules.FcmResponse} perm={perms.viewList} page>
        <FCMResponse />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/pg_response",
    content: (
      <ProtectedElement module={modules.PgResponse} perm={perms.viewList} page>
        <PGResponse />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/sms_response",
    content: (
      <ProtectedElement module={modules.SmsResponse} perm={perms.viewList} page>
        <SMSResponse />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/pg_webhook",
    content: (
      <ProtectedElement module={modules.PgWebhook} perm={perms.viewList} page>
        <PGWebhook />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/payment_logs",
    content: (
      <ProtectedElement module={modules.PaymentLog} perm={perms.viewList} page>
        <PaymentLogs />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/order_logs",
    content: (
      <ProtectedElement module={modules.OrderLog} perm={perms.viewList} page>
        <OrderLogs />
      </ProtectedElement>
    ),
  },
  {
    path: "/logs/contact_us",
    content: (
      <ProtectedElement module={modules.ContactUs} perm={perms.viewList} page>
        <ContactUs />
      </ProtectedElement>
    ),
  },
];
