import { Header, Input, Icon } from "semantic-ui-react";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Pagination from "../../../components/Pagination/Pagination.js";
import { TableComponent } from "../../../components/Table/table.js";
// import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown.js";
import Loading from "../../../components/Loading/Loading.js";
import { api } from "../../../api/api.js";
import moment from "moment";
// import { ReactComponent as EditIcon } from "../../../images/edit.svg";
// import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
// import DeleteConfirmModal from "../../../components/ActionDropdown/DeleteConfirmModal.js";
import axios from "axios";
import { pls_url } from "../../../api/urls.js";
import MoreInfoModal from "./more_info_modal.js";
import unknownError from "../../../utils/unknownError.js";
import { na2 } from "../../../utils/na.js";
import BackButton from "../../../components/BackButton/BackButton.js";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage.js";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown.js";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import { modules, perms } from "../../../api/codes.js";
import { useModal } from "../../../components/Modaler";
import { useToast } from "../../../components/Toast";

const title = "Registered Users";
const apiUrl = pls_url;
const module = modules.RegisteredUsers;
// const module = modules.RegisteredUsers;
const currentRoute = "pls";
const searchFeild = "first_name";
const headers = [
  "Profile image",
  "Name",
  "Payroll Type",
  "Update date",
  "Update by",
  "Status",
  "Actions",
];
const bodyMap = (d, refetch, modaler) => {
  return [
    <>
      <DashboardImage src={d.profile_img} height="50px" width="50px" circle />
      <MoreInfoModal data={d} refetch={refetch} />
    </>,
    na2(
      d.first_name || d.last_name,
      <div className="wrap">{d?.first_name + " " + d?.last_name}</div>
    ),
    <div className={d.earning ? "active-green" : "active-blue"}>
      {d.earning ? "Part Time" : "Payroll"}
    </div>,
    moment(d.updated_at).format("DD-MM-YYYY"),
    "Admin",
    <div className={d.is_active ? "active-green" : "active-red"}>
      {d.is_active ? "Active" : "Inactive"}
    </div>,
    <ActionDropdown
      options={(closer) => {
        return (
          <>
            <ProtectedElement module={module} perm={perms.add}>
              <Link to={currentRoute + `/update_docs/${d.id}`}>
                <div
                  className="option"
                  onClick={() => {
                    closer();
                  }}
                >
                  <div className="purple-title">Update documents</div>
                </div>
              </Link>
            </ProtectedElement>
            <ProtectedElement module={module} perm={perms.edit}>
              <div
                className="option"
                onClick={() => {
                  modaler.opener(
                    <ChangePayrollStatus
                      d={d}
                      refetch={refetch}
                      closer={modaler.closer}
                    />,
                    false
                  );
                  closer();
                }}
              >
                <div className="purple-title">
                  Change to {d.earning ? "Payroll" : "Part Time"}
                </div>
              </div>
            </ProtectedElement>
          </>
        );
      }}
    />,
    // <ActionDropdown
    //   DeleteButton={({ setOpen }) => (
    //     <DeleteConfirmModal
    //       data={d}
    //       dropdownCloser={() => setOpen(false)}
    //       route={apiUrl}
    //       setTableData={() => refetch()}
    //     />
    //   )}
    //   // EditButton={({ setOpen }) => (
    //   //   <Link to={currentRoute + "/edit/" + d.id}>
    //   //     <div
    //   //       className="option"
    //   //       onClick={() => {
    //   //         setOpen(false);
    //   //       }}
    //   //     >
    //   //       <div className="icon-btn green-icon">
    //   //         <EditIcon />
    //   //       </div>
    //   //       <div className="green-title">Edit</div>
    //   //     </div>
    //   //   </Link>
    //   // )}
    //   // ViewButton={({ setOpen }) => (
    //   //   <Link to={currentRoute + "/view/" + d.id}>
    //   //     <div
    //   //       className="option"
    //   //       onClick={() => {
    //   //         setOpen(false);
    //   //       }}
    //   //     >
    //   //       <div className="icon-btn yellow-icon">
    //   //         <EyeIcon />
    //   //       </div>
    //   //       <div className="yellow-title">View</div>
    //   //     </div>
    //   //   </Link>
    //   // )}
    // />,
  ];
};

function ChangePayrollStatus({ d, refetch, closer }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  return (
    <div style={{ padding: "40px" }}>
      <div style={{ fontSize: "20px", fontWeight: "500" }}>
        Are you sure you want to change status to{" "}
        {d.earning ? "Payroll" : "Part Time"}?
      </div>
      <div style={{ padding: "20px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
        }}
      >
        <button className="btn-red" onClick={closer}>
          No
        </button>
        <button
          className="btn"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await api.patch(apiUrl + d.id + "/", {
                earning: !d.earning,
              });
              closer();
              refetch();
              toast.open("Payroll status changed successfully", "success");
            } catch (e) {
              unknownError(e);
            }
            setLoading(false);
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
}

const PLS = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  const modaler = useModal();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          apiUrl + "?limit=" + limit + "&offset=" + offset,
          { cancelToken: source.token }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, limit, offset]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
          {/* <Link to={currentRoute + "/new"}>
            <button className="btn">Add New</button>
          </Link> */}
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data.results
                ? data.results
                    .filter((d) =>
                      d[searchFeild]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((d) => {
                      return bodyMap(d, refetch, modaler);
                    })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination count={count} setLimit={setLimit} setOffset={setOffset} />
      ) : null}
    </>
  );
};

export default PLS;
