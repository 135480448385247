import SectionCard from "../../components/SectionCard/SectionCard";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";
import { perms } from "../../api/codes";

const CardsPage = ({ items }) => {
  return (
    <div className="cards">
      {items.map((c, idx) => {
        return (
          <ProtectedElement key={idx} module={c?.module} perm={perms.viewList}>
            <SectionCard Icon={c.icon} title={c.title} href={c.path} />
          </ProtectedElement>
        );
      })}
    </div>
  );
};

export default CardsPage;
