export const moveUpToError = async () => {
  let tries = 10;

  const real = () => {
    const doc = document.querySelector(".error-message");
    if (doc) {
      doc?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else {
      tries--;
      if (tries) {
        real();
      }
    }
  };
  setTimeout(real, 200);
};
