import { Header, Input, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Pagination from "../../../../components/Pagination/Pagination.js";
import { TableComponent } from "../../../../components/Table/table";
import BackButton from "../../../../components/BackButton/BackButton";
import ActionDropdown from "../../../../components/ActionDropdown/ActionDropdown";
import Loading from "../../../../components/Loading/Loading";
import { api } from "../../../../api/api.js";
import moment from "moment";
import { ReactComponent as EditIcon } from "../../../../images/edit.svg";
import { ReactComponent as EyeIcon } from "../../../../images/eye.svg";
import { ReactComponent as CopyIcon } from "../../../../images/copy.svg";
import DeleteConfirmModal from "../../../../components/ActionDropdown/DeleteConfirmModal.js";
import { faq_content_url } from "../../../../api/urls.js";
import unknownError from "../../../../utils/unknownError.js";
import { modules, perms } from "../../../../api/codes.js";
import { ProtectedElement } from "../../../../components/ProtectedElement/ProtectedElement.js";

const title = "FAQ's Content";
const apiUrl = faq_content_url;
const module = modules.FaqContent;
const currentRoute = "faqs_content";
const searchFeild = "printable_name";
const headers = [
  "FAQ's title",
  "FAQ's content",
  "Update date",
  "Update by",
  "Status",
  "Actions",
];
const bodyMap = (d, refetch) => {
  return [
    <div className="wrap">{d.faq}</div>,
    <div className="wrap">{d.content}</div>,
    moment(d.updated_at).format("DD-MM-YYYY"),
    "Admin",
    <div className={d.is_active ? "active-green" : "active-red"}>
      {d.is_active ? "Active" : "Inactive"}
    </div>,
    <ActionDropdown
      DeleteButton={({ setOpen }) => (
        <DeleteConfirmModal
          data={d}
          dropdownCloser={() => setOpen(false)}
          route={apiUrl}
          setTableData={() => refetch()}
          module={module}
        />
      )}
      EditButton={({ setOpen }) => (
        <ProtectedElement module={module} perm={perms.edit}>
          <Link to={currentRoute + "/edit/" + d.id}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="icon-btn green-icon">
                <EditIcon />
              </div>
              <div className="green-title">Edit</div>
            </div>
          </Link>
        </ProtectedElement>
      )}
      ViewButton={({ setOpen }) => (
        <ProtectedElement module={module} perm={perms.view}>
          <Link to={currentRoute + "/view/" + d.id}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="icon-btn yellow-icon">
                <EyeIcon />
              </div>
              <div className="yellow-title">View</div>
            </div>
          </Link>
        </ProtectedElement>
      )}
      CopyButton={({ setOpen }) => (
        <ProtectedElement module={module} perm={perms.add}>
          <Link to={currentRoute + "/copy/" + d.id}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="icon-btn purple-icon">
                <CopyIcon />
              </div>
              <div className="purple-title">Copy</div>
            </div>
          </Link>
        </ProtectedElement>
      )}
    />,
  ];
};

const FaqsContent = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);
  const getData = async () => {
    setData();
    try {
      const res = await api.get(apiUrl);
      setData(res.data);
    } catch (err) {
      unknownError(err);
    }
  };
  useEffect(() => {
    getData();
  }, [refetcher]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
          <ProtectedElement module={module} perm={perms.add}>
            <Link to={currentRoute + "/new"}>
              <button className="btn">Add New</button>
            </Link>
          </ProtectedElement>
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data
                ? data
                    .filter((d) =>
                      d[searchFeild]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((d) => {
                      return bodyMap(d, refetch);
                    })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      <Pagination />
    </>
  );
};

export default FaqsContent;
