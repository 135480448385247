import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { InputNumber } from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { country_url, tagon_customer_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { TitleLine } from "../../../components/lineTitle";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import {
  userAddressFormatter,
  userNameFormatter,
} from "../../../utils/addresssFormatter";
import moment from "moment";
import { useFetch } from "../../../utils/useFetch";

const initialState = {
  amount: "",
  credit_period: "",
  country: "",
};
const title = "Set Credit Limit";
const backUrl = "/b2b_store/eligible_users";
const apiUrl = tagon_customer_url;

const B2BAddCredit = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const userData = useFetch(apiUrl + id + "/");

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        try {
          api
            .post(apiUrl + id + "/creditAssign/", newData)
            .then((res) => {
              toast.open("Credit limit updated", "success");
              history.push(backUrl);
              setIsSaving(false);
            })
            .catch((err) => {
              if (err.response.data) {
                setErrors(err.response.data);
                moveUpToError();
              } else {
                unknownError(err);
              }
              setIsSaving(false);
            });
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="p-10" />
      <PersonalInfo data={userData} />
      <div className="p-10" />
      <CreditInfo data={userData} />
      <div className="p-10" />
      <div id="form">
        <InputNumber
          label="Enter credit limit"
          placeholder="Enter credit limit"
          disabled={view}
          value={data.amount}
          error={errors["amount"]}
          required
          onChange={(v) => set("amount", v)}
        />
        <InputNumber
          label="Credit settlement period (In Days)"
          placeholder="Credit settlement period (In Days)"
          disabled={view}
          value={data.credit_period}
          error={errors["credit_period"]}
          required
          onChange={(v) => set("credit_period", v)}
        />
        <APIDropdown
          label="Country"
          placeholder="Country"
          required
          disabled={view}
          value={data.country}
          url={country_url}
          onChange={(v) => set("country", v)}
          error={errors["country"]}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

function PersonalInfo({ data }) {
  return (
    <>
      <TitleLine right>Personal Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Image",
            renderCell: (params) => (
              <DashboardImage
                width="50px"
                height="50px"
                src={params?.row?.user?.profile_image}
                circle
              />
            ),
          },
          {
            headerName: "Name",
            renderCell: (params) => `${userNameFormatter(params?.row?.user)}`,
          },
          {
            headerName: "Date of Birth",
            renderCell: (params) =>
              params?.row?.user?.birthday
                ? moment(params?.row?.user?.birthday).format("DD-MM-YYYY")
                : "-",
          },
          {
            headerName: "Contact",
            renderCell: (params) => `${params?.row?.user?.mobile_number}`,
          },
          {
            headerName: "Address",
            renderCell: (params) => (
              <div className="wrap" style={{ maxWidth: "250px" }}>
                {userAddressFormatter(params?.row?.add?.[0])}
              </div>
            ),
          },
        ]}
        rows={[{ ...data?.data }]}
        loading={data.loading}
      />
    </>
  );
}

function CreditInfo({ data }) {
  return (
    <>
      <TitleLine right>Credit Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Assiged Credit",
            field: "user",
            renderCell: (params) => params?.value?.bnplCredit,
          },
          {
            headerName: "Used Credit",
            field: "credit",
            renderCell: (params) => params?.value?.[0]?.amount,
          },
          {
            headerName: "Remaining Credit",
            field: "user",
            renderCell: (params) =>
              (
                Number(params.row?.user?.bnplCredit) -
                  Number(params.row?.credit?.[0]?.amount) || 0
              ).toFixed(2),
          },
          {
            headerName: "Credit Period",
            field: "user",
            renderCell: (params) => `${params?.value?.credit_period} days`,
          },
        ]}
        rows={[{ ...data?.data }]}
        loading={data.loading}
      />
    </>
  );
}

export default B2BAddCredit;
