import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const Logistics = () => {
  return (
    <CardsPage
      items={items.find((item) => item.title === "Delivery").childrens}
    />
  );
};

export default Logistics;
