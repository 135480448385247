import { Header, Input, Icon } from "semantic-ui-react";
import { useEffect, useState } from "react";

import Pagination from "../../../../components/Pagination/Pagination.js";
import { TableComponent } from "../../../../components/Table/table";
import BackButton from "../../../../components/BackButton/BackButton";
import Loading from "../../../../components/Loading/Loading";
import { api } from "../../../../api/api.js";
import axios from "axios";
import { helpline_forms_url } from "../../../../api/urls.js";
import unknownError from "../../../../utils/unknownError.js";
import { DashboardImage } from "../../../../components/DashboardImage/DashboardImage.js";

const title = "Customer Complaints";
const apiUrl = helpline_forms_url;
const searchFeild = "subject";
const headers = [
  "Product image",
  "Subject",
  "Customer name",
  "Order number",
  "Product descriptions",
];
const bodyMap = (d, refetch) => {
  return [
    <DashboardImage src={d.product_image} height="70px" width="70px" />,
    d.subject,
    d.fullname,
    d.order_no,
    d.product_description,
  ];
};

const HelplineForms = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          apiUrl + "?limit=" + limit + "&offset=" + offset,
          { cancelToken: source.token }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, limit, offset]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data.results
                ? data.results
                    .filter((d) =>
                      d[searchFeild]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((d) => {
                      return bodyMap(d, refetch);
                    })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination count={count} setLimit={setLimit} setOffset={setOffset} />
      ) : null}
    </>
  );
};

export default HelplineForms;
