import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { InputCheckBox, InputText } from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { product_questions_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";

const initialState = {
  question: "",
  accept: false,
  question_return: false,
  question_cancel: false,
  question_replace: false,
  dlvry: false,
};

const module = modules.ProductQuestion;

const title = "Product Questions";
const backUrl = "/masters/product_questions";
const apiUrl = product_questions_url;

const NewProductQuestions = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };
      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Question"
          placeholder="Question"
          disabled={view}
          value={data.question}
          error={errors["question"]}
          required
          onChange={(v) => set("question", v)}
        />
        <InputCheckBox
          label="Accept"
          value={data.accept}
          disabled={view}
          onChange={(v) => set("accept", v)}
          error={errors["accept"]}
        />
        <InputCheckBox
          label="Question for return"
          value={data.question_return}
          disabled={view}
          onChange={(v) => set("question_return", v)}
          error={errors["question_return"]}
        />
        <InputCheckBox
          disabled={view}
          label="Question for cancel"
          value={data.question_cancel}
          onChange={(v) => set("question_cancel", v)}
          error={errors["question_cencel"]}
        />
        <InputCheckBox
          disabled={view}
          label="Question for replace"
          value={data.question_replace}
          onChange={(v) => set("question_replace", v)}
          error={errors["question_replace"]}
        />
        <InputCheckBox
          disabled={view}
          label="Question for delivery"
          value={data.dlvry}
          onChange={(v) => set("dlvry", v)}
          error={errors["dlvry"]}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewProductQuestions;
