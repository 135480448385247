import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import {
  branch_url,
  city_url,
  country_url,
  district_url,
  pincode_url,
  region_url,
  state_url,
} from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  name: "",
  mngrname: "",
  mngrcontact: "",
  mngremail: "",
  country: "",
  region: "",
  state: "",
  city: "",
  pincode: "",
  district: "",
  landmark: "",
  address: "",
};

const module = modules.Branch;

const title = "Branch";
const backUrl = "/masters/branch";
const apiUrl = branch_url;

const NewBranch = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          required
          onChange={(v) => set("description", v)}
        />
        <InputText
          label="Branch name"
          placeholder="Branch name"
          disabled={view}
          value={data.name}
          error={errors["name"]}
          required
          onChange={(v) => set("name", v)}
        />
        <InputText
          label="Branch manager name"
          placeholder="Branch manager name"
          disabled={view}
          value={data.mngrname}
          error={errors["mngrname"]}
          required
          onChange={(v) => set("mngrname", v)}
        />
        <InputText
          label="Branch manager contact"
          placeholder="Branch manager contact"
          disabled={view}
          value={data.mngrcontact}
          error={errors["mngrcontact"]}
          required
          onChange={(v) => set("mngrcontact", v)}
        />
        <InputText
          label="Branch manager email"
          placeholder="Branch manager email"
          disabled={view}
          value={data.mngremail}
          error={errors["mngremail"]}
          required
          onChange={(v) => set("mngremail", v)}
        />
        <APIDropdown
          label="Country"
          placeholder="Country"
          required
          disabled={view}
          value={data.country}
          url={country_url}
          onChange={(v) => set("country", v)}
          error={errors["country"]}
        />
        <APIDropdown
          label="Region"
          placeholder="Region"
          required
          disabled={view}
          value={data.region}
          url={region_url}
          onChange={(v) => set("region", v)}
          error={errors["region"]}
        />
        <APIDropdown
          label="State"
          placeholder="State"
          required
          disabled={view}
          value={data.state}
          url={state_url}
          onChange={(v) => set("state", v)}
          error={errors["state"]}
        />
        <APIDropdown
          label="City"
          placeholder="City"
          required
          disabled={view}
          value={data.city}
          url={city_url}
          onChange={(v) => set("city", v)}
          error={errors["city"]}
        />
        <APIDropdown
          label="District"
          placeholder="District"
          required
          disabled={view}
          value={data.district}
          url={district_url}
          onChange={(v) => set("district", v)}
          error={errors["district"]}
        />
        <InputTextBox
          label="Address"
          placeholder="Address"
          disabled={view}
          value={data.address}
          error={errors["address"]}
          required
          onChange={(v) => set("address", v)}
        />
        <InputText
          label="Landmark"
          placeholder="Landmark"
          disabled={view}
          value={data.landmark}
          error={errors["landmark"]}
          required
          onChange={(v) => set("landmark", v)}
        />
        <APIDropdown
          label="Pincode"
          placeholder="Pincode"
          required
          disabled={view}
          value={data.pincode}
          url={pincode_url}
          onChange={(v) => set("pincode", v)}
          error={errors["pincode"]}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewBranch;
