import "./SubTabs.scss";

const SubTabs = ({ active, setActive, options, count, setCount }) => {
  return (
    <div className="sub-tabs">
      {options.map((d, idx) => (
        <div
          key={idx}
          className={"sub-tab no-wrap " + (active === idx ? "active" : "")}
          onClick={() => {
            if (active !== idx) {
              setActive(idx);
              if (setCount) {
                setCount();
              }
            }
          }}
        >
          {d.title}{" "}
          {active === idx && count !== undefined ? (
            <span className="tab-count">{count}</span>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default SubTabs;
