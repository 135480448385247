import { useCallback, useEffect, useRef, useState } from "react";
import "./MultipleImages.scss";
import { useModal } from "../Modaler";
import { generateUID } from "../../utils/generateUID";
import { useDropzone } from "react-dropzone";
import { DashboardImage } from "../DashboardImage/DashboardImage";
import _ from "lodash";

export const MultipleImagesWithSelection = ({
  array,
  setArray,
  active,
  setActive,
  view,
  setDelete,
  noAdd,
}) => {
  // const [array, setArray] = useState([generateUID()]);
  const modaler = useModal();
  return (
    <div style={{ display: "flex", gap: "5px", overflow: "auto" }}>
      {array.map((x, idx) => (
        <div
          key={x.id}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <InputImage
            disabled={view}
            label={x.id}
            value={x.image01}
            onChange={(v) => {
              const newData = [...array];
              newData.find((y) => y.id === x.id).image01 = v;
              setArray(newData);
            }}
            deleteThis={
              array.length > 1
                ? () => {
                    modaler.opener(
                      <div style={{ padding: "20px" }}>
                        <div style={{ fontWeight: "bold" }}>
                          Are you sure you want to delete this product from
                          catalog?
                        </div>
                        <div className="p-10" />
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            gap: "10px",
                            width: "min-content",
                          }}
                        >
                          <div
                            className="button color-blue"
                            style={{
                              padding: "3px 10px",
                              width: "80px",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => {
                              modaler.closer();
                            }}
                          >
                            No
                          </div>
                          <div
                            className="button"
                            style={{ padding: "3px 10px", width: "80px" }}
                            onClick={() => {
                              const newData = array.filter(
                                (aa) => aa.id !== x.id
                              );
                              setDelete("product_inventory_del", x.id);
                              setArray(newData);
                              modaler.closer();
                            }}
                          >
                            Yes
                          </div>
                        </div>
                      </div>
                    );
                  }
                : undefined
            }
            onClick={() => setActive(idx)}
          />
          <div className={"image-name " + (active === idx ? "active" : "")}>
            Product {idx + 1}
          </div>
        </div>
      ))}
      {!view && !noAdd && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "60px",
            paddingBottom: "20px",
          }}
        >
          <div
            className="image-add"
            onClick={() =>
              setArray([
                ...array,
                {
                  id: generateUID(),
                  code: generateUID(),
                  printable_name: generateUID(),
                },
              ])
            }
          >
            &#43;
          </div>
        </div>
      )}
    </div>
  );
};

let curId = 1;
const ids = new Map();

function getObjectId(value) {
  if (typeof value === "object" && value !== null) {
    if (!ids.has(value)) {
      ids.set(value, String(curId++));
    }
    return ids.get(value);
  }
  return value;
}

const MultipleImages = ({ value, set, disabled }) => {
  const [visible, setVisible] = useState(8);
  const max = 8;

  return (
    <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
      {[...Array(max)].map(
        (_x, idx) =>
          (idx < visible || value[`image0${idx + 1}`]) && (
            <InputImage
              key={getObjectId(value[`image0${idx + 1}`]) || generateUID()}
              label={generateUID()}
              value={value[`image0${idx + 1}`]}
              disabled={disabled}
              onChange={(v) => {
                if (set) {
                  set(`image0${idx + 1}`, v);
                }
              }}
            />
          )
      )}
      {visible !== max && !disabled && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "60px",
          }}
        >
          <div
            className="image-add"
            onClick={() => {
              if (visible !== max) {
                setVisible(visible + 1);
              }
            }}
          >
            &#43;
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleImages;

const InputImage = ({
  label,
  value,
  onChange,
  required,
  error,
  disabled,
  deleteThis,
  onClick,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const ref = useRef();

  const onDrop = useCallback(
    (acceptedFiles, _, e) => {
      setSelectedFile(acceptedFiles[0]);
      onChange(acceptedFiles[0]);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop });

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  useEffect(() => {
    const getFile = async () => {
      if (value && ref.current) {
        if (_.isString(value)) {
          setSelectedFile(value);
          setPreview(value);
        } else {
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(value);
          ref.current.files = dataTransfer.files;
          setSelectedFile(value);
        }
      }
    };
    getFile();
  }, [value, setSelectedFile]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      ref.current.value = "";
      return;
    }

    if (selectedFile instanceof File) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    } else if (typeof selectedFile === "string") {
      setPreview(selectedFile);
    }
  }, [selectedFile]);

  const onSelectFile = (e) => {
    e.preventDefault();
    if (!!errorMessage) {
      setErrorMessage(e.target.validationMessage);
    }

    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setSelectedFile(e.target.files[0]);
    if (onChange) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <div
      {...getRootProps()}
      className={
        "input-mimage " +
        (selectedFile ? "" : "empty ") +
        (disabled ? "disabled" : "")
      }
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className="upload-image vertical">
        {deleteThis && !disabled && (
          <div
            className="close-icon"
            onClick={(e) => {
              e.stopPropagation();
              deleteThis();
            }}
          >
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.4125 4.5L8.875 7.9625V8.875H7.9625L4.5 5.4125L1.0375 8.875H0.125V7.9625L3.5875 4.5L0.125 1.0375V0.125H1.0375L4.5 3.5875L7.9625 0.125H8.875V1.0375L5.4125 4.5Z"
                fill="white"
              />
            </svg>
          </div>
        )}
        {selectedFile && (
          <div className="upload-image-wrapper">
            <DashboardImage src={preview} height="100px" width="100px" noOpen />
            {deleteThis === undefined && !disabled && (
              <div
                className="close-icon"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedFile(undefined);
                  onChange("");
                }}
              >
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.4125 4.5L8.875 7.9625V8.875H7.9625L4.5 5.4125L1.0375 8.875H0.125V7.9625L3.5875 4.5L0.125 1.0375V0.125H1.0375L4.5 3.5875L7.9625 0.125H8.875V1.0375L5.4125 4.5Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </div>
        )}
        {!selectedFile && !disabled ? (
          <label className="image-add" onClick={() => ref.current.click()}>
            &#43;
          </label>
        ) : null}
      </div>
      <input
        {...getInputProps()}
        type="file"
        ref={ref}
        required={required}
        autoComplete="on"
        onChange={onSelectFile}
        disabled={selectedFile || disabled}
        onInvalid={(e) => {
          e.preventDefault();
          setErrorMessage(e.target.validationMessage);
        }}
        accept="image/png, image/gif, image/jpeg"
      />
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
