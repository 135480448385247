import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const Logs = () => {
  return (
    <CardsPage items={items.find((item) => item.title === "Logs").childrens} />
  );
};

export default Logs;
