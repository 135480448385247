import { useEffect, useState } from "react";

import { TableComponent } from "../../../components/Table/table";
import Loading from "../../../components/Loading/Loading";
import { api } from "../../../api/api.js";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
// import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
import DeleteConfirmModal from "./delete_confirm_modal";
import axios from "axios";
import unknownError from "../../../utils/unknownError.js";
import { pls_docs_url } from "../../../api/urls";
import { useHistory } from "react-router-dom";
import NewPLSCertModal from "./new_seller_cert";
import { useToast } from "../../../components/Toast";
import { bool } from "../../../utils/bool";

const headers = [
  "Document name",
  "Document number",
  "File",
  "Verified",
  "Actions",
];
const bodyMap = (d, refetch, delivery_boy_id) => {
  return [
    d.doc_name,
    d.doc_number,
    <a target="_blank" rel="noreferrer" href={d.doc_file} className="blue-link">
      View File
    </a>,
    <div className="center">{bool(d.is_verified)}</div>,
    <div style={{ display: "flex", gap: "10px" }}>
      <NewPLSCertModal
        refetch={refetch}
        delivery_boy_id={delivery_boy_id}
        editData={d}
        trigger={
          <div className="i-btn">
            <EditIcon height={10} width={10} />
          </div>
        }
      />
      <DeleteConfirmModal
        data={d}
        dropdownCloser={() => false}
        route={pls_docs_url}
        setTableData={() => refetch()}
      />
    </div>,
  ];
};

const PLSCertDashboard = ({ delivery_boy_id, next }) => {
  const [data, setData] = useState();
  const [refetcher, setRefetcher] = useState(false);
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          pls_docs_url + "?delivery_boy=" + delivery_boy_id,
          {
            cancelToken: source.token,
          }
        );
        setData(res.data.filter((x) => x.doc_certificate === true));
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, delivery_boy_id]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <div style={{ maxWidth: "700px" }}>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <NewPLSCertModal
          refetch={refetch}
          delivery_boy_id={delivery_boy_id}
          trigger={
            <button type="button" className="btn">
              Add New
            </button>
          }
        />
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data
                ? data.map((d) => {
                    return bodyMap(d, refetch, delivery_boy_id);
                  })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      <div style={{ padding: "10px" }} />
      <div className="actions">
        <button
          className="btn-red"
          onClick={() => {
            history.push("/logistics/pls");
          }}
        >
          Cancel
        </button>
        <button
          className="btn"
          onClick={() => {
            if (data && data.length > 0) {
              next();
            } else {
              toast.open("Please atleast add one certificate", "error");
            }
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PLSCertDashboard;
