import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editorInput.scss";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";

export function EditorInput({ label, value, onChange, disabled }) {
  const [v, setV] = useState(value);

  if (disabled) {
    return (
      <div className="no-perm-wrapper">
        <div className="no-perm">
          <label className="input-label">{label}</label>
          <Editor
            editorState={v}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-text-box"
            toolbarClassName="editor-toolbar"
            onEditorStateChange={(v) => {
              setV(v);
              onChange(v);
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      <label className="input-label">{label}</label>
      <Editor
        editorState={v}
        wrapperClassName="editor-wrapper"
        editorClassName="editor-text-box"
        toolbarClassName="editor-toolbar"
        onEditorStateChange={(v) => {
          setV(v);
          onChange(v);
        }}
      />
    </div>
  );
}

export function editorToText(e) {
  const dirty = draftToHtml(convertToRaw(e.getCurrentContent()));
  const clean = DOMPurify.sanitize(dirty);
  return clean;
  // const content = e.getCurrentContent();
  // const rawObject = convertToRaw(content);
  // const markdownString = draftToMarkdown(rawObject);
  // return markdownString;
}

export function textToEditor(t) {
  const html = t;
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return EditorState.createEmpty();

  // const markdownString = t;
  // const rawData = markdownToDraft(markdownString);
  // const contentState = convertFromRaw(rawData);
  // const newEditorState = EditorState.createWithContent(contentState);
  // return newEditorState;
}
