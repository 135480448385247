import "./Modal.scss";

export const Modal = (props) => {
  return (
    <div className="window">
      {props.cross && (
        <div
          className="close-btn"
          onClick={() => {
            props.close();
          }}
        >
          &#10005;
        </div>
      )}
      {props.children}
    </div>
  );
};
