import React from "react";
import "./Button.scss";
import { ReactComponent as LoadingIcon } from "../../images/loading.svg";

const Button = ({
  onClick,
  color,
  isLoading,
  children,
  type,
  className,
  disabled,
  ...props
}) => {
  const buttonClasses = `button-component ${className} ${
    isLoading ? "loading" : ""
  }`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      style={color && !disabled ? { backgroundColor: color } : {}}
      disabled={isLoading || disabled}
      type={type ? type : "button"}
      {...props}
    >
      {isLoading ? (
        <>
          <LoadingIcon width={30} height={30} /> {children}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
