import { useState } from "react";
import { ReactComponent as FilterIcon } from "../../images/filter.svg";
import "./filter.scss";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "../Modal/Modal";
import { Form } from "../form";

export function useFilter({ schema, render }) {
  const [obj, setObj] = useState({});
  const [open, setOpen] = useState(false);
  const methods = useForm({ resolver: zodResolver(schema) });
  return {
    obj: obj,
    FilterButton: (
      <Modal
        open={open}
        trigger={
          <div className="filter-button" onClick={() => setOpen(true)}>
            <FilterIcon /> Filter{" "}
            <span className="filter-count">{getFilterCount(obj)}</span>
          </div>
        }
      >
        <div
          className="close-btn"
          onClick={() => {
            setOpen(false);
          }}
        >
          &#10005;
        </div>
        <FilterModal
          setObj={setObj}
          render={render}
          methods={methods}
          setOpen={setOpen}
        />
      </Modal>
    ),
  };
}

function FilterModal({ setObj, render, methods, setOpen }) {
  const Render = render;
  return (
    <Form
      methods={methods}
      onSubmit={() => {
        setObj(filterObj(methods.getValues()));
        setOpen(false);
      }}
    >
      <div
        style={{
          padding: "30px",
          paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Render methods={methods} />
        <div className="actions" style={{ alignSelf: "end" }}>
          <button
            className="btn-red"
            type="button"
            onClick={() => {
              methods.reset();
              setObj(filterObj(methods.getValues()));
              setOpen(false);
            }}
          >
            Clear
          </button>
          <button className="btn">Apply</button>
        </div>
      </div>
    </Form>
  );
}

function getFilterCount(obj) {
  let count = 0;
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      count += obj[key].length;
    } else {
      count++;
    }
  });
  return count;
}

function filterObj(paramObj) {
  const obj = { ...paramObj };
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete obj[key];
    }
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((x) => x);
      if (obj[key].length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
}
