import { Header, Input, Icon } from "semantic-ui-react";
import { useEffect, useState } from "react";

import Pagination from "../../../components/Pagination/Pagination.js";
import { TableComponent } from "../../../components/Table/table.js";
import BackButton from "../../../components/BackButton/BackButton.js";
import Loading from "../../../components/Loading/Loading.js";
import { api } from "../../../api/api.js";
import { sms_response_url } from "../../../api/urls.js";
import moment from "moment";
import axios from "axios";
import unknownError from "../../../utils/unknownError.js";

const title = "SMS Response";
const apiUrl = sms_response_url;
const searchFeild = "created_at";
const headers = ["JSON", "Date"];
const bodyMap = (d, refetch) => {
  return [
    <pre style={{ width: "100%" }}>
      {JSON.stringify(d.sms_response_data, null, 2)}
    </pre>,
    moment(d.updated_at).format("DD-MM-YYYY"),
  ];
};

const SMSResponse = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          apiUrl + "?limit=" + limit + "&offset=" + offset,
          { cancelToken: source.token }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, limit, offset]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data.results
                ? data.results
                    .filter((d) =>
                      d[searchFeild]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((d) => {
                      return bodyMap(d, refetch);
                    })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination count={count} setLimit={setLimit} setOffset={setOffset} />
      ) : null}
    </>
  );
};

export default SMSResponse;
