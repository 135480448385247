import "./Stepper.scss";

const Stepper = ({ steps, step, setter, allowedTill }) => {
  const array = steps;
  return (
    <div className="stepper-wrapper">
      <div className="stepper">
        {array.map((item, idx) => {
          return (
            <div
              className={"step " + (step === idx ? "active" : "")}
              key={idx}
              onClick={() => {
                if (allowedTill >= idx) {
                  setter(idx);
                }
              }}
            >
              <div style={{ display: "flex", gap: "6px" }}>
                <div className="step-index">{idx + 1}</div>
                {idx < array.length - 1 && (
                  <div
                    className={
                      "bar " +
                      (step === idx ||
                      (step === array.length - 1 && idx === array.length - 2)
                        ? "active"
                        : "")
                    }
                  />
                )}
              </div>
              <div className="step-name">{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
