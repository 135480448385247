import "./ActionDropdown.scss";
import { useRef, useState } from "react";
import { useOutsideClick, useOutsideScroll } from "../Inputs/Inputs";

import { ReactComponent as DotDot } from "../../images/dotdot.svg";
// import { ReactComponent as EditIcon } from "../../images/edit.svg";
// import { ReactComponent as EyeIcon } from "../../images/eye.svg";
// import { ReactComponent as PrintIcon } from "../../images/print.svg";
// import { ReactComponent as CloudIcon } from "../../images/cloud.svg";
// import { ReactComponent as DeleteIcon } from "../../images/delete.svg";

const ActionDropdown = ({
  ResetPass,
  EditButton,
  DeleteButton,
  ViewButton,
  CopyButton,
  options,
  Trigger,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const optionsRef = useRef();
  useOutsideClick(ref, () => setOpen(false));
  useOutsideScroll(ref, () => setOpen(false));
  let top = 0;
  let right = 0;
  if (ref.current) {
    if (optionsRef.current) {
      const height = optionsRef.current.getBoundingClientRect().height;
      top = ref.current.getBoundingClientRect().bottom + 3;
      const left = ref.current.getBoundingClientRect().right + 3;
      const width = optionsRef.current.getBoundingClientRect().width + 3;
      const widthTrig = ref.current.getBoundingClientRect().width + 3;
      right = window.innerWidth - ref.current.getBoundingClientRect().right;
      if (window.innerHeight - top < height) {
        top -= height + 27;
      }
      if (left < width) {
        right -= width - widthTrig;
      }
    }
  }
  return (
    <div ref={ref} className={"actions-dropdown " + (open ? "active" : "")}>
      {Trigger ? (
        <Trigger onClick={() => setOpen(!open)} />
      ) : (
        <div className="dot-dot" onClick={() => setOpen(!open)}>
          <DotDot />
        </div>
      )}
      <div
        ref={optionsRef}
        className="options"
        style={{
          top: top,
          right: right,
        }}
      >
        {EditButton ? <EditButton setOpen={setOpen} /> : null}

        {ViewButton ? <ViewButton setOpen={setOpen} /> : null}

        {DeleteButton ? <DeleteButton setOpen={setOpen} /> : null}

        {CopyButton ? <CopyButton setOpen={setOpen} /> : null}

        {/* <div className="option" onClick={() => setOpen(false)}>
              <div className="icon-btn blue-icon">
                <PrintIcon />
              </div>
              <div className="blue-title">Print</div>
            </div>

            <div className="option" onClick={() => setOpen(false)}>
              <div className="icon-btn purple-icon">
                <CloudIcon />
              </div>
              <div className="purple-title">Download</div>
            </div> */}

        {ResetPass && <ResetPass setOpen={setOpen} />}

        {options && options(() => setOpen(false))}
      </div>
    </div>
  );
};

export default ActionDropdown;
