import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { district_url, taluka_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { modules } from "../../../api/codes";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  name: "",
  district: "",
};

const module = modules.Taluka;

const title = "Taluka";
const backUrl = "/masters/taluka";
const apiUrl = taluka_url;

const NewTaluka = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          required
          onChange={(v) => set("description", v)}
        />
        <InputText
          label="Name"
          placeholder="Name"
          disabled={view}
          value={data.name}
          error={errors["name"]}
          required
          onChange={(v) => set("name", v)}
        />
        <APIDropdown
          label="District"
          placeholder="District"
          required
          disabled={view}
          value={data.district}
          url={district_url}
          onChange={(v) => set("district", v)}
          error={errors["district"]}
          module={module}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewTaluka;
