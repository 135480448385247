import { modules, perms } from "../../api/codes";
import HoroscopeTitle from "../../pages/horoscope/horoscope_title";
import NewHoroscopeTitle from "../../pages/horoscope/new_horoscope_title";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteHoroscope = [
  {
    path: "/horoscope",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.viewList} page>
        <HoroscopeTitle />
      </ProtectedElement>
    ),
  },
  {
    path: "/horoscope/new",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.add} page>
        <NewHoroscopeTitle />
      </ProtectedElement>
    ),
  },
  {
    path: "/horoscope/edit/:id",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.edit} page>
        <NewHoroscopeTitle edit />
      </ProtectedElement>
    ),
  },
  {
    path: "/horoscope/view/:id",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.view} page>
        <NewHoroscopeTitle view />
      </ProtectedElement>
    ),
  },
];
