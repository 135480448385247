import { Header } from "semantic-ui-react";
import BackButton from "../../../components/BackButton/BackButton";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../utils/useFetch";
import { TitleLine } from "../../../components/lineTitle";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import Loading from "../../../components/Loading/Loading";
import { useModal } from "../../../components/Modaler";
import moment from "moment";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { useState } from "react";
import { useToast } from "../../../components/Toast";
import { api } from "../../../api/api";
import { serialize } from "../../../utils/serialize";
import unknownError from "../../../utils/unknownError";
import {
  InputDate,
  InputFile,
  InputText,
} from "../../../components/Inputs/Inputs";
import { moveUpToError } from "../../../utils/moveUpToError";
import {
  EditorInput,
  editorToText,
  textToEditor,
} from "../../../components/EditorInput/editorInput";
import { delivery_boy_docs_url, pls_url } from "../../../api/urls";

const backUrl = "/logistics/pls";
export function PLSUpdateDocuments() {
  const title = "View/Update Documents";

  return (
    <div>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="p-10" />
      <UploadedDocuments />
      <div className="p-10" />
    </div>
  );
}

function UploadedDocuments() {
  const params = useParams();
  const userId = params.id;
  const moduler = useModal();
  const data = useFetch(delivery_boy_docs_url + "?delivery_boy=" + userId);
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document name",
            field: "doc_name",
          },
          {
            headerName: "Doc no.",
            field: "doc_number",
          },
          {
            headerName: "Document expiry",
            field: "doc_expiry",
            renderCell: (params) =>
              params.value ? moment(params.value).format("DD-MM-YYYY") : "-",
          },
          {
            headerName: "File type",
            field: "doc_file",
            renderCell: (params) => params?.value?.split(".").pop(),
          },
          {
            headerName: "Status",
            field: "doc_status",
          },
          {
            headerName: "Action",
            field: "doc_file",
            renderCell: (params) => (
              <div
                className="blue-link"
                onClick={() => {
                  moduler.opener(
                    <DocumentVerifyModal
                      data={params.row}
                      onSave={() => {
                        moduler.closer();
                        data.refetch();
                      }}
                    />,
                    true
                  );
                }}
              >
                View
              </div>
            ),
          },
        ]}
        rows={data?.data}
        loading={data.loading}
      />
      <UpdateDocument
        data={data}
        onSave={() => {
          data.refetch();
        }}
      />
    </>
  );
}

function UpdateDocument({ onSave, onCancel, view }) {
  const title = "Update Documents";
  const [data, setData] = useState({});
  const [errors] = useState({});

  const params = useParams();
  const userId = params.id;
  const fieldData = useFetch(pls_url + userId + "/get_delvboy_docs/");

  const [isSaving, setIsSaving] = useState(false);

  const toast = useToast();

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (fieldData.loading || !fieldData.data)
    return <Loading style={{ height: "500px" }} />;

  //   const uploadedDocuments = userData?.data?.docs;
  const uploadedDocuments = [];
  const fields = fieldData?.data
    ?.map((d) => d.docs.map((doc) => ({ ...doc })))
    .flat();

  if (fields.length === 0) {
    return (
      <form id="form-wrapper">
        <div id="form">
          <div className="p-10" />
          <Header>{title}</Header>
          All documents submitted
        </div>
      </form>
    );
  }

  return (
    <form
      id="form-wrapper"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsSaving(true);

        try {
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const exists = uploadedDocuments.find(
              (ud) => ud.doc_type === field.id
            )?.id;
            const name = field.doc_name;

            await api?.[exists ? "patch" : "post"](
              delivery_boy_docs_url + (exists ? `${exists}/` : ""),
              serialize({
                doc_name: name,
                doc_number: data[`${name}_number`] || null,
                doc_expiry: data[`${name}_expiry`]
                  ? moment(data[`${name}_expiry`]).format("YYYY-MM-DD")
                  : null,
                doc_file: data[`${name}_file`] || null,
                is_verified: false,
                doc_kyc: field.doc_kyc,
                doc_certificate: field.doc_certificate,
                doc_type: field.id,
                delivery_boy: userId,
                doc_section: field.section,
              })
            );
          }
          toast.open("Success", "success");
          fieldData.refetch();
          onSave();
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            toast.open(e?.response?.data.data, "error");
          } else {
            unknownError(e);
          }
        }
        setIsSaving(false);
      }}
    >
      <div id="form">
        <div className="p-10" />
        <Header>{title}</Header>
        Some documents are expired or not uploaded, please update them
        {fieldData.data.map((section) => {
          if (
            !section.docs.some((field) => fields.find((f) => f.id === field.id))
          )
            return null;
          return (
            <>
              <div style={{ padding: "2px" }} />
              <TitleLine right>{section.section.printable_name}</TitleLine>
              <div style={{ padding: "0px" }} />
              {section.docs.map((field) => {
                if (!fields.find((f) => f.id === field.id)) return null;
                const name = field.doc_name;
                return (
                  <>
                    {field.doc_num_require && (
                      <InputText
                        label={`${name} number`}
                        placeholder={`${name} number`}
                        disabled={view}
                        value={data[`${name}_number`]}
                        error={errors[`${name}_number`]}
                        required
                        onChange={(v) => set(`${name}_number`, v)}
                      />
                    )}
                    {field.expiryrequire && (
                      <InputDate
                        label={`${name} expiry`}
                        placeholder={`${name} expiry`}
                        disabled={view}
                        value={data[`${name}_expiry`]}
                        error={errors[`${name}_expiry`]}
                        required
                        onChange={(v) => set(`${name}_expiry`, v)}
                      />
                    )}
                    {field.filerequire && (
                      <InputFile
                        label={`${name} file`}
                        placeholder={`${name} file`}
                        disabled={view}
                        value={data[`${name}_file`]}
                        error={errors[`${name}_file`]}
                        required
                        onChange={(v) => set(`${name}_file`, v)}
                      />
                    )}
                  </>
                );
              })}
            </>
          );
        })}
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
}

function DocumentVerifyModal({ data, onSave }) {
  function checkURLIsImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  const [rejected, setRejected] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [comment, setComment] = useState(textToEditor(""));

  return (
    <div
      style={{
        padding: "20px",
        width: "700px",
        maxHeight: "90vh",
        maxWidth: "90vw",
        overflow: "auto",
      }}
    >
      <div style={{ height: "300px" }}>
        {data.doc_file ? (
          checkURLIsImage(data.doc_file) ? (
            <DashboardImage src={data.doc_file} width="500px" height="300px" />
          ) : (
            <object data={data.doc_file} width="500px" height="300px">
              <p>
                Unable to display this file, click
                <a href={data.doc_file} target="_blank" rel="noreferrer">
                  {" here "}
                </a>
                to open it.
              </p>
            </object>
          )
        ) : (
          <p>No document file available</p>
        )}
      </div>
      <div className="p-10" />
      <div className="p-10" />
      {rejected ? (
        <>
          <EditorInput
            value={comment}
            onChange={(v) => setComment(v)}
            label="Comment"
          />
          <div className="p-10" />
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  await api.patch(
                    delivery_boy_docs_url + data.id + "/",
                    serialize({
                      is_verified: false,
                      reject_comment: editorToText(comment),
                      doc_status: "Reject",
                    })
                  );
                  onSave();
                } catch (e) {
                  unknownError();
                }
                setIsSaving(false);
              }}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        data.doc_status !== "Approved" && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button className="btn btn-red" onClick={() => setRejected(true)}>
              Reject
            </button>
            <button
              className="btn"
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                try {
                  await api.patch(
                    delivery_boy_docs_url + data.id + "/",
                    serialize({
                      is_verified: true,
                      doc_status: "Approved",
                    })
                  );
                  onSave();
                } catch (e) {
                  unknownError();
                }
                setIsSaving(false);
              }}
            >
              Accept
            </button>
          </div>
        )
      )}
    </div>
  );
}
