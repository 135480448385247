import _ from "lodash";

export default function objToFormdata(data) {
  const form_data = new FormData();
  for (const key in data) {
    if (_.isString(data[key]) && data[key].startsWith("http")) {
      continue;
    }
    if (Object.prototype.toString.call(data[key]) === "[object Array]") {
      data[key].forEach((d) => {
        form_data.append(key, d);
      });
    } else {
      if (data[key] || data[key] === false) {
        form_data.append(key, data[key]);
      }
    }
  }
  return form_data;
}
