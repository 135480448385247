import { Dropdown, Search } from "semantic-ui-react";
import "./Navbar.scss";
import { ReactComponent as LogoutIcon } from "../../images/logout.svg";
import { logout } from "../../redux/userStore";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../api/api";
import { user_logout_url } from "../../api/urls";
import { useRef, useState, useCallback, useEffect } from "react";
import { useOutsideClick } from "../Inputs/Inputs";
import { items } from "../../data/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isAuthorized } from "../ProtectedElement/ProtectedElement";
import { perms } from "../../api/codes";
import React from "react";
import { ReactComponent as Logo } from "../../images/logo.svg";

const NavBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const username = useSelector((state) => state.user.username);
  const permissions = useSelector((state) => state.user.permissions);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const ref = useRef();
  useOutsideClick(ref, () => setOpen(false));

  const handleSearchChange = useCallback((e, { value }) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    if (searchValue) {
      const filteredResults = items.flatMap((item, index) =>
        item.childrens
          ? [...item.childrens, { ...item, parent: null }]
              .filter(
                (child) =>
                  child.title
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) &&
                  isAuthorized(permissions, child.module, perms.viewList)
              )
              .map((child, childIndex) => ({
                ...child,
                key: `${index}-${childIndex}`,
                parent: child.parent !== null ? item.title : null,
              }))
          : item.title.toLowerCase().includes(searchValue.toLowerCase()) &&
            isAuthorized(permissions, item.module, perms.viewList)
          ? [{ ...item, key: `${index}` }]
          : []
      );

      setSearchResults(
        filteredResults.map((item) => ({
          key: item.key,
          title: item.title,
          path: item.path,
          description: item.parent
            ? `${item.parent} > ${item.title}`
            : item.title,
        }))
      );
    } else {
      setSearchResults([]);
    }
  }, [searchValue, permissions]);

  const handleResultSelect = useCallback(
    (e, { result }) => {
      history.push(result.path);
      setSearchValue("");
      document.activeElement.blur();
    },
    [history]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter" && searchResults.length > 0) {
        handleResultSelect(null, { result: searchResults[0] });
      }
    },
    [searchResults, handleResultSelect]
  );

  return (
    <div id="nav">
      <Logo id="logo" />

      <div className="nav-right">
        <div className="nav-search">
          <Search
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            results={searchResults}
            value={searchValue}
            placeholder="Search modules..."
            onKeyPress={handleKeyPress}
          />
        </div>
        <div ref={ref} className="nav-user">
          <Dropdown
            icon={
              <div className="nav-user-info">
                <div className="nav-welcome">welcome {username}</div>
                <div className="nav-user-avatar">
                  {username.charAt(0).toUpperCase()}
                </div>
              </div>
            }
            direction="left"
            open={open}
            onClick={() => setOpen(true)}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  try {
                    await api.get(user_logout_url);
                  } catch (e) {
                    console.log(e);
                  }
                  setLoading(false);
                  setOpen(false);
                  await dispatch(logout());
                }}
              >
                <div className="logout-btn">
                  <LogoutIcon className="logout-icon" height={15} width={15} />
                  Logout
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
