import "./dashboard.scss";
// import { ReactComponent as UserIcon } from "../../images/user.svg";
// import { useState } from "react";
// import { useEffect } from "react";
// import {
//   internal_users_url,
//   order_list_url,
//   seller_url,
//   tagon_customer_url,
// } from "../../api/urls";
// import { api } from "../../api/api";
// import unknownError from "../../utils/unknownError";
// import Loading from "../../components/Loading/Loading";
// import { TableComponent } from "../../components/Table/table";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import placeholder from "../../images/no-image.jpg";
// // import { BarGraph } from "./bar-graph";
// // import { LineGraph } from "./line-graph";
// // import { PieGraph } from "./pie-graph";

const Dashboard = () => {
  return <></>;
};

// const Dashboard = () => {
//   const [tagoncustomers, setTagoncustomers] = useState("...");
//   const [internalusers, setInternaluser] = useState("...");
//   const [orders, setOrders] = useState("...");
//   const [recentOrders, setRecentOrders] = useState();
//   const [cancel, setCancel] = useState("...");
//   const [returns, setReturns] = useState("...");
//   const [replace, setReplace] = useState("...");
//   const [delivered, setDelivered] = useState("...");

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(tagon_customer_url + "?limit=1");
//         if (res.data) {
//           setTagoncustomers(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, [setTagoncustomers]);

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(internal_users_url + "?limit=1");
//         if (res.data) {
//           setInternaluser(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, [setInternaluser]);

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(order_list_url + "?limit=5");
//         if (res.data) {
//           setOrders(res.data.count);
//           setRecentOrders(res.data.results);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, [setOrders]);

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(
//           order_list_url + "?limit=1&status_code=OS_CNCLD_029"
//         );
//         if (res.data) {
//           setCancel(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, []);

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(
//           order_list_url + "?limit=1&status_code=OS_RTRND_018"
//         );
//         if (res.data) {
//           setReturns(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, []);

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(
//           order_list_url + "?limit=1&status_code=	OS_RPLCD_010"
//         );
//         if (res.data) {
//           setReplace(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, []);

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(
//           order_list_url + "?limit=1&status_code=OS_DLVRD_003"
//         );
//         if (res.data) {
//           setDelivered(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, []);

//   const [sellers, setSellers] = useState("...");

//   useEffect(() => {
//     const get = async () => {
//       try {
//         const res = await api.get(seller_url + "?limit=1");
//         if (res.data) {
//           setSellers(res.data.count);
//         }
//       } catch (err) {
//         unknownError(err);
//       }
//     };
//     get();
//   }, []);

//   return (
//     <>
//       <div className="dashboard">
//         <div className="cards">
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Orders</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{orders}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Delivered</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{delivered}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Cancel Orders</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{cancel}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Return Orders</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{returns}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//         </div>
//         <div className="cards">
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Replace Orders</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{replace}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Internal Users</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{internalusers}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total TagOn Customers</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{tagoncustomers}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//           <div className="dashboard-card">
//             <div className="dashboard-card-title-wrapper">
//               <div className="dashboard-card-title">Total Sellers</div>
//               <UserIcon className="dashboard-card-icon" />
//             </div>
//             <div style={{ padding: "5px" }} />
//             <div className="dashboard-card-body">{sellers}</div>
//             <div style={{ padding: "2px" }} />
//           </div>
//         </div>
//         <div className="cards">
//           <div className="dashboard-placeholder-1">
//             {!recentOrders ? (
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   height: "100%",
//                 }}
//               >
//                 <Loading />
//               </div>
//             ) : (
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "5px",
//                   fontSize: "small",
//                   fontWeight: "normal",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     flexWrap: "wrap",
//                     gap: "10px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: "20px",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Recent Orders
//                   </div>
//                   <Link to="/orders">
//                     <div
//                       className="button"
//                       style={{ padding: "3px 5px", fontSize: "12px" }}
//                     >
//                       View All
//                     </div>
//                   </Link>
//                 </div>
//                 <TableComponent
//                   headers={[
//                     "Order Id",
//                     "Image",
//                     "Product details",
//                     "Date",
//                     "Amount",
//                     "Status",
//                   ]}
//                   body={recentOrders.map((d) => {
//                     return [
//                       d.order_id,
//                       d.tagon_product_image ? (
//                         <a
//                           href={d.tagon_product_image}
//                           target="_blank"
//                           rel="noreferrer"
//                         >
//                           <img
//                             width={57}
//                             src={d.tagon_product_image}
//                             alt="product_image"
//                             style={{
//                               borderRadius: "5px",
//                               border: "1px solid rgba(0,0,0,0.1)",
//                             }}
//                           />
//                         </a>
//                       ) : (
//                         <img
//                           src={placeholder}
//                           width={57}
//                           height={57}
//                           alt="placeholder"
//                         />
//                       ),
//                       d.title,
//                       moment(d.updated).format("DD-MM-YYYY"),
//                       d?.subtotal_after_tax_amt,
//                       d?.action_name,
//                     ];
//                   })}
//                   showbottom={false}
//                   disableHeaderPopup
//                 />
//               </div>
//             )}
//           </div>
//           {/* <div className="dashboard-placeholder-2">
//             <div
//               style={{
//                 fontSize: "20px",
//                 fontWeight: "bold",
//               }}
//             >
//               Bar Graph
//             </div>
//             <div style={{ padding: "10px" }} />
//             <div style={{ paddingRight: "10px", flex: 1 }}>
//               <BarGraph />
//             </div>
//           </div> */}
//         </div>
//         <div className="cards">
//           {/* <div className="dashboard-placeholder-3">
//             <div
//               style={{
//                 fontSize: "20px",
//                 fontWeight: "bold",
//               }}
//             >
//               Line Graph
//             </div>
//             <div style={{ padding: "10px" }} />
//             <div style={{ paddingRight: "10px", flex: 1 }}>
//               <LineGraph />
//             </div>
//           </div>
//           <div className="dashboard-placeholder-3">
//             <div
//               style={{
//                 fontSize: "20px",
//                 fontWeight: "bold",
//               }}
//             >
//               Pie Graph
//             </div>
//             <div style={{ padding: "10px" }} />
//             <div style={{ paddingRight: "10px", flex: 1 }}>
//               <PieGraph />
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </>
//   );
// };
export default Dashboard;
