import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import FaqsContent from "../../pages/help_center/faqs/faqs_content/faqs_content";
import NewFaqsContent from "../../pages/help_center/faqs/faqs_content/new_faqs_content";
import FaqsTitle from "../../pages/help_center/faqs/faqs_title/faqs_title";
import NewFaqsTitle from "../../pages/help_center/faqs/faqs_title/new_faqs_title";
import HelpCenter from "../../pages/help_center/help_center";
import HelplineForms from "../../pages/help_center/helpline/helpline_forms/helpline_forms";
import HelplineNumber from "../../pages/help_center/helpline/helpline_number/helpline_number";
import NewHelplineNumber from "../../pages/help_center/helpline/helpline_number/new_helpline_number";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteHelpCenter = [
  {
    path: "/help_center",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.HelpCenterCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <HelpCenter />
      </ProtectedElement>
    ),
  },
  ...makeRoute("faqs_title", FaqsTitle, NewFaqsTitle, modules.FaqTitle),
  ...makeRoute("faqs_content", FaqsContent, NewFaqsContent, modules.FaqContent),
  ...makeRoute(
    "helpline_forms",
    HelplineForms,
    HelplineForms,
    modules.HelplineForm,
    true
  ),
  ...makeRoute(
    "helpline_number",
    HelplineNumber,
    NewHelplineNumber,
    modules.HelplineNumber
  ),
];

function makeRoute(route, Dashboard, Form, module, readOnly) {
  if (readOnly) {
    return [
      {
        path: `/help_center/${route}`,
        content: (
          <ProtectedElement module={module} perm={perms.viewList} page>
            <Dashboard isGlobal={false} />
          </ProtectedElement>
        ),
      },
    ];
  }
  return [
    {
      path: `/help_center/${route}`,
      content: (
        <ProtectedElement module={module} perm={perms.viewList} page>
          <Dashboard isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/help_center/${route}/new`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/help_center/${route}/edit/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.edit} page>
          <Form edit isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/help_center/${route}/view/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.view} page>
          <Form view isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/help_center/${route}/copy/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} />
        </ProtectedElement>
      ),
    },
  ];
}
