import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputImage,
  InputNumber,
  InputText,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { logistics_parter_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { useModal } from "../../../components/Modaler";
import NewPartnerAddressModal from "./new_partner_address";
import { urlTofile } from "../../../utils/urlTofile";
import objToFormdata from "../../../utils/objToFormdata";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  company_name: "",
  company_img: "",
  PAN_number: "",
  TAN_number: "",
  CIN_number: "",
  website: "",
  trnsprtr_id: "",
  customer_care_name: "",
  customer_care_mobile_number: "",
  customer_care_email_id: "",
  account_manager_name: "",
  account_manager_mob_no: "",
  account_manager_email_id: "",
};

const title = "Logistics Partner";
const backUrl = "/masters/logistics_partner";
const apiUrl = logistics_parter_url;
const module = modules.LogisticPartner;

const NewLogisticPartner = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const modaler = useModal();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: module },
          });
          res.data.company_img = await urlTofile(res.data.company_img);
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", objToFormdata(data), {
                headers: { module: module },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, objToFormdata(data), {
                headers: { module: module },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                modaler.opener(
                  <AskHugModal
                    seller_id={res.data.id}
                    close={modaler.closer}
                    back={() => history.push(backUrl)}
                  />
                );
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="twoside-form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <div className="twoside-form">
          <div className="formside">
            <InputText
              label="Code"
              placeholder="Code"
              disabled={view}
              required
              value={data.code}
              onChange={(v) => set("code", v)}
              error={errors["code"]}
            />
            <InputText
              label="Printable name"
              placeholder="Printable name"
              disabled={view}
              required
              value={data.printable_name}
              onChange={(v) => set("printable_name", v)}
              error={errors["printable_name"]}
            />
            <InputText
              label="Company name"
              placeholder="Company name"
              disabled={view}
              required
              value={data.company_name}
              onChange={(v) => set("company_name", v)}
              error={errors["company_name"]}
            />
            <InputText
              label="Website"
              placeholder="Website"
              disabled={view}
              value={data.website}
              onChange={(v) => set("website", v)}
              error={errors["website"]}
            />
            <InputImage
              label="Company image"
              placeholder="Company image"
              disabled={view}
              value={data.company_img}
              onChange={(v) => set("company_img", v)}
              error={errors["company_img"]}
            />
            <InputText
              label="Account manager name"
              placeholder="Account manager name"
              disabled={view}
              value={data.account_manager_name}
              onChange={(v) => set("account_manager_name", v)}
              error={errors["account_manager_name"]}
            />
            <InputNumber
              label="Account manager mobile number"
              placeholder="Account manager mobile number"
              disabled={view}
              value={data.account_manager_mob_no}
              onChange={(v) => set("account_manager_mob_no", v)}
              error={errors["account_manager_mob_no"]}
            />
            <InputText
              label="Account manager email id"
              placeholder="Account manager email id"
              type="email"
              disabled={view}
              value={data.account_manager_email_id}
              onChange={(v) => set("account_manager_email_id", v)}
              error={errors["account_manager_email_id"]}
            />
          </div>
          <div className="formside">
            <InputText
              label="Customer care name"
              placeholder="Customer care name"
              disabled={view}
              value={data.customer_care_name}
              onChange={(v) => set("customer_care_name", v)}
              error={errors["customer_care_name"]}
            />
            <InputNumber
              label="Customer care mobile number"
              placeholder="Customer care mobile number"
              disabled={view}
              value={data.customer_care_mobile_number}
              onChange={(v) => set("customer_care_mobile_number", v)}
              error={errors["customer_care_mobile_number"]}
            />
            <InputText
              label="Customer care email id"
              placeholder="Customer care email id"
              type="email"
              disabled={view}
              value={data.customer_care_email_id}
              onChange={(v) => set("customer_care_email_id", v)}
              error={errors["customer_care_email_id"]}
            />
            <InputText
              label="PAN number"
              placeholder="PAN number"
              disabled={view}
              value={data.PAN_number}
              onChange={(v) => set("PAN_number", v)}
              error={errors["PAN_number"]}
            />
            <InputText
              label="TAN number"
              placeholder="TAN number"
              disabled={view}
              value={data.TAN_number}
              onChange={(v) => set("TAN_number", v)}
              error={errors["TAN_number"]}
            />
            <InputText
              label="CIN number"
              placeholder="CIN number"
              disabled={view}
              value={data.CIN_number}
              onChange={(v) => set("CIN_number", v)}
              error={errors["CIN_number"]}
            />
            <InputText
              label="Transporter ID (for E-way bill)"
              placeholder="Transporter ID (for E-way bill)"
              disabled={view}
              required
              value={data.trnsprtr_id}
              onChange={(v) => set("trnsprtr_id", v)}
              error={errors["trnsprtr_id"]}
            />
          </div>
        </div>
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

const AskHugModal = ({ close, back, seller_id }) => {
  return (
    <div style={{ padding: "20px" }}>
      <div style={{ fontWeight: "bold" }}>
        Data saved successfully, do you want add hub details ?
      </div>
      <div className="p-10" />
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          gap: "10px",
          width: "min-content",
        }}
      >
        <div
          className="button btn-red"
          style={{
            padding: "3px 10px",
            width: "80px",
          }}
          onClick={() => {
            close();
          }}
        >
          No
        </div>
        <NewPartnerAddressModal
          refetch={back}
          seller_id={seller_id}
          onSave={() => {
            close();
          }}
          trigger={
            <div
              className="button"
              style={{ padding: "3px 10px", width: "80px" }}
            >
              Yes
            </div>
          }
        />
      </div>
    </div>
  );
};

export default NewLogisticPartner;
