import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";

import { AreYouSureContext } from "./ModalContext";
import { Modal } from "./Modal";
import "./Modal.scss";

let modalSetter;

export const openAreYouSure = (content, cross = false) => {
  modalSetter({
    open: true,
    content: content,
    cross: cross,
  });
};
export const closeAreYouSure = () => {
  modalSetter({
    open: false,
    content: <></>,
  });
};

export const AreYouSureProvider = (props) => {
  const [modal, setModal] = useState({
    open: false,
    content: () => <></>,
  });
  modalSetter = setModal;
  const opener = (content, cross = false) =>
    setModal({ open: true, content: content, cross: cross });
  const closer = () => setModal({ open: false, content: () => <></> });
  const contextValue = useMemo(() => ({ opener, closer }), []);

  return (
    <AreYouSureContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        modal.open === true ? (
          <div className="overlay">
            <Modal cross={modal.cross} close={closer}>
              {modal.content}
            </Modal>
          </div>
        ) : null,
        document.body
      )}
    </AreYouSureContext.Provider>
  );
};
