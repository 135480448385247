import NavBar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

const Content = ({ children }) => {
  return (
    <div id="page">
      <NavBar />
      <div id="main">
        <Sidebar />
        <div id="content">
          <div className="content-wrapper-wrapper">
            <div className="content-wrapper">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
