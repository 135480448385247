import { Header, Input, Icon } from "semantic-ui-react";
import { useEffect, useState, useMemo } from "react";
import debounce from "lodash/debounce";

import Pagination from "../../../components/Pagination/Pagination.js";
import { TableComponent } from "../../../components/Table/table";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import Loading from "../../../components/Loading/Loading";
import { api } from "../../../api/api.js";
import moment from "moment";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
import { ReactComponent as CopyIcon } from "../../../images/copy.svg";
import DeleteConfirmModal from "../../../components/ActionDropdown/DeleteConfirmModal.js";
import axios from "axios";
import unknownError from "../../../utils/unknownError.js";
import {
  bproduct_add_url,
  gproduct_add_url,
  product_add_url,
} from "../../../api/urls.js";
import BackButton from "../../../components/BackButton/BackButton.js";
import { Link } from "react-router-dom";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement.js";
import { modules, perms } from "../../../api/codes.js";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage.js";

const ProductList = ({ isGlobal, isB2B }) => {
  const apiUrl = isB2B
    ? bproduct_add_url
    : isGlobal
    ? gproduct_add_url
    : product_add_url;

  const title = "Product List";
  const currentRoute = "products";
  const module = isB2B
    ? modules.B2BProduct
    : isGlobal
    ? modules.GlobalProduct
    : modules.Product;
  const headers = [
    "Image",
    "Title",
    "Variants",
    "Update date",
    "Update by",
    "Status",
    "Actions",
  ];
  const bodyMap = (d, refetch, apiUrl) => {
    return [
      <div style={{ display: "flex", gap: "10px" }}>
        <DashboardImage
          src={d?.product_inventory?.[0]?.image01}
          height="75px"
          width="75px"
        />
      </div>,
      <div className="wrap">{d.title}</div>,
      d?.product_inventory?.filter((x) => x.is_active)?.length,
      moment(d.updated_at).format("DD-MM-YYYY"),
      "Admin",
      <div className={d.is_active ? "active-green" : "active-red"}>
        {d.is_active ? "Active" : "Inactive"}
      </div>,
      <ActionDropdown
        DeleteButton={({ setOpen }) => (
          <DeleteConfirmModal
            data={d}
            dropdownCloser={() => setOpen(false)}
            route={apiUrl}
            setTableData={() => refetch()}
            module={module}
          />
        )}
        EditButton={({ setOpen }) => (
          <ProtectedElement module={module} perm={perms.edit}>
            <Link to={currentRoute + "/edit/" + d.id}>
              <div
                className="option"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div className="icon-btn green-icon">
                  <EditIcon />
                </div>
                <div className="green-title">Edit</div>
              </div>
            </Link>
          </ProtectedElement>
        )}
        ViewButton={({ setOpen }) => (
          <ProtectedElement module={module} perm={perms.view}>
            <Link to={currentRoute + "/view/" + d.id}>
              <div
                className="option"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div className="icon-btn yellow-icon">
                  <EyeIcon />
                </div>
                <div className="yellow-title">View</div>
              </div>
            </Link>
          </ProtectedElement>
        )}
        CopyButton={({ setOpen }) => (
          <ProtectedElement module={module} perm={perms.add}>
            <Link to={currentRoute + "/copy/" + d.id}>
              <div
                className="option"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div className="icon-btn purple-icon">
                  <CopyIcon />
                </div>
                <div className="purple-title">Copy</div>
              </div>
            </Link>
          </ProtectedElement>
        )}
      />,
    ];
  };

  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm) => {
        setRefetcher((v) => !v);
      }, 300),
    []
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          `${apiUrl}?limit=${limit}&offset=${offset}&search=${search}`,
          {
            cancelToken: source.token,
          }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetcher, limit, offset, apiUrl]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      {isGlobal && <BackButton />}
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
            icon={<Icon name="search" link id="search-icon" />}
          />
          <ProtectedElement module={module} perm={perms.add}>
            <Link to={currentRoute + "/new"}>
              <button className="btn">Add New</button>
            </Link>
          </ProtectedElement>
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data.results
                ? data.results.map((d) => {
                    return bodyMap(d, refetch, apiUrl);
                  })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination
          count={count}
          setLimit={setLimit}
          setOffset={setOffset}
          pages={[10, 20, 30, 40, 50]}
        />
      ) : null}
    </>
  );
};

export default ProductList;
