import { useState } from "react";
import "./ActionDropdown.scss";
import "./DeleteConfirmModal.scss";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import { api } from "../../api/api";
import Modal from "../Modal/Modal";
import { useModal } from "../Modaler";
import { Header } from "semantic-ui-react";
import unknownError from "../../utils/unknownError";

const ResetPassModal = ({ data, dropdownCloser, route }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const modal = useModal();
  return (
    <Modal
      open={modalOpen}
      onOpen={() => {
        setModalOpen(true);
      }}
      trigger={
        <div className="option">
          <div className="icon-btn red-icon">
            <DeleteIcon />
          </div>
          <div className="red-title">Reset Password</div>
        </div>
      }
      className="modal-window small-modal"
    >
      <div className="modal-content">
        <div className="modal-form">
          <h2>
            <b>Do you want to reset password of {data.username} ?</b>
          </h2>
        </div>
        <div className="delete-modal-actions">
          <button
            className="btn-red"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            No
          </button>
          <button
            className="btn"
            onClick={() => {
              if (data) {
                try {
                  api
                    .post(route + data.id + "/reset_password/")
                    .then((_res) => {
                      modal.opener(
                        <div className="modal-window small-modal">
                          <div className="modal-content">
                            <div className="modal-form">
                              <Header>
                                Email for resetting the password has been send
                                to {data.username} at {data.email}
                              </Header>
                            </div>
                            <div className="delete-modal-actions">
                              <button
                                className="btn-red"
                                onClick={() => modal.closer()}
                              >
                                close
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    .catch((err) => unknownError(err));
                } catch (err) {
                  unknownError(err);
                }
              }
              setModalOpen(false);
              dropdownCloser(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassModal;
