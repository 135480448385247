import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";

import { ToastContext } from "./ToastContext";
import { Toast } from "./Toast";
import "./Toast.scss";
import { generateUID } from "../../utils/generateUID";

let toastSetter;

export const createToast = (content, Variant) => {
  toastSetter((currentToasts) => [
    ...currentToasts,
    { id: generateUID(), content, Variant },
  ]);
};

export const ToastProvider = (props) => {
  const [toasts, setToasts] = useState([]);
  toastSetter = setToasts;
  const open = (content, Variant) =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateUID(), content, Variant },
    ]);
  const close = (id) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <div className="toasts-wrapper">
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              close={() => close(toast.id)}
              Variant={toast.Variant}
            >
              {toast.content}
            </Toast>
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};
