import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children, module, perm }) => {
  const access = useSelector((state) => state.user.accessToken);
  const pwd_temp = useSelector((state) => state.user.pwd_temp);
  const location = useLocation();

  if (pwd_temp === true) {
    return <Redirect to="/upd_tmp_pswd" relative={false} replace />;
  }
  if (!access) {
    return (
      <Redirect
        to={`/?back_url=${encodeURI(location.pathname)}`}
        relative={false}
        replace
      />
    );
  }
  return children;
};

export default ProtectedRoute;
