import { Header } from "semantic-ui-react";
import BackButton from "../../../components/BackButton/BackButton";
import { useState } from "react";
import {
  InputDate,
  InputFile,
  InputText,
} from "../../../components/Inputs/Inputs";
import { useFetch } from "../../../utils/useFetch";
import { b2b_documents_url, tagon_customer_url } from "../../../api/urls";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import { useToast } from "../../../components/Toast";
import { serialize } from "../../../utils/serialize";
import moment from "moment";
import { TitleLine } from "../../../components/lineTitle";
// import { useModal } from "../../../components/Modaler";

const title = "Add New B2B";
const backUrl = "/users/tagon_customers";

export function NewB2B({ view }) {
  const [data, setData] = useState({});
  const [errors] = useState({});

  // const fieldData = useFetch(b2b_verification_sections_url);
  const params = useParams();
  const userId = params.id;
  const fieldData = useFetch(tagon_customer_url + userId + "/get_b2b_docs/");

  const userData = useFetch(tagon_customer_url + userId + "/");

  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  // const modaler = useModal();

  const toast = useToast();

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (
    fieldData.loading ||
    !fieldData.data ||
    userData.loading ||
    !userData.data
  )
    return <Loading />;

  const uploadedDocuments = userData?.data?.docs;
  const fields = fieldData?.data
    ?.map((d) => d.docs.map((doc) => ({ ...doc })))
    .flat();
  // .filter((field) =>
  //   uploadedDocuments.find((ud) => ud.doc_type === field.id)
  //     ? true
  //     : !(
  //         uploadedDocuments.find((ud) => ud.doc_type === field.id)
  //           ?.is_verified === true
  //       )
  // );

  if (fields.length === 0) {
    return (
      <form id="form-wrapper">
        <div id="form">
          <BackButton href={backUrl} />
          <Header>{title}</Header>
          All documents submitted
        </div>
      </form>
    );
  }

  return (
    <form
      id="form-wrapper"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsSaving(true);

        try {
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const exists = uploadedDocuments.find(
              (ud) => ud.doc_type === field.id
            )?.id;
            const name = field.doc_name;

            await api?.[exists ? "patch" : "post"](
              b2b_documents_url + (exists ? `${exists}/` : ""),
              serialize({
                doc_name: name,
                doc_number: data[`${name}_number`] || null,
                doc_expiry: data[`${name}_expiry`]
                  ? moment(data[`${name}_expiry`]).format("YYYY-MM-DD")
                  : null,
                doc_file: data[`${name}_file`] || null,
                is_verified: false,
                doc_kyc: field.doc_kyc,
                doc_certificate: field.doc_certificate,
                doc_type: field.id,
                b2buser: userId,
                doc_section: field.section,
              })
            );
          }
          toast.open("Success", "success");
          history.push(backUrl);
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            toast.open(e?.response?.data.data, "error");
          } else {
            unknownError(e);
          }
        }
        setIsSaving(false);
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        {/* <div className="table-wrapper table-wrapper-styles">
          <Table
            className="table-component no-wrap"
            size="small"
            unstackable
            celled
          >
            <Table.Body>
              {fields.data.map((field, idx) => (
                <Table.Row key={idx} style={{ fontSize: "14px" }}>
                  <Table.Cell
                    style={{
                      fontWeight: "500",
                      width: "50px",
                      textAlign: "center",
                    }}
                  >
                    {idx + 1}
                  </Table.Cell>
                  <Table.Cell>{field.doc_name}</Table.Cell>
                  <Table.Cell style={{ width: "90px" }}>
                    <button className="btn" style={{ width: "70px" }} onClick={()=> {
                      modaler.open(<DocForm />, true);
                    }}>
                      Add
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div> */}
        {fieldData.data.map((section) => {
          // if no docs in section that is in fields, skip it
          if (
            !section.docs.some((field) => fields.find((f) => f.id === field.id))
          )
            return null;
          return (
            <>
              <div style={{ padding: "2px" }} />
              <TitleLine right>{section.section.printable_name}</TitleLine>
              <div style={{ padding: "0px" }} />
              {section.docs.map((field) => {
                if (!fields.find((f) => f.id === field.id)) return null;
                const name = field.doc_name;
                return (
                  <>
                    {field.doc_num_require && (
                      <InputText
                        label={`${name} number`}
                        placeholder={`${name} number`}
                        disabled={view}
                        value={data[`${name}_number`]}
                        error={errors[`${name}_number`]}
                        required
                        onChange={(v) => set(`${name}_number`, v)}
                      />
                    )}
                    {field.expiryrequire && (
                      <InputDate
                        label={`${name} expiry`}
                        placeholder={`${name} expiry`}
                        disabled={view}
                        value={data[`${name}_expiry`]}
                        error={errors[`${name}_expiry`]}
                        required
                        onChange={(v) => set(`${name}_expiry`, v)}
                      />
                    )}
                    {field.filerequire && (
                      <InputFile
                        label={`${name} file`}
                        placeholder={`${name} file`}
                        disabled={view}
                        value={data[`${name}_file`]}
                        error={errors[`${name}_file`]}
                        required
                        onChange={(v) => set(`${name}_file`, v)}
                      />
                    )}
                  </>
                );
              })}
            </>
          );
        })}
        {/* {fields?.map((field) => {
          const name = field.doc_name;
          return (
            <>
              {field.doc_num_require && (
                <InputText
                  label={`${name} number`}
                  placeholder={`${name} number`}
                  disabled={view}
                  value={data[`${name}_number`]}
                  error={errors[`${name}_number`]}
                  required
                  onChange={(v) => set(`${name}_number`, v)}
                />
              )}
              {field.expiryrequire && (
                <InputDate
                  label={`${name} expiry`}
                  placeholder={`${name} expiry`}
                  disabled={view}
                  value={data[`${name}_expiry`]}
                  error={errors[`${name}_expiry`]}
                  required
                  onChange={(v) => set(`${name}_expiry`, v)}
                />
              )}
              {field.filerequire && (
                <InputFile
                  label={`${name} file`}
                  placeholder={`${name} file`}
                  disabled={view}
                  value={data[`${name}_file`]}
                  error={errors[`${name}_file`]}
                  required
                  onChange={(v) => set(`${name}_file`, v)}
                />
              )}
            </>
          );
        })} */}
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
}

// function DocForm()
