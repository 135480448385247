import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Field } from "./form";
import {
  InputCheckBox,
  InputDate,
  InputFile,
  InputNumber,
  InputText,
  InputTextBox,
} from "./Inputs/Inputs";
import { APIDropdown } from "./APIDropdown/APIDropdown";
import { Radio } from "semantic-ui-react";
import { isValidUrl } from "../utils/isValidUrl";
import { urlTofile } from "../utils/urlTofile";
import moment from "moment";
import _ from "lodash";

function Text({
  methods,
  label,
  name,
  placeholder,
  required,
  info,
  multiline,
  disabled,
}) {
  const { control } = methods;
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={
          ({ field: { ref, value, onChange }, fieldState: { error } }) => {
            return multiline ? (
              <InputTextBox
                label={label}
                disabled={disabled}
                error={error?.message}
                placeholder={placeholder}
                required={required}
                value={value}
                onChange={onChange}
              />
            ) : (
              <InputText
                label={label}
                disabled={disabled}
                error={error?.message}
                placeholder={placeholder}
                required={required}
                value={value}
                onChange={onChange}
              />
            );
          }
          // <TextField
          //   inputRef={ref}
          //   value={value ? value : ""}
          //   onChange={onChange}
          //   multiline={multiline}
          //   rows={multiline ? 3 : undefined}
          //   placeholder={placeholder}
          //   error={!!error}
          //   helperText={error?.message}
          // />
        }
      />
    </Field>
  );
}

function Number({
  methods,
  label,
  required,
  info,
  name,
  placeholder,
  disabled,
}) {
  const { control, setValue, getValues } = methods;
  useEffect(() => {
    setValue(name, parseFloat(getValues(name)));
  }, [name, setValue, getValues]);
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <InputNumber
            label={label}
            disabled={disabled}
            error={error?.message}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChange}
          />
          // <TextField
          //   inputRef={ref}
          //   type="number"
          //   value={value?.toString() ? value?.toString() : ""}
          //   onChange={(e) => onChange(parseFloat(e.target.value))}
          //   placeholder={placeholder}
          //   error={!!error}
          //   helperText={error?.message}
          //   disabled={disabled}
          // />
        )}
      />
    </Field>
  );
}

function Date({
  methods,
  label,
  required,
  info,
  name,
  placeholder,
  disabled,
  noTime = true,
  format,
}) {
  const { control } = methods;
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <InputDate
            label={label}
            disabled={disabled}
            error={error?.message}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={(v) => {
              if (format) {
                onChange(moment(v).format(format));
              } else {
                onChange(v);
              }
            }}
            noTime={noTime}
          />
          // <DatePicker
          //   inputRef={ref}
          //   placeholder={placeholder}
          //   slots={{ openPickerIcon: Calendar }}
          //   format="DD/MM/YYYY"
          //   slotProps={{
          //     openPickerIcon: { style: { strokeWidth: "2px" } },
          //     actionBar: {
          //       actions: ["today"],
          //     },
          //     textField: {
          //       error: !!error,
          //       helperText: error?.message,
          //     },
          //   }}
          //   value={value ? moment(value) : null}
          //   onChange={(e) => {
          //     onChange(e.format());
          //   }}
          // />
        )}
      />
    </Field>
  );
}

function Select({
  methods,
  label,
  required,
  info,
  name,
  placeholder,
  multiple,
  options,
  getKey = "printable_name",
  setKey = "id",
  loading,
  onChangeFullData,
  disabled,
  refetch,
}) {
  const { control } = methods;
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue={multiple ? undefined : null}
        render={
          ({ field: { ref, value, onChange }, fieldState: { error } }) => (
            <APIDropdown
              apiData={{ data: options, loading: loading, refetch: refetch }}
              label={label}
              disabled={disabled}
              error={error?.message}
              placeholder={placeholder}
              required={required}
              value={value}
              onChange={onChange}
              options={options}
              loading={loading}
              hasMultipleSelection={multiple}
              parameterForGet={getKey}
              parameterForSet={setKey}
              onChangeFullData={onChangeFullData}
            />
          )
          // <Autocomplete
          //   loading={loading}
          //   multiple={multiple}
          //   value={
          //     multiple
          //       ? options?.filter((option) =>
          //           value?.find((v) => v === option?.[setKey])
          //         )
          //       : options?.find((option) => option?.[setKey] === value) || null
          //   }
          //   onChange={(e, data) => {
          //     onChange(
          //       multiple ? data?.map((d) => d?.[setKey]) : data?.[setKey]
          //     );
          //     if (onChangeFullData) {
          //       onChangeFullData(data);
          //     }
          //   }}
          //   getOptionLabel={(option) => {
          //     return option?.[getKey];
          //   }}
          //   options={options ? options : []}
          //   renderInput={(params) => (
          //     <TextField
          //       {...params}
          //       inputRef={ref}
          //       placeholder={placeholder}
          //       fullWidth
          //       inputProps={{
          //         ...params.inputProps,
          //         style: { fontSize: "14px", height: "12px" },
          //       }}
          //       error={!!error}
          //       helperText={error?.message}
          //     />
        }
      />
    </Field>
  );
}

function CheckBox({
  methods,
  name,
  label,
  required,
  info,
  disabled,
  onChange: upperOnChange,
  defaultValue,
  labelColor,
}) {
  const { control } = methods;
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={
          ({ field: { ref, value, onChange }, fieldState: { error } }) => (
            <InputCheckBox
              labelColor={labelColor}
              label={label}
              value={value}
              onChange={(v) => {
                if (upperOnChange) {
                  upperOnChange(v);
                }
                onChange(v);
              }}
              disabled={disabled}
            />
          )
          // <TextField
          //   inputRef={ref}
          //   value={value ? value : ""}
          //   onChange={onChange}
          //   multiline={multiline}
          //   rows={multiline ? 3 : undefined}
          //   placeholder={placeholder}
          //   error={!!error}
          //   helperText={error?.message}
          // />
        }
      />
    </Field>
  );
}

function InputRadio({
  methods,
  label,
  required,
  info,
  name,
  multiple,
  options,
  getKey = "printable_name",
  setKey = "id",
  loading,
}) {
  const { control } = methods;
  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="input-label">{label}</div>
            <div style={{ padding: "0px" }} />
            {options?.map((option, index) => (
              <Radio
                key={index}
                label={option[getKey]}
                name={name}
                checked={value === option[setKey]}
                onChange={() => {
                  onChange(option[setKey]);
                }}
              />
              // <FormControlLabel
              //   key={index}
              //   value={option[setKey]}
              //   control={<RadioInput size="small" />}
              //   label={
              //     <InputLabel
              //       sx={{
              //         color: "gray.main",
              //         justifySelf: "end",
              //         fontSize: "14px",
              //         fontWeight: "500",
              //       }}
              //     >
              //       {option[getKey]}
              //     </InputLabel>
              //   }
              //   sx={{ fontSize: "14px" }}
              // />
            ))}
            {!!error && <div className="error-message">{error?.message}</div>}
          </div>
        )}
      />
    </Field>
  );
}

function File({ methods, label, required, info, name, disabled }) {
  // const { getRootProps, getInputProps } = useDropzone();
  const { control, watch, setValue } = methods;

  const watchedValue = watch(name);

  useEffect(() => {
    if (isValidUrl(watchedValue)) {
      const func = async () => {
        const file = await urlTofile(watchedValue);
        setValue(name, file);
      };
      func();
    }
  }, [watchedValue, setValue, name]);

  return (
    <Field label={label + (required ? " *" : "")} info={info}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { ref, value, onChange },
          fieldState: { error },
        }) => (
          <InputFile
            label={label}
            disabled={disabled}
            error={error?.message}
            required={required}
            value={_.isString(value) ? "" : value}
            onChange={onChange}
          />
          // <Box
          //   ref={ref}
          //   sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
          // >
          //   <Box
          //     sx={{
          //       border: !!error ? "2px solid" : "1.5px dashed",
          //       borderColor: !!error ? "error.main" : "text.primary",
          //       padding: "20px",
          //       borderRadius: "5px",
          //       display: "flex",
          //       flexDirection: "column",
          //       alignItems: "center",
          //       justifyContent: "center",
          //       textAlign: "center",
          //       cursor: "pointer",
          //       color: "gray.main",
          //     }}
          //     {...(value?.name
          //       ? {
          //           onClick: () => {
          //             onChange("");
          //           },
          //         }
          //       : getRootProps())}
          //   >
          //     <input
          //       {...getInputProps()}
          //       value={value instanceof File ? URL.createObjectURL(value) : ""}
          //       onChange={(e) => onChange(e.target.files[0])}
          //     />
          //     {value?.name ? <FileIcon /> : <UploadCloud />}
          //     {value?.name ? (
          //       <Box sx={{ fontSize: "14px" }}>
          //         Selected file - {value?.name}{" "}
          //         <Box
          //           sx={{
          //             fontWeight: "600",
          //             color: "primary.main",
          //             textDecoration: "underline",
          //           }}
          //         >
          //           Click to remove selected file
          //         </Box>
          //       </Box>
          //     ) : (
          //       <Box sx={{ fontSize: "14px" }}>
          //         Drag & drop or{" "}
          //         <Box
          //           sx={{
          //             display: "inline",
          //             fontWeight: "600",
          //             color: "primary.main",
          //             textDecoration: "underline",
          //           }}
          //         >
          //           Browse
          //         </Box>
          //       </Box>
          //     )}
          //   </Box>
          //   {!!error && (
          //     <Box
          //       className="Mui-error"
          //       sx={{
          //         color: "error.main",
          //         marginLeft: "14px",
          //         fontSize: "13px",
          //       }}
          //     >
          //       {error?.message}
          //     </Box>
          //   )}
          // </Box>
        )}
      />
    </Field>
  );
}

export const Input = {
  Text,
  Date,
  File,
  Number,
  Select,
  CheckBox,
  Radio: InputRadio,
};
