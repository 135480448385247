import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import GlobalStore from "../../pages/global_store/global_store";
import Brand from "../../pages/masters/brand/brand";
import NewBrand from "../../pages/masters/brand/new_brand";
import FirstCategory from "../../pages/masters/first_category/first_category";
import NewFirstCategory from "../../pages/masters/first_category/new_first_category";
import NewSecondCategory from "../../pages/masters/second_category/new_second_category";
import SecondCategory from "../../pages/masters/second_category/second_category";
import NewThirdCategory from "../../pages/masters/third_category/new_third_category";
import ThirdCategory from "../../pages/masters/third_category/third_category";
import NewProduct from "../../pages/products/product_list/new_product";
import ProductList from "../../pages/products/product_list/product_list";
import NewSeller from "../../pages/seller/sellers/new_sellers";
import Sellers from "../../pages/seller/sellers/sellers";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteGlobal = [
  {
    path: "/global_store",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.GlobalCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <GlobalStore />
      </ProtectedElement>
    ),
  },
  ...makeRoute("products", ProductList, NewProduct, modules.GlobalProduct),
  ...makeRoute("sellers", Sellers, NewSeller, modules.GlobalSeller),
  ...makeRoute("luxury_brand", Brand, NewBrand, modules.GlobalLuxuryBrand, {
    type: "hot",
  }),
  ...makeRoute("premium_brand", Brand, NewBrand, modules.GlobalPremiumBrand, {
    type: "premium",
  }),
  ...makeRoute(
    "first_category",
    FirstCategory,
    NewFirstCategory,
    modules.GlobalCategoryOne
  ),
  ...makeRoute(
    "second_category",
    SecondCategory,
    NewSecondCategory,
    modules.GlobalCategoryTwo
  ),
  ...makeRoute(
    "third_category",
    ThirdCategory,
    NewThirdCategory,
    modules.GlobalCategoryThree
  ),
];

function makeRoute(route, Dashboard, Form, module, props = {}) {
  return [
    {
      path: `/global_store/${route}`,
      content: (
        <ProtectedElement module={module} perm={perms.viewList} page>
          <Dashboard isGlobal {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/global_store/${route}/new`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/global_store/${route}/edit/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.edit} page>
          <Form edit isGlobal {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/global_store/${route}/view/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.view} page>
          <Form view isGlobal {...props} />
        </ProtectedElement>
      ),
    },
    {
      path: `/global_store/${route}/copy/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal {...props} />
        </ProtectedElement>
      ),
    },
  ];
}
