import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./more_info_modal.scss";
import { ReactComponent as CloudIcon } from "../../../images/cloud.svg";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
import moment from "moment/moment";
import { na, na2, namt, naper } from "../../../utils/na";
import { useModal } from "../../../components/Modaler";
import Track from "./track/track";
import {
  sellerAddressFormatter,
  userAddressFormatter,
  userNameFormatter,
} from "../../../utils/addresssFormatter";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const MoreInfoModal = ({ data, statusCode }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Annexture data={data} statusCode={statusCode} />
        </div>
      </div>
    </Modal>
  );
};

const Annexture = ({ data, statusCode }) => {
  const cancel =
    statusCode === "OS_CNCLDRQSTD_032" || statusCode === "OS_CNCLD_029";
  const is_return =
    statusCode === "OS_RTRNRQSTD_015" ||
    statusCode === "OS_RTRND_018" ||
    statusCode === "OS_RTRNDEND_021";
  const not_delivered =
    statusCode === "OS_ ORDERCNFRMD_47" ||
    statusCode === "OS_ ORDERPROCESSED_52" ||
    statusCode === "OS_SHPD_002";
  const delivered =
    statusCode === "OS_DLVRD_003" ||
    statusCode === "OS_RTRND_018" ||
    statusCode === "OS_RPLCD_010";
  const is_refund = statusCode === "OS_RFNDD_020";
  const is_replace =
    statusCode === "OS_RPLCRQSTD_005" ||
    statusCode === "OS_RPLCD_010" ||
    statusCode === "OS_RPLCDND_006";

  const selectedVarient = data?.prod_json?.inventory?.find((d) => {
    const has_size = data?.prod_json?.has_size;
    const has_color = data?.prod_json?.has_color;
    const selected_size_id = data?.size_label;
    const selected_color_id = data?.hexcolor;
    const current_size_id = d?.size_and_price.size;
    const current_color_id = d?.code;
    if (
      has_size &&
      has_color &&
      current_size_id === selected_size_id &&
      current_color_id === selected_color_id
    ) {
      return true;
    }
    if (has_size && !has_color && current_size_id === selected_size_id) {
      return true;
    }
    if (!has_size && has_color && current_color_id === selected_color_id) {
      return true;
    }
    if (!has_size && !has_color) {
      return true;
    }
    return true;
  });

  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <HeadingSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        delivered={delivered}
        selectedVarient={selectedVarient}
      />
      <ProductDetailsSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        delivered={delivered}
        is_replace={is_replace}
        selectedVarient={selectedVarient}
        statusCode={statusCode}
      />
      <CheckBoxSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        selectedVarient={selectedVarient}
      />
      <AddressSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        selectedVarient={selectedVarient}
      />
      <PriceBreakupSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        selectedVarient={selectedVarient}
      />
      <RefundDetailsSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        is_refund={is_refund}
        selectedVarient={selectedVarient}
      />
      <ExtraDetailsSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        selectedVarient={selectedVarient}
      />
      <MoreDetailsSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        selectedVarient={selectedVarient}
      />
      <TaxsSection
        data={data}
        cancel={cancel}
        is_return={is_return}
        not_delivered={not_delivered}
        selectedVarient={selectedVarient}
      />
    </div>
  );
};

const HeadingSection = ({ data: d }) => {
  const modaler = useModal();
  return (
    <div className="heading-section">
      <div className="left-section">
        <h3>
          <b>Product Details</b>
        </h3>
      </div>
      <div className="right-section">
        <div
          className="btns track-btn"
          onClick={() =>
            modaler.opener(<Track close={modaler.closer} data={d} />, true)
          }
        >
          <div className="icon-btn blue-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-truck"
            >
              <path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11" />
              <path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2" />
              <circle cx="7" cy="18" r="2" />
              <path d="M15 18H9" />
              <circle cx="17" cy="18" r="2" />
            </svg>
          </div>
          <div className="blue-title">Track order</div>
        </div>

        {/* <div className="btns print-btn">
          <div className="icon-btn blue-icon">
            <PrintIcon />
          </div>
          <div className="blue-title">Print</div>
        </div> */}
      </div>
    </div>
  );
};

const ProductDetailsSection = ({
  data: d,
  cancel,
  is_return,
  not_delivered,
  delivered,
  is_replace,
  selectedVarient,
  statusCode,
}) => {
  const [selectedImage, setSelectedImage] = useState(0);
  return (
    <div className="product-info">
      <div className="image-section">
        <DashboardImage
          src={selectedVarient?.images?.[selectedImage]?.img}
          height="200px"
          width="200px"
        />
        <div className="image-section-sub">
          {selectedVarient?.images?.map((image, index) => (
            <DashboardImage
              src={image?.img}
              height="50px"
              width="50px"
              className={index === selectedImage ? "active" : ""}
              onClick={() => setSelectedImage(index)}
              noOpen
            />
          ))}
        </div>
      </div>
      <div className="details-section">
        {d?.prod_json?.title && (
          <div style={{ fontSize: "15px", fontWeight: "bold" }}>
            {d?.prod_json.title}
          </div>
        )}
        {d?.prod_json?.description && <div>{d?.prod_json.description}</div>}
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Size : </b>
              {na(d?.size)}
            </div>
            <div>
              <b>Color : </b>
              {na(d?.color)}
            </div>
            <div>
              <b>Quantity : </b>
              {na(d?.quantity)}
            </div>
            {d?.is_b2b && (
              <div>
                <b>Lots : </b>
                {na(d?.unit2lot)}
              </div>
            )}
            <div>
              <b>Selling price : </b>
              {namt(d?.subtotal_after_tax_amt, d?.currency_symbol)}
            </div>
            <div>
              <b>MRP : </b>
              {namt(d?.mrp_qty, d?.currency_symbol)}
            </div>
            <div>
              <b>Brand : </b>
              {na(d?.brand_name)}
            </div>
            <div>
              <b>Sold by : </b>
              {na(d?.prod_json?.seller_name)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Order id : </b>
              {na2(
                d?.order_id,
                <span style={{ color: "#077ACE" }}>{d?.order_id}</span>
              )}
            </div>
            {d?.awb_no && (
              <div>
                <b>AWB no : </b>
                {d?.awb_no}
              </div>
            )}
            <div>
              <b>Order date : </b>
              {na2(d?.created, moment(d?.created).format("DD-MM-YYYY"))}
            </div>
            {statusCode === "OS_ORDRDEND_53" && (
              <div>
                <b>Reason for denial : </b>
                {na(d?.reason_choices)}
              </div>
            )}
            {!cancel && !is_return && (
              <div>
                <b>Tracking id : </b>
                <span style={{ color: "#077ACE" }}>{d?.order_id}</span>
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancel id : </b>
                <span style={{ color: "#077ACE" }}>{na(d?.cancel_id)}</span>
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancel date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancellation reason : </b>
                {na(d?.reason_choices)}
              </div>
            )}
            {is_return && (
              <div>
                <b>Return id : </b>
                <span style={{ color: "#077ACE" }}>{na(d?.return_id)}</span>
              </div>
            )}
            {is_return && delivered && (
              <div>
                <b>Return date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {is_return && (
              <div>
                <b>Reason for returning : </b>
                {na(d?.reason_choices)}
              </div>
            )}
            {is_replace && (
              <div>
                <b>Replace id : </b>
                <span style={{ color: "#077ACE" }}>{na(d?.repalce_id)}</span>
              </div>
            )}
            {is_replace && delivered && (
              <div>
                <b>Replace date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_RPLCRQSTD_005"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_RPLCRQSTD_005")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {is_replace && (
              <div>
                <b>Reason for replacing : </b>
                {na(d?.reason_choices)}
              </div>
            )}
            <div>
              <b>Order status :</b>
              {na2(
                d?.action_name,
                <span style={{ color: "#" + d?.action_color_code }}>
                  {" "}
                  {d?.action_name.toUpperCase()}
                </span>
              )}
            </div>
            {delivered && (
              <div>
                <b>Delivered date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_DLVRD_003"),
                  moment(getDateOfStatus(d?.order_logs, "OS_DLVRD_003")).format(
                    "DD-MM-YYYY"
                  )
                )}
              </div>
            )}
            {not_delivered && (
              <div>
                <b>Expected delivery date :</b>{" "}
                {na2(
                  d?.expected_delivery,
                  moment(d?.expected_delivery).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {d?.delivery_details && (
              <div>
                <b>Logistic partner : </b>
                {d?.delivery_details}
              </div>
            )}
          </div>
        </div>
        <div className="p-10" />
        <div className="downloads">
          {d?.invoice && (
            <a target="_blank" href={d?.invoice} rel="noreferrer">
              <div className="btns print-btn">
                <div className="icon-btn blue-icon">
                  <CloudIcon />
                </div>
                <div className="blue-title">Download invoice</div>
              </div>
            </a>
          )}

          {d?.shipping_label && (
            <a target="_blank" href={d?.shipping_label} rel="noreferrer">
              <div className="btns download-btn">
                <div className="icon-btn purple-icon">
                  <CloudIcon />
                </div>
                <div className="purple-title">Download airway bill</div>
              </div>
            </a>
          )}

          {d?.pkl_label && (
            <a target="_blank" href={d?.pkl_label} rel="noreferrer">
              <div className="btns track-btn">
                <div className="icon-btn purple-icon">
                  <CloudIcon />
                </div>
                <div className="purple-title">Download packing list</div>
              </div>
            </a>
          )}

          {d?.shipping_return_label && (
            <a target="_blank" href={d?.shipping_return_label} rel="noreferrer">
              <div className="btns track-btn">
                <div className="icon-btn purple-icon">
                  <CloudIcon />
                </div>
                <div className="purple-title">
                  Download reverse pickup airwaybill
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const CheckBoxSection = ({ data: d }) => {
  const boxes = [
    {
      text: "Cash on delivery available",
      on: d?.prod_json?.country_details?.cod,
    },
    {
      text: "Cancel applicable",
      on: d?.is_cancellable,
    },
    {
      text: "Return applicable",
      on: d?.is_returnable,
    },
    {
      text: "Replace applicable",
      on: d?.is_replacable,
    },
    {
      text: "Wallet",
      on: d?.user_input_json?.wallet,
    },
    {
      text: "Points",
      on: d?.user_input_json?.points,
    },
    {
      text: "Coupons",
      on: d?.promo_coupons,
    },
  ];

  return (
    <div className="checkbox-section">
      {boxes.map((b, idx) => {
        if (b?.text === "Return applicable") {
          return (
            <div className="cbox" key={idx}>
              {Bool(b?.on)}
              {b.text} (in {d?.return_span} days)
            </div>
          );
        }
        if (b.text === "Replace applicable") {
          return (
            <div className="cbox" key={idx}>
              {Bool(b.on)}
              {b.text} (in {d?.replace_span} days)
            </div>
          );
        }
        return (
          <div className="cbox" key={idx}>
            {Bool(b.on)}
            {b.text}
          </div>
        );
      })}
    </div>
  );
};

const PriceBreakupSection = ({ data: d, cancel, is_return }) => {
  return (
    <Accordian
      head={"Price Breakup"}
      footer={
        !cancel &&
        !is_return && (
          <div style={{ paddingTop: "10px" }}>
            <b>
              Total paid amount :{" "}
              {namt(d?.total_amount_paid_amt, d?.currency_symbol)}
            </b>
          </div>
        )
      }
      body={
        <div className="two-price-sections">
          <div className="payment-box">
            <div
              style={{
                padding: "2px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <b>Total item price</b>
                <b>{namt(d?.product_sell_price_amt, d?.currency_symbol)}</b>
              </div>
            </div>
          </div>
          <div className="payment-box">
            <b>Total order price</b>
            <br />
            <table>
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td>
                    {namt(
                      d?.online_paid_product_before_tax,
                      d?.currency_symbol
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Delivery charges</td>
                  <td>{namt(d?.delivery_before_tax, d?.currency_symbol)}</td>
                </tr>
                <tr>
                  <td>Packing & forwarding charges</td>
                  <td>
                    {namt(d?.pcking_fwdng_before_tax, d?.currency_symbol)}
                  </td>
                </tr>
                <tr>
                  <td>Sub-total before tax</td>
                  <td>
                    {namt(d?.online_paid_amt_before_tax, d?.currency_symbol)}
                  </td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>{namt(d?.online_paid_amt_tax, d?.currency_symbol)}</td>
                </tr>
                <tr>
                  <td>Wallet used</td>
                  <td>{namt(d?.wallet_paid_amt, d?.currency_symbol)}</td>
                </tr>
                <tr>
                  <td>Points used</td>
                  <td>{namt(d?.points_used, d?.currency_symbol)}</td>
                </tr>
                <tr>
                  <td>Online payment</td>
                  <td>{namt(d?.cart_online_paid_amt, d?.currency_symbol)}</td>
                </tr>
                <tr>
                  <td>COD payment</td>
                  <td>
                    {namt(d?.cart_collect_on_delivery_amt, d?.currency_symbol)}
                  </td>
                </tr>
                <tr>
                  <td>Credit used</td>
                  <td>{namt(d?.b2bcredits, d?.currency_symbol)}</td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                borderTop: "1px solid #0000001A",
                padding: "2px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <b>Total order price</b>
                <b>{namt(d?.total_amount_paid_amt, d?.currency_symbol)}</b>
              </div>
              {(cancel || is_return) && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>Refunded amount</b>
                  <b>{namt(d?.refundable_amount_amt, d?.currency_symbol)}</b>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

const AddressSection = ({ data: d }) => {
  return (
    <Accordian
      head={"Address"}
      body={
        <div className="addresses">
          <div className="address-box">
            <center style={{ color: "#0094FF" }}>
              <b>Biling Address</b>
            </center>
            <br />
            {d?.billing_address?.name && (
              <b>{userNameFormatter(d?.billing_address)}</b>
            )}
            <br />
            {userAddressFormatter(d?.billing_address)} <br />
            {d?.billing_address?.email_iD && (
              <>
                Email id : {d?.billing_address.email_iD} <br />
              </>
            )}
            {d?.billing_address?.primary_mobile && (
              <>
                Mobile no : +{d?.billing_address.primary_mobile} <br />
              </>
            )}
            {d?.billing_address?.alternate_mobile && (
              <>
                Secondary no : +{d?.billing_address.alternate_mobile} <br />
              </>
            )}
            {d?.billing_address?.landline && (
              <>
                Landline no : +{d?.billing_address.landline} <br />
              </>
            )}
            {d?.gstin_number && (
              <>
                GSTIN no : {d?.gstin_number} <br />
              </>
            )}
            {d?.pan_number && (
              <>
                PAN no : {d?.gstin_number} <br />
              </>
            )}
            {d?.company_name && (
              <>
                Company no : {d?.company_name} <br />
              </>
            )}
          </div>
          <div className="address-box">
            <center style={{ color: "#0094FF" }}>
              <b>Shipping Address</b>
            </center>
            <br />
            {d?.shipping_address?.name && (
              <b>{userNameFormatter(d?.shipping_address)}</b>
            )}
            <br />
            {userAddressFormatter(d?.shipping_address)} <br />
            {d?.shipping_address?.email_iD && (
              <>
                Email id : {d?.shipping_address.email_iD} <br />
              </>
            )}
            {d?.shipping_address?.primary_mobile && (
              <>
                Mobile no : +{d?.shipping_address.primary_mobile} <br />
              </>
            )}
            {d?.shipping_address?.alternate_mobile && (
              <>
                Secondary no : +{d?.shipping_address.alternate_mobile} <br />
              </>
            )}
            {d?.shipping_address?.landline && (
              <>
                Landline no : +{d?.shipping_address.landline} <br />
              </>
            )}
          </div>
          <div className="address-box">
            <center style={{ color: "#0094FF" }}>
              <b>Seller Address</b>
            </center>
            <br />
            <b>{d?.pickup_address?.contact_name}</b>
            <br />
            {sellerAddressFormatter(d?.pickup_address)}
            <div>
              <b>Facility GSTIN number : </b>
              {na(d?.pickup_address?.facility_gstin_number)}
            </div>
            <div>
              <b>Facility name : </b>
              {na(d?.pickup_address?.facility_name)}
            </div>
          </div>
        </div>
      }
    />
  );
};

const RefundDetailsSection = ({ data: d, is_refund }) => {
  if (!is_refund) return null;

  const get_refund_mode = () => {
    if (d?.refund_to_wallet) {
      return "Wallet";
    }
    if (d?.refund_to_bank) {
      return "Bank";
    }
    if (d?.refund_to_source) {
      if (d?.user_input_json?.payment_method === "Cash On Delivery") {
        return "UPI";
      } else {
        return "Original source of payment";
      }
    }
    return undefined;
  };

  return (
    <Accordian
      head={"Refund"}
      body={
        <div className="extra-details-section">
          <div className="extra-box" style={{ gap: "100px" }}>
            <div>
              <b>Refund detail</b>
            </div>
            <div>
              <b>Refund mode : </b>
              {na(get_refund_mode())}
            </div>
            <div
              style={{
                borderTop: "1px solid #0000001A",
                padding: "2px 0px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <b>Refund amount : </b>
                {namt(d?.refundable_amount_amt, d?.currency_symbol)}
              </div>
            </div>
          </div>
          <div className="extra-box">
            <div className="utr">
              <div className="utr-title">
                UTR (Unique Transaction Reference)
              </div>
              <div className="utr-body">{na(d?.utr)}</div>
              <div className="utr-hidden">&nbsp;</div>
            </div>
          </div>
        </div>
      }
    />
  );
};

const ExtraDetailsSection = ({ data: d }) => {
  return (
    <Accordian
      head={"Additional Order & Billing Details"}
      body={
        <div className="extra-details-section">
          <div className="extra-box">
            <div>
              <b>Place of supply : </b>
              {na(d?.pickup_address?.state)}
            </div>
            <div>
              <b>Place of delivery : </b>
              {na(d?.shipping_address?.state_name)}
            </div>
            <div>
              <b>Shipped date : </b>
              {na2(
                getDateOfStatus(d?.order_logs, "OS_SHPD_002"),
                moment(getDateOfStatus(d?.order_logs, "OS_SHPD_002")).format(
                  "DD-MM-YYYY"
                )
              )}
            </div>
            <div>
              <b>Invoice date : </b>
              {na2(
                getDateOfStatus(d?.order_logs, "OS_ ORDERPROCESSED_52"),
                moment(
                  getDateOfStatus(d?.order_logs, "OS_ ORDERPROCESSED_52")
                ).format("DD-MM-YYYY")
              )}
            </div>
            <div>
              <b>Order date : </b>
              {na2(d?.created, moment(d?.created).format("DD-MM-YYYY"))}
            </div>
          </div>
          <div className="extra-box">
            <div>
              <b>Technical datasheet : </b>
              {na2(
                d?.prod_json?.data_upload,
                <a
                  target="_blank"
                  href={d?.prod_json?.data_upload}
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Download Datasheet
                </a>
              )}
            </div>
            <div>
              <b>First category : </b>
              {na(d?.prod_json?.first_category)}
            </div>
            <div>
              <b>Second category : </b>
              {na(d?.prod_json?.second_category)}
            </div>
            <div>
              <b>Third category : </b>
              {na(d?.prod_json?.third_category)}
            </div>
            <div>
              <b>Sku id : </b>
              {na(d?.prod_json?.skuid)}
            </div>
            <div>
              <b>HSN code : </b>
              {(d?.prod_json?.country_details?.goods_tax_all &&
                d?.prod_json?.country_details?.goods_tax_all?.length > 0 &&
                d?.prod_json?.country_details?.goods_tax_all[0]?.tax_code) ||
                "N/A"}
            </div>
            <div>
              <b>Search key : </b>
              {na(d?.prod_json?.search_keys)}
            </div>
          </div>
          <div className="extra-box">
            <div>
              <b>Payment transaction id : </b>
              {na(d?.payment_id)}
            </div>
            <div>
              <b>Payment date : </b>
              {na2(
                getDateOfStatus(d?.order_logs, "OS_ ORDERCNFRMD_47"),
                moment(
                  getDateOfStatus(d?.order_logs, "OS_ ORDERCNFRMD_47")
                ).format("DD-MM-YYYY")
              )}
            </div>
            <div>
              <b>Mode of payment : </b>
              {na(d?.payment_method)}
            </div>
            <div>
              <b>Order type : </b>
              {d?.is_b2b ? "B2B" : "B2C"}
            </div>
            <div>
              <b>Currency symbol : </b>
              {na(d?.currency_symbol)}
            </div>
            <div>
              <b>Country name : </b>
              {na(d?.country_name)}
            </div>
            <div>
              <b>Currency code : </b>
              {na(d?.currency_code)}
            </div>
            <div>
              <b>Biling id : </b>
              {na(d?.billing_id)}
            </div>
            <div>
              <b>Shipping id : </b>
              {na(d?.shipping_id)}
            </div>
          </div>
          <div className="extra-box">
            <div>
              <b>Business type : </b>
              {d?.is_company ? "B2B" : "B2C"}
            </div>
            <div>
              <b>Unique id : </b>
              {na(d?.uniqueid)}
            </div>
            <div>
              <b>Payment status : </b>
              {na(d?.payment_status)}
            </div>
            <div>
              <b>Paid status : </b>
              {na(d?.paid_status)}
            </div>
            <div>
              <b>Payment link id : </b>
              {na(d?.payment_link_id)}
            </div>
            <div>
              <b>Acquirer status : </b>
              {na(d?.acquirer_status)}
            </div>
          </div>
          {d?.acquirer_response && (
            <div className="extra-box" style={{ gridColumn: "1 / -1" }}>
              <div>
                <b>Acquirer response</b>
                <pre>{JSON.stringify(d?.acquirer_response, null, 2)}</pre>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

const MoreDetailsSection = ({ data: d }) => {
  const array = d?.prod_json?.information;
  const [active, setActive] = useState(0);

  if (!array) return null;

  return (
    <Accordian
      head={"Product Specification"}
      body={
        <div className="more-details-section">
          <div className="nav-section">
            {array?.map((item, idx) => (
              <div
                key={idx}
                className={"nav-item " + (active === idx ? "active" : "")}
                onClick={() => setActive(idx)}
              >
                {item.product_info}
              </div>
            ))}
          </div>
          <table>
            <tbody>
              {array[active]?.all_parameters?.map((item, idx) =>
                item?.values?.length > 0 ? (
                  <tr key={idx}>
                    <td>{item?.product_property_type}</td>
                    <td>
                      {item?.values &&
                        item?.values?.length > 0 &&
                        item?.values[0]?.name}
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      }
    />
  );
};

const TaxsSection = ({ data }) => {
  const d = data.prod_price;
  return (
    <Accordian
      head={"Price & Taxes"}
      body={
        <div className="two-tax-boxs">
          <div className="tax-box">
            <center style={{ color: "#0094FF" }}>
              <b>Price and Tax</b>
            </center>
            <br />
            <div>
              <b>Basic price range start : </b>
              {namt(d?.price_range_start, data.currency_symbol)}
            </div>
            <div>
              <b>Basic price range end : </b>
              {namt(d?.price_range_end, data.currency_symbol)}
            </div>
            <div>
              <b>Basic price value : </b>
              {namt(d?.price_value, data.currency_symbol)}
            </div>
            <div>
              <b>Country converstion factor : </b>
              {naper(d?.country_tax_id?.rate_of_change_product)}
            </div>
            <div>
              <b>Country basic price : </b>
              {namt(d?.product_price_without_tax, data.currency_symbol)}
            </div>
            <div>
              <b>HSN code : </b>
              {na(d?.goods_tax_code_id?.name)}
            </div>
            <div>
              <b>HSN Tax name : </b>
              {na(d?.["tax_%es"]?.[0]?.tax_name)}
            </div>
            <div>
              <b>HSN percent : </b>
              {naper(d?.["tax_%es"]?.[0]?.tax_percent)}
            </div>
            <div>
              <b>Tax on country price : </b>
              {namt(d?.tax_on_product, data.currency_symbol)}
            </div>
            <div>
              <b>Discount percent range start : </b>
              {naper(d?.disc_percent_range_start)}
            </div>
            <div>
              <b>Discount percent range end : </b>
              {naper(d?.disc_percent_range_end)}
            </div>
            <div>
              <b>Discount percent : </b>
              {naper(d?.disc_percent_value)}
            </div>
            <div>
              <b>Discount value(with tax) : </b>
              {namt(d?.calculated_discount, data.currency_symbol)}
            </div>
            <div>
              <b>Discount value(without tax) : </b>
              {namt(
                (
                  Number(d?.product_price_without_tax) -
                  Number(d?.discounted_price_without_tax)
                )?.toFixed(2),
                data.currency_symbol
              )}
            </div>
            <div>
              <b>Discounted selling price(with tax) : </b>
              {namt(d?.discounted_price, data.currency_symbol)}
            </div>
            <div>
              <b>Discounted selling price(without tax) : </b>
              {namt(d?.discounted_price_without_tax, data.currency_symbol)}
            </div>
          </div>
          {d?.charges?.map((c, index) => (
            <div className="tax-box" key={index}>
              <center style={{ color: "#0094FF" }}>
                <b>{c?.charge_name_id?.charge_name}</b>
              </center>
              <br />
              <div>
                <b>Charges basic price range start : </b>
                {namt(c?.price_range_start, data.currency_symbol)}
              </div>
              <div>
                <b>Charges basic price range end : </b>
                {namt(c?.price_range_end, data.currency_symbol)}
              </div>
              <div>
                <b>Charges basic price : </b>
                {namt(c?.price_value, data.currency_symbol)}
              </div>
              <div>
                <b>Country converstion factor : </b>
                {naper(d?.country_tax_id?.rate_of_change_service)}
              </div>
              <div>
                <b>Country charges basic price : </b>
                {namt(c?.charges_no_tax, data.currency_symbol)}
              </div>
              <div>
                <b>SAC code : </b>
                {na(c?.service_tax_code_id?.name)}
              </div>
              <div>
                <b>SAC Tax name : </b>
                {na(c?.["tax_%es"]?.[0]?.tax_name)}
              </div>
              <div>
                <b>SAC percent : </b>
                {naper(c?.["tax_%es"]?.[0]?.tax_percent)}
              </div>
              <div>
                <b>Tax on country charges basic price : </b>
                {namt(c?.charge_tax_value, data.currency_symbol)}
              </div>
              <div>
                <b>Total charges(with tax) : </b>
                {namt(c?.charge_with_tax, data.currency_symbol)}
              </div>
            </div>
          ))}
        </div>
      }
    />
  );
};

const Accordian = ({ head, body, footer }) => {
  const [open, setOpen] = useState(false);
  const height = 10000;

  return (
    <div className={"acc " + (open ? "open" : "")}>
      <div className="acc-head" onClick={() => setOpen(!open)}>
        <b>{head}</b>
        <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
      </div>
      <div
        className={"acc-body " + (open ? "open" : "")}
        style={{ maxHeight: open ? height : 0 }}
      >
        {body}
        {footer}
      </div>
    </div>
  );
};

export function getDateOfStatus(logs, status) {
  return logs?.find((log) => log?.action_code === status)?.created_at;
}

const Bool = (value) => {
  return (
    <span className="cbox">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 15 16"
        fill="none"
        style={{ flexShrink: 0 }}
      >
        <rect
          width="15"
          height="15"
          transform="translate(0 0.617981)"
          fill={value ? "#028917" : "#ffffff"}
          stroke="rgba(0, 0, 0, 0.5)"
          rx="2"
        />
        {value && (
          <path
            d="M13.125 4.99297L5.625 12.493L2.1875 9.05548L3.06875 8.17423L5.625 10.7242L12.2437 4.11172L13.125 4.99297Z"
            fill="white"
          />
        )}
      </svg>
    </span>
  );
};

export default MoreInfoModal;
