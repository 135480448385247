import React from "react";
import { useTimeout } from "./useTimeout";
import "./Toast.scss";

export const Toast = (props) => {
  useTimeout(props.close, 5500);
  return (
    <div className={"toast toast-" + props.Variant}>
      <div className="toast-text-side">
        <div className="toast-heading">{props.Variant}</div>
        <div className="toast-sub-heading">{props.children}</div>
      </div>
      <button onClick={props.close} className="toast_close-btn">
        &#10005;
      </button>
      <div className="toast-progress-bar" />
    </div>
  );
};
