import { useLocation } from "react-router-dom";
import { InputText } from "../../components/Inputs/Inputs";
import { Button, Header } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { api, apiPublic } from "../../api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./upd_tmp_pswd.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userStore";
import { useToast } from "../../components/Toast";
import { update_password_url, user_logout_url } from "../../api/urls";
import unknownError from "../../utils/unknownError";
import { Eye, EyeOff } from "lucide-react";

const UpdTmpPwsd = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const token = decodeURIComponent(searchParams.get("token"));
  const pwd_temp = useSelector((state) => state.user.pwd_temp);
  const dispatch = useDispatch();
  const toast = useToast();

  const [retryPass, setRetryPass] = useState("");
  const [retryError, setRetryError] = useState("");
  const [password, setpassword] = useState("");
  const [passError, setPassError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [retryType, setRetryType] = useState("password");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRetryError("");
  }, [retryPass]);

  useEffect(() => {
    setPassError("");
  }, [password]);

  function handleSubmit(event) {
    event.preventDefault();
    if (password !== retryPass) {
      setRetryError("Password and confirm password should be same");
      return;
    }
    try {
      setLoading(true);
      if (token === "null") {
        api
          .post(update_password_url, {
            password,
            confirm_password: retryPass,
          })
          .then(async (res) => {
            try {
              await api.get(user_logout_url);
            } catch (e) {
              console.log(e);
            }
            toast.open(
              "Password has been resetted successfully. Please login using new password.",
              "success"
            );
            setLoading(false);
            await dispatch(logout());
            history.push("/");
          })
          .catch((err) => {
            setLoading(false);
            unknownError(err);
          });
      } else {
        apiPublic
          .post(update_password_url, {
            token,
            password,
            confirm_password: retryPass,
          })
          .then(async (res) => {
            try {
              await api.get(user_logout_url);
            } catch (e) {
              console.log(e);
            }
            toast.open(
              "Password has been resetted successfully. Please login using new password.",
              "success"
            );
            await dispatch(logout());
            history.push("/");
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            unknownError(err);
          });
      }
    } catch (err) {
      setLoading(false);
      unknownError(err);
    }
  }

  return (
    <div className="upd-page">
      <div className="layout">
        <div id="segment-upd">
          <form id="form-upd" onSubmit={handleSubmit}>
            <Header textAlign="center">Password Reset</Header>
            {pwd_temp === true ? (
              <>
                <div className="error-message">
                  You are using a temporarily assigned password. Please reset
                  your password.
                </div>
              </>
            ) : null}
            <div style={{ padding: "10px" }} />
            <InputText
              label="Password"
              placeholder="Password"
              onChange={(v) => setpassword(v)}
              error={passError}
              required
              type={passwordType}
              icon={
                <div
                  onClick={() => {
                    setPasswordType(
                      passwordType === "password" ? "text" : "password"
                    );
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                  }}
                >
                  {passwordType === "password" ? (
                    <EyeOff size={20} />
                  ) : (
                    <Eye size={20} />
                  )}
                </div>
              }
            />
            <div style={{ padding: "10px" }} />
            <InputText
              label="Confirm password"
              placeholder="Confirm password"
              onChange={(v) => setRetryPass(v)}
              error={retryError}
              type={retryType}
              required
              icon={
                <div
                  onClick={() => {
                    setRetryType(
                      retryType === "password" ? "text" : "password"
                    );
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                  }}
                >
                  {retryType === "password" ? (
                    <EyeOff size={20} />
                  ) : (
                    <Eye size={20} />
                  )}
                </div>
              }
            />
            <div style={{ padding: "5px" }} />
            <Button
              secondary
              type="submit"
              id="button-upd"
              fluid
              disabled={loading}
            >
              Reset password
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdTmpPwsd;
