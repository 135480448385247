import { tagon_customer_url } from "../../../api/urls";
import { Link } from "react-router-dom";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { TextChip } from "../../../components/TextChip/TextChip";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { useModal } from "../../../components/Modaler";

export function B2BEligibleUsers() {
  const apiUrl = tagon_customer_url + "?bnpl=true";
  const title = "Eligible Users";
  const module = modules.B2BEligibleUsers;
  const currentRoute = "eligible_users";
  const modaler = useModal();
  const { data, pagination } = useFetchWithPagination(apiUrl);
  const columns = [
    {
      headerName: "Profile Image",
      field: "user",
      renderCell: (params) => (
        <DashboardImage
          height="50px"
          width="50px"
          circle
          src={params.value.profile_image}
        />
      ),
    },
    {
      headerName: "Name",
      field: "user",
      renderCell: (params) => params.value.full_name,
    },
    {
      headerName: "Mobile number",
      field: "user",
      renderCell: (params) => params.value.mobile_number,
    },
    {
      headerName: "Credit limit",
      field: "credit",
      renderCell: (params) => (
        <div className="flex-column">
          {params?.value?.map((c, index) => (
            <div key={index}>
              {c.currency_sym}
              {c.amount}
            </div>
          ))}
        </div>
      ),
    },
    {
      headerName: "Credit Period",
      field: "user",
      renderCell: (params) => `${params?.value?.credit_period} days`,
    },
    {
      headerName: "Status",
      renderCell: (params) => (
        <TextChip
          color={params?.row?.user?.no_order ? "#b91c1c" : "#03A326"}
          text={params?.row?.user?.no_order ? "Blocked" : "Verified"}
        />
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <>
                <ProtectedElement module={module} perm={perms.b2bCreditAssign}>
                  <Link to={currentRoute + `/add_credit/${params.row.user.id}`}>
                    <div
                      className="option"
                      onClick={() => {
                        closer();
                      }}
                    >
                      <div className="purple-title">Set Credit Limit</div>
                    </div>
                  </Link>
                </ProtectedElement>
                <ProtectedElement module={module} perm={perms.b2bExtendDue}>
                  <Link
                    to={
                      currentRoute +
                      `/transaction_history/${params.row.user.id}`
                    }
                  >
                    <div
                      className="option"
                      onClick={() => {
                        closer();
                      }}
                    >
                      <div className="purple-title">Transaction history</div>
                    </div>
                  </Link>
                </ProtectedElement>
                <ProtectedElement module={module} perm={perms.b2bLock}>
                  <div
                    className="option"
                    onClick={() => {
                      modaler.opener(
                        <ChangeB2BBlockStatus
                          d={params.row}
                          refetch={data.refetch}
                          closer={modaler.closer}
                        />,
                        false
                      );
                    }}
                  >
                    <div className="purple-title">
                      {params.row?.user?.no_order ? "Unblock" : "Block"}
                    </div>
                  </div>
                </ProtectedElement>
                <ProtectedElement module={module} perm={perms.getB2BDocs}>
                  <Link
                    to={
                      currentRoute + `/update_documents/${params.row.user.id}`
                    }
                  >
                    <div
                      className="option"
                      onClick={() => {
                        closer();
                      }}
                    >
                      <div className="purple-title">View/Update Documents</div>
                    </div>
                  </Link>
                </ProtectedElement>
              </>
            );
          }}
        />
      ),
    },
  ];

  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}

function ChangeB2BBlockStatus({ d, refetch, closer }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return (
    <div style={{ padding: "40px" }}>
      <div style={{ fontSize: "20px", fontWeight: "500" }}>Are you sure?</div>
      <div style={{ padding: "20px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <button
          className="btn-red"
          onClick={() => {
            closer();
          }}
        >
          No
        </button>
        <button
          className="btn"
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await api.post(tagon_customer_url + d?.user?.id + "/b2bLocked/", {
                b2b: !d?.user?.no_order,
              });
              closer();
              refetch();
              toast.open("Status change", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setLoading(false);
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
}
