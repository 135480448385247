import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputImage,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import objToFormdata from "../../../utils/objToFormdata";
import { useToast } from "../../../components/Toast";
import {
  bsecond_category_url,
  bthird_category_url,
  gsecond_category_url,
  gthird_category_url,
  product_questions_url,
  second_category_url,
  third_category_url,
} from "../../../api/urls";
import { urlTofile } from "../../../utils/urlTofile";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";
import Loading from "../../../components/Loading/Loading";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { modules } from "../../../api/codes";

const module = modules.ThirdCategory;

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  third: "",
  cat03_image: "",
  is_global: false,
  category_second: "",
  questions: "",
  return_span: "",
  replace_span: "",
};
const title = "Third Category";

const NewThirdCategory = ({ edit, view, isGlobal, isB2B }) => {
  const apiUrl = isB2B
    ? bthird_category_url
    : isGlobal
    ? gthird_category_url
    : third_category_url;
  const backUrl = isB2B
    ? "/b2b_store/third_category"
    : isGlobal
    ? "/global_store/third_category"
    : "/masters/third_category";
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: module },
          });
          res.data.cat03_image = await urlTofile(res.data.cat03_image);
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view, apiUrl]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        delete newData.docs_data;
        if (isGlobal) {
          newData.is_global = true;
        }
        if (isB2B) {
          newData.is_b2b = true;
        }
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", objToFormdata(newData), {
                headers: { module: module },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, objToFormdata(newData), {
                headers: { module: module },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          onChange={(v) => set("description", v)}
        />
        <InputText
          label="Category name"
          placeholder="Category name"
          disabled={view}
          value={data.third}
          error={errors["third"]}
          required
          onChange={(v) => set("third", v)}
        />
        <InputImage
          label="Category image 3"
          placeholder="Category image 3"
          disabled={view}
          value={data.cat03_image}
          error={errors["cat03_image"]}
          required
          onChange={(v) => set("cat03_image", v)}
        />
        <APIDropdown
          label="Category second"
          placeholder="Category second"
          required
          disabled={view}
          value={data.category_second}
          url={
            isB2B
              ? bsecond_category_url
              : isGlobal
              ? gsecond_category_url
              : second_category_url
          }
          onChange={(v) => set("category_second", v)}
          error={errors["category_second"]}
          module={module}
        />
        <APIDropdown
          label="Questions"
          placeholder="Questions"
          required
          disabled={view}
          value={data.questions}
          url={product_questions_url}
          onChange={(v) => set("questions", v)}
          error={errors["questions"]}
          hasMultipleSelection
          parameterForGet="question"
          module={module}
        />
        <InputText
          label="Return span"
          placeholder="Return span"
          disabled={view}
          value={data.return_span}
          error={errors["return_span"]}
          required
          onChange={(v) => set("return_span", v)}
        />
        <InputText
          label="Replace span"
          placeholder="Replace span"
          disabled={view}
          value={data.replace_span}
          error={errors["replace_span"]}
          required
          onChange={(v) => set("replace_span", v)}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewThirdCategory;
