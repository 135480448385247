import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./more_info_modal.scss";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
// import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import moment from "moment/moment";
import { api } from "../../../api/api";
import { seller_address, seller_bank } from "../../../api/urls";
import { useEffect } from "react";
import unknownError from "../../../utils/unknownError";
// import NewSellerAddressModal from "./new_seller_address";
// import AddressDeleteModal from "./delete_confirm_modal";
// import NewSellerBankModal from "./new_seller_bank";
// import EditSellerProfileModal from "./edit_seller_profile";
import Loading from "../../../components/Loading/Loading";
import { na, na2 } from "../../../utils/na";
import { sellerAddressFormatter } from "../../../utils/addresssFormatter";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const MoreInfoModal = ({ data, refetch, isGlobal }) => {
  const module = isGlobal ? modules.GlobalSeller : modules.Seller;

  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState();
  const [addressRefetcher, setAddressRefetcher] = useState(false);
  const [banks, setBanks] = useState();
  const [bankRefetcher, setBankRefetcher] = useState(false);
  // const [docs, setDocs] = useState();
  // const [docsRefetcher, setDocsRefetcher] = useState(false);
  // const [certs, setCerts] = useState();
  // const [certsRefetcher, setCertsRefetcher] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(seller_address + "?seller=" + data.id);
        setAddresses(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.id, open, addressRefetcher]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(seller_bank + "?seller=" + data.id);
        setBanks(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.id, open, bankRefetcher]);

  // useEffect(() => {
  //   const get = async () => {
  //     try {
  //       const res = await api.get(seller_docs + "?seller=" + data.id);
  //       setDocs(res.data.filter((x) => x.doc_kyc === true));
  //     } catch (err) {
  //       unknownError(err);
  //     }
  //   };
  //   if (open) {
  //     get();
  //   }
  // }, [data.id, open, docsRefetcher]);

  // useEffect(() => {
  //   const get = async () => {
  //     try {
  //       const res = await api.get(seller_docs + "?seller=" + data.id);
  //       setCerts(res.data.filter((x) => x.doc_certificate === true));
  //     } catch (err) {
  //       unknownError(err);
  //     }
  //   };
  //   if (open) {
  //     get();
  //   }
  // }, [data.id, open, certsRefetcher]);

  const addressRefetch = () => {
    setAddressRefetcher((v) => !v);
  };

  const bankRefetch = () => {
    setBankRefetcher((v) => !v);
  };

  // const docsRefetch = () => {
  //   setDocsRefetcher((v) => !v);
  // };

  // const certsRefetch = () => {
  //   setCertsRefetcher((v) => !v);
  // };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Annexture
            data={data}
            addresses={addresses}
            addressRefetch={addressRefetch}
            banks={banks}
            bankRefetch={bankRefetch}
            // docs={docs}
            // docsRefetch={docsRefetch}
            // certs={certs}
            // certsRefetch={certsRefetch}
            refetch={refetch}
            isGlobal={isGlobal}
            module={module}
          />
        </div>
      </div>
    </Modal>
  );
};

const Annexture = ({
  data,
  addresses,
  addressRefetch,
  banks,
  bankRefetch,
  // docs,
  // docsRefetch,
  // certs,
  // certsRefetch,
  refetch,
  isGlobal,
  module,
}) => {
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <HeadingSection data={data} refetch={refetch} module={module} />
      <ProfileDetailsSection
        data={data}
        refetch={refetch}
        module={module}
        isGlobal={isGlobal}
      />
      <AddressSection
        data={data}
        addresses={addresses}
        refetch={addressRefetch}
        module={module}
      />
      <BankSection
        data={data}
        banks={banks}
        refetch={bankRefetch}
        module={module}
      />
      {/* <DocSection
        data={data}
        docs={docs}
        refetch={docsRefetch}
        module={module}
      />

      <CertSection
        data={data}
        certs={certs}
        refetch={certsRefetch}
        module={module}
      /> */}
    </div>
  );
};

const HeadingSection = ({ data: d, refetch }) => {
  return (
    <div className="heading-section">
      <div className="left-section">
        <h3>
          <b>Seller Details</b>
        </h3>
      </div>
      <div className="right-section"></div>
    </div>
  );
};

const ProfileDetailsSection = ({
  data: d,
  cancel,
  is_return,
  refetch,
  isGlobal,
  module,
}) => {
  return (
    <div className="product-info">
      <div className="image-section">
        <DashboardImage
          src={d.company_image}
          height="250px"
          width="250px"
          circle
        />
      </div>
      <div className="details-section" style={{ position: "relative" }}>
        <ProtectedElement module={module} perm={perms.edit}>
          {/* <EditSellerProfileModal
            refetch={refetch}
            seller_id={d.id}
            editData={d}
            isGlobal={isGlobal}
            trigger={
              <div
                className="i-btn"
                style={{ position: "absolute", right: "10px", top: "10px" }}
              >
                <EditIcon width={10} height={10} />
              </div>
            }
          /> */}
        </ProtectedElement>
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Name : </b>
              {na2(d.firstname && d.lastname, d.firstname + " " + d.lastname)}
            </div>
            <div>
              <b>Email id : </b>
              {na(d.email_id)}
            </div>
            <div>
              <b>Mobile number : </b>
              {na2(d.mobile_number, <>+{d.mobile_number}</>)}
            </div>
            <div>
              <b>Company name : </b>
              {na(d.company_name)}
            </div>
            <div>
              <b>Company email id : </b>
              {na(d.company_email_id)}
            </div>
            <div>
              <b>Website url : </b>
              {na(d.website)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Landline number : </b>
              {na2(d.landline_number, <>+{d.landline_number}</>)}
            </div>
            <div>
              <b>GSTIN number : </b>
              {na(d.gstin_number)}
            </div>
            <div>
              <b>PAN number : </b>
              {na(d.pan_number)}
            </div>
            <div>
              <b>TAN number : </b>
              {na(d.tan_number)}
            </div>
            <div>
              <b>CIN number : </b>
              {na(d.cin_number)}
            </div>
            <div>
              <b>Date of incorporation : </b>
              {na2(
                d.date_of_incoperate,
                <>{moment(d.date_of_incoperate).format("DD-MM-YYYY")}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressSection = ({ data, addresses, refetch, module }) => {
  return (
    <Accordian
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
          }}
        >
          <div>Address</div>
          {/* <ProtectedElement module={module} perm={perms.add}>
            <NewSellerAddressModal
              refetch={refetch}
              seller_id={data.id}
              trigger={
                <div className="button" style={{ padding: "2px 10px" }}>
                  Add new
                </div>
              }
            />
          </ProtectedElement> */}
        </div>
      }
      alwaysOpen
      body={
        <>
          {addresses === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {addresses && addresses.length > 0
                ? addresses.map((d, idx) => (
                    <div className="address-box" key={idx}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "5px",
                        }}
                      >
                        {/* <ProtectedElement module={module} perm={perms.edit}>
                          <NewSellerAddressModal
                            refetch={refetch}
                            seller_id={data.id}
                            editData={d}
                            trigger={
                              <div className="i-btn">
                                <EditIcon width={10} height={10} />
                              </div>
                            }
                          />
                        </ProtectedElement> */}
                        {/* <ProtectedElement module={module} perm={perms.delete}>
                          <AddressDeleteModal
                            data={d}
                            refetch={refetch}
                            dropdownCloser={() => false}
                            route={seller_address}
                          />
                        </ProtectedElement> */}
                      </div>
                      <div className="p-5" />
                      {sellerAddressFormatter(d)}
                    </div>
                  ))
                : "No data entered yet."}
            </div>
          )}
        </>
      }
    />
  );
};

const BankSection = ({ data, banks, refetch, module }) => {
  return (
    <Accordian
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
          }}
        >
          <div>Bank Details</div>
          {/* <ProtectedElement module={module} perm={perms.add}>
            <NewSellerBankModal
              refetch={refetch}
              seller_id={data.id}
              trigger={
                <div className="button" style={{ padding: "2px 10px" }}>
                  Add new
                </div>
              }
            />
          </ProtectedElement> */}
        </div>
      }
      alwaysOpen
      body={
        <>
          {banks === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {banks && banks.length > 0
                ? banks.map((d, idx) => (
                    <div className="address-box" key={idx}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "5px",
                        }}
                      >
                        {/* <ProtectedElement module={module} perm={perms.edit}>
                          <NewSellerBankModal
                            refetch={refetch}
                            seller_id={data.id}
                            editData={d}
                            trigger={
                              <div className="i-btn">
                                <EditIcon width={10} height={10} />
                              </div>
                            }
                          />
                        </ProtectedElement> */}
                        {/* <ProtectedElement module={module} perm={perms.delete}>
                          <AddressDeleteModal
                            data={d}
                            refetch={refetch}
                            dropdownCloser={() => false}
                            route={seller_bank}
                          />
                        </ProtectedElement> */}
                      </div>
                      <div>
                        <b>Code : </b>
                        {na(d.code)}
                      </div>
                      <div>
                        <b>Bank name : </b>
                        {na(d.bank_name)}
                      </div>
                      <div>
                        <b>Bank address : </b>
                        {na(d.bank_branch_address)}
                      </div>
                      <div>
                        <b>Account name : </b>
                        {na(d.account_name)}
                      </div>
                      <div>
                        <b>Account number : </b>
                        {na(d.account_number)}
                      </div>
                      <div>
                        <b>IFSC code : </b>
                        {na(d.ifsc_code)}
                      </div>
                      <div>
                        <b>UPI id : </b>
                        {na(d.upi_id_number)}
                      </div>
                    </div>
                  ))
                : "No data entered yet."}
            </div>
          )}
        </>
      }
    />
  );
};

// const DocSection = ({ data, docs, refetch, module }) => {
//   return (
//     <Accordian
//       head={
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//             flex: 1,
//           }}
//         >
//           <div>KYC Document Details</div>
//           <ProtectedElement module={module} perm={perms.add}>
//             <NewSellerDocModal
//               refetch={refetch}
//               seller_id={data.id}
//               trigger={
//                 <div className="button" style={{ padding: "2px 10px" }}>
//                   Add new
//                 </div>
//               }
//             />
//           </ProtectedElement>
//         </div>
//       }
//       alwaysOpen
//       body={
//         <>
//           {docs === undefined ? (
//             <div style={{ height: "200px" }}>
//               <Loading />
//             </div>
//           ) : (
//             <div className="address-box-wrapper">
//               {docs && docs.length > 0
//                 ? docs.map((d, idx) => (
//                     <div className="address-box" key={idx}>
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "flex-end",
//                           gap: "5px",
//                         }}
//                       >
//                         <ProtectedElement module={module} perm={perms.edit}>
//                           <NewSellerDocModal
//                             refetch={refetch}
//                             seller_id={data.id}
//                             editData={d}
//                             trigger={
//                               <div className="i-btn">
//                                 <EditIcon width={10} height={10} />
//                               </div>
//                             }
//                           />
//                         </ProtectedElement>
//                         <ProtectedElement module={module} perm={perms.delete}>
//                           <AddressDeleteModal
//                             data={d}
//                             refetch={refetch}
//                             dropdownCloser={() => false}
//                             route={seller_docs}
//                           />
//                         </ProtectedElement>
//                       </div>
//                       <div>
//                         <b>Code : </b>
//                         {na(d.code)}
//                       </div>
//                       <div>
//                         <b>Document name : </b>
//                         {na(d.doc_name)}
//                       </div>
//                       <div>
//                         <b>Document number : </b>
//                         {na(d.doc_number)}
//                       </div>
//                       <div>
//                         <b>Document expiry : </b>
//                         {na2(
//                           d.doc_expiry,
//                           <>{moment(d.doc_expiry).format("DD-MM-YYYY")}</>
//                         )}
//                       </div>
//                       <div>
//                         <b>Document file : </b>
//                         {na2(
//                           d.doc_file,
//                           <a
//                             className="blue-link"
//                             href={d.doc_file}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             View File
//                           </a>
//                         )}
//                       </div>
//                       <div>
//                         <b>Verified : </b>
//                         {boolSmall(d.is_verified)}
//                       </div>
//                     </div>
//                   ))
//                 : "No data entered yet."}
//             </div>
//           )}
//         </>
//       }
//     />
//   );
// };

// const CertSection = ({ data, certs, refetch, module }) => {
//   return (
//     <Accordian
//       head={
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//             flex: 1,
//           }}
//         >
//           <div>KYC Certificate Details</div>
//           <ProtectedElement module={module} perm={perms.add}>
//             <NewSellerCertModal
//               refetch={refetch}
//               seller_id={data.id}
//               trigger={
//                 <div className="button" style={{ padding: "2px 10px" }}>
//                   Add new
//                 </div>
//               }
//             />
//           </ProtectedElement>
//         </div>
//       }
//       alwaysOpen
//       body={
//         <>
//           {certs === undefined ? (
//             <div style={{ height: "200px" }}>
//               <Loading />
//             </div>
//           ) : (
//             <div className="address-box-wrapper">
//               {certs && certs.length > 0
//                 ? certs.map((d, idx) => (
//                     <div className="address-box" key={idx}>
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "flex-end",
//                           gap: "5px",
//                         }}
//                       >
//                         <ProtectedElement module={module} perm={perms.edit}>
//                           <NewSellerCertModal
//                             refetch={refetch}
//                             seller_id={data.id}
//                             editData={d}
//                             trigger={
//                               <div className="i-btn">
//                                 <EditIcon width={10} height={10} />
//                               </div>
//                             }
//                           />
//                         </ProtectedElement>
//                         <ProtectedElement module={module} perm={perms.delete}>
//                           <AddressDeleteModal
//                             data={d}
//                             refetch={refetch}
//                             dropdownCloser={() => false}
//                             route={seller_docs}
//                           />
//                         </ProtectedElement>
//                       </div>
//                       <div>
//                         <b>Code : </b>
//                         {na(d.code)}
//                       </div>
//                       <div>
//                         <b>Document name : </b>
//                         {na(d.doc_name)}
//                       </div>
//                       <div>
//                         <b>Document number : </b>
//                         {na(d.doc_number)}
//                       </div>
//                       <div>
//                         <b>Document expiry : </b>
//                         {na2(
//                           d.doc_expiry,
//                           <>{moment(d.doc_expiry).format("DD-MM-YYYY")}</>
//                         )}
//                       </div>
//                       <div>
//                         <b>Document file : </b>
//                         {na2(
//                           d.doc_file,
//                           <a
//                             className="blue-link"
//                             href={d.doc_file}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             View File
//                           </a>
//                         )}
//                       </div>
//                       <div>
//                         <b>Verified : </b>
//                         {boolSmall(d.is_verified)}
//                       </div>
//                     </div>
//                   ))
//                 : "No data entered yet."}
//             </div>
//           )}
//         </>
//       }
//     />
//   );
// };

const Accordian = ({ head, body, footer, alwaysOpen = false }) => {
  const [open, setOpen] = useState(alwaysOpen);
  const height = 10000;

  return (
    <div className={"acc " + (open ? "open" : "")}>
      <div
        className="acc-head"
        onClick={() => (!alwaysOpen ? setOpen(!open) : null)}
      >
        <b style={{ flex: 1 }}>{head}</b>
        {!alwaysOpen && (
          <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
        )}
      </div>
      <div
        className={"acc-body " + (open ? "open" : "")}
        style={{ maxHeight: open ? height : 0 }}
      >
        {body}
        {footer}
      </div>
    </div>
  );
};

export default MoreInfoModal;
