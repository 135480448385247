import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./more_info_modal.scss";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { api } from "../../../api/api";
import { useEffect } from "react";
import unknownError from "../../../utils/unknownError";
import NewPartnerAddressModal from "./new_partner_address";
import AddressDeleteModal from "./delete_confirm_modal";
import Loading from "../../../components/Loading/Loading";
import { na } from "../../../utils/na";
import EditPartnerProfileModal from "./edit_partner_profile";
import { logistics_parter_hub_url } from "../../../api/urls";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const MoreInfoModal = ({ data, refetch }) => {
  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState();
  const [addressRefetcher, setAddressRefetcher] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(
          logistics_parter_hub_url + "?deliv_partner=" + data.id
        );
        setAddresses(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.id, open, addressRefetcher]);

  const addressRefetch = () => {
    setAddressRefetcher((v) => !v);
  };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Annexture
            data={data}
            addresses={addresses}
            addressRefetch={addressRefetch}
            refetch={refetch}
          />
        </div>
      </div>
    </Modal>
  );
};

const Annexture = ({ data, addresses, addressRefetch, refetch }) => {
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <HeadingSection data={data} refetch={refetch} />
      <ProfileDetailsSection data={data} refetch={refetch} />
      <AddressSection
        data={data}
        addresses={addresses}
        refetch={addressRefetch}
      />
    </div>
  );
};

const HeadingSection = ({ data: d, refetch }) => {
  return (
    <div className="heading-section">
      <div className="left-section">
        <h3>
          <b>Logistic Partner Details</b>
        </h3>
      </div>
      <div className="right-section"></div>
    </div>
  );
};

const ProfileDetailsSection = ({ data: d, cancel, is_return, refetch }) => {
  return (
    <div className="product-info">
      <div className="image-section">
        <DashboardImage
          src={d.company_img}
          height="250px"
          width="250px"
          circle
        />
      </div>
      <div className="details-section" style={{ position: "relative" }}>
        <ProtectedElement module={modules.LogisticPartner} perm={perms.edit}>
          <EditPartnerProfileModal
            refetch={refetch}
            seller_id={d.id}
            editData={d}
            trigger={
              <div
                className="i-btn"
                style={{ position: "absolute", right: "10px", top: "10px" }}
              >
                <EditIcon width={10} height={10} />
              </div>
            }
          />
        </ProtectedElement>
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Company name : </b>
              {na(d.company_name)}
            </div>
            <div>
              <b>Website : </b>
              {na(d.website)}
            </div>
            <div>
              <b>Transporter ID : </b>
              {na(d.trnsprtr_id)}
            </div>
            <div>
              <b>Account manager name : </b>
              {na(d.account_manager_name)}
            </div>
            <div>
              <b>Account manager mobile number : </b>
              {na(d.account_manager_mob_no)}
            </div>
            <div>
              <b>Account manager email id : </b>
              {na(d.account_manager_email_id)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Customer care name : </b>
              {na(d.customer_care_name)}
            </div>
            <div>
              <b>Customer care mobile number : </b>
              {na(d.customer_care_mobile_number)}
            </div>
            <div>
              <b>Customer care email id : </b>
              {na(d.customer_care_email_id)}
            </div>
            <div>
              <b>PAN number : </b>
              {na(d.PAN_number)}
            </div>
            <div>
              <b>TAN number : </b>
              {na(d.TAN_number)}
            </div>
            <div>
              <b>CIN number : </b>
              {na(d.CIN_number)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressSection = ({ data, addresses, refetch }) => {
  return (
    <Accordian
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
          }}
        >
          <div>Hubs</div>
          <ProtectedElement module={modules.LogisticPartner} perm={perms.add}>
            <NewPartnerAddressModal
              refetch={refetch}
              seller_id={data.id}
              trigger={
                <div className="button" style={{ padding: "2px 10px" }}>
                  Add new
                </div>
              }
            />
          </ProtectedElement>
        </div>
      }
      alwaysOpen
      body={
        <>
          {addresses === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {addresses && addresses.length > 0
                ? addresses.map((d, idx) => (
                    <div className="address-box" key={idx}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "5px",
                        }}
                      >
                        <ProtectedElement
                          module={modules.LogisticPartner}
                          perm={perms.edit}
                        >
                          <NewPartnerAddressModal
                            refetch={refetch}
                            seller_id={data.id}
                            editData={d}
                            trigger={
                              <div className="i-btn">
                                <EditIcon width={10} height={10} />
                              </div>
                            }
                          />
                        </ProtectedElement>
                        <ProtectedElement
                          module={modules.LogisticPartner}
                          perm={perms.delete}
                        >
                          <AddressDeleteModal
                            data={d}
                            refetch={refetch}
                            dropdownCloser={() => false}
                            route={logistics_parter_hub_url}
                          />
                        </ProtectedElement>
                      </div>
                      <div>
                        <b>Code : </b>
                        {na(d.code)}
                      </div>
                      <div>
                        <b>Name : </b>
                        {na(d.contact_name)}
                      </div>
                      <div>
                        <b>Number : </b>
                        {na(d.contact_number)}
                      </div>
                      <div>
                        <b>Email id : </b>
                        {na(d.contact_email_id)}
                      </div>
                      <div>
                        <b>Address line 1 : </b>
                        {na(d.address_line_1)}
                      </div>
                      <div>
                        <b>Address line 2 : </b>
                        {na(d.address_line_2)}
                      </div>
                      <div>
                        <b>Landmark : </b>
                        {na(d.landmark)}
                      </div>
                      <div>
                        <b>City : </b>
                        {na(d.city_village)}
                      </div>
                      <div>
                        <b>Pincode : </b>
                        {na(d.pincode)}
                      </div>
                      <div>
                        <b>State : </b>
                        {na(d.state)}
                      </div>
                      <div>
                        <b>Country : </b>
                        {na(d.country_name)}
                      </div>
                      <div>
                        <b>Facility GSTIN number : </b>
                        {na(d.facility_gstin_number)}
                      </div>
                      <div>
                        <b>Facility name : </b>
                        {na(d.facility_name)}
                      </div>
                    </div>
                  ))
                : "No data entered yet."}
            </div>
          )}
        </>
      }
    />
  );
};

const Accordian = ({ head, body, footer, alwaysOpen = false }) => {
  const [open, setOpen] = useState(alwaysOpen);
  const height = 10000;

  return (
    <div className={"acc " + (open ? "open" : "")}>
      <div
        className="acc-head"
        onClick={() => (!alwaysOpen ? setOpen(!open) : null)}
      >
        <b style={{ flex: 1 }}>{head}</b>
        {!alwaysOpen && (
          <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
        )}
      </div>
      <div
        className={"acc-body " + (open ? "open" : "")}
        style={{ maxHeight: open ? height : 0 }}
      >
        {body}
        {footer}
      </div>
    </div>
  );
};

export default MoreInfoModal;
