import "./timeline.scss";

const Timeline = ({ steps }) => {
  const array = steps;
  return (
    <div className="timeline-wrapper">
      <div className="timeline">
        {array.map((item, idx) => {
          return (
            <div
              className={"tl-item " + (item.complete ? "active" : "")}
              key={idx}
            >
              <div style={{ display: "flex", gap: "0px" }}>
                <div className="step-index"></div>
                {idx < array.length - 1 && (
                  <div className={"bar " + (item.complete ? "active" : "")} />
                )}
              </div>
              <div className="step-name">{item.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
