import { useEffect, useState } from "react";
import "./Keywords.scss";
import { generateUID } from "../../utils/generateUID";

export const keywordsToString = (keywords) => {
  if (!keywords) return "";
  if (keywords.length === 0) return "";
  return keywords.reduce((prev, cur) => prev + "," + cur);
};

export const stringToKeyword = (keywords) => {
  if (!keywords) return "";
  return keywords.split(",");
};

export const Keywords = ({
  required,
  label,
  placeholder,
  value,
  onChange,
  type,
  icon,
  error,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [v, setV] = useState("");
  const [tmp, setTmp] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setV(value);
    }
  }, [value, setV]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  return (
    <div className={"input-keywords " + (disabled ? "disabled" : "")}>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
      <label
        htmlFor={label}
        className={"input-wrapper " + (v.length === 0 ? "empty" : "")}
      >
        <span className="words">
          {v
            ? v.map((d, idx) => (
                <div
                  key={generateUID()}
                  className="word"
                  style={{ cursor: disabled ? "not-allowed" : undefined }}
                  onClick={() => {
                    if (!disabled) {
                      const newV = v.filter((_x, xdx) => idx !== xdx);
                      setV(newV);
                      onChange(newV);
                    }
                  }}
                >
                  {d}{" "}
                  <span style={{ fontSize: "9px", marginLeft: "3px" }}>
                    &#10005;
                  </span>
                </div>
              ))
            : null}
        </span>
        {v && v.length > 0 && <div className="p-5" />}
        <input
          id={label}
          type={type ?? "text"}
          placeholder={placeholder}
          required={required}
          value={tmp}
          style={{ width: "100%" }}
          disabled={disabled}
          autoComplete="on"
          onInvalid={(e) => {
            e.preventDefault();
            setErrorMessage(e.target.validationMessage);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (tmp === "") return false;
              if (v === "") {
                setV([tmp]);
                if (onChange) {
                  onChange([tmp]);
                }
                setTmp("");
              } else {
                setV([...v.filter((x) => x !== tmp), tmp]);
                if (onChange) {
                  onChange([...v.filter((x) => x !== tmp), tmp]);
                }
                setTmp("");
              }
              return false;
            }
          }}
          onBlur={() => {
            if (tmp === "") return false;
            if (v === "") {
              setV([tmp]);
              if (onChange) {
                onChange([tmp]);
              }
              setTmp("");
            } else {
              setV([...v.filter((x) => x !== tmp), tmp]);
              if (onChange) {
                onChange([...v.filter((x) => x !== tmp), tmp]);
              }
              setTmp("");
            }
            return false;
          }}
          onChange={(e) => {
            setTmp(e.target.value);
            if (!!errorMessage) {
              setErrorMessage(e.target.validationMessage);
            }
          }}
        />
      </label>
      <label className="real-label">{label}</label>
      {icon}
    </div>
  );
};
