import { modules, perms } from "../../api/codes";
import Ads from "../../pages/Advertisements/ads/ads";
import NewAds from "../../pages/Advertisements/ads/new_ads";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteAdvertisements = [
  {
    path: "/advertisements",
    content: (
      <ProtectedElement
        module={modules.Advertisement}
        perm={perms.viewList}
        page
      >
        <Ads />
      </ProtectedElement>
    ),
  },
  {
    path: "/advertisements/new",
    content: (
      <ProtectedElement module={modules.Advertisement} perm={perms.add} page>
        <NewAds />
      </ProtectedElement>
    ),
  },
  {
    path: "/advertisements/edit/:id",
    content: (
      <ProtectedElement module={modules.Advertisement} perm={perms.edit} page>
        <NewAds edit />
      </ProtectedElement>
    ),
  },
  {
    path: "/advertisements/view/:id",
    content: (
      <ProtectedElement module={modules.Advertisement} perm={perms.view} page>
        <NewAds view />
      </ProtectedElement>
    ),
  },
  {
    path: "/advertisements/copy/:id",
    content: (
      <ProtectedElement module={modules.Advertisement} perm={perms.add} page>
        <NewAds copy />
      </ProtectedElement>
    ),
  },
];
