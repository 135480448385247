import React from "react";
import { useLocation, Link } from "react-router-dom";
import { ChevronLeft } from "lucide-react";

const BackButton = ({ href }) => {
  const location = useLocation();
  let path = location.pathname;
  while (path.length > 0 && path.charAt(path.length - 1) !== "/") {
    path = path.slice(0, -1);
  }
  if (path.length > 0) {
    path = path.slice(0, -1);
  }
  return (
    <Link to={href ? href : path} className="back-button">
      <ChevronLeft className="back-button-icon" size={16} />
      {/* <span>Back</span> */}
    </Link>
  );
};

export default BackButton;
