import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputDate,
  InputImage,
  InputNumber,
  InputSelect,
  InputText,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import Stepper from "../../../components/Stepper/Stepper";
import { ReactComponent as LoadingIcon } from "../../../images/loading.svg";
import {
  country_url,
  pls_add_url,
  pls_bank_url,
  pls_docs_url,
  pls_url,
  state_url,
} from "../../../api/urls";
import { idToname } from "../../../utils/idToname";
import { nameToid } from "../../../utils/nameToid";
import unknownError from "../../../utils/unknownError";
import PLSDocDashboard from "./seller_doc_dashboard";
import PLSCertDashboard from "./seller_cert_dashboard";
import { moveUpToError } from "../../../utils/moveUpToError";

const initialState = {
  mobile_no: "",
  password: "",
  first_name: "",
  last_name: "",
  birth_date: "",
  profile_img: "",
  country: "",

  bank_name: "",
  account_holder_name: "",
  account_number: "",
  ifsc_code: "",
  upi_id_number: "",
  delivery_boy: "",

  contact_name: "",
  contact_number: "",
  contact_email_id: "",
  building_premises_number: "",
  building_premises_name: "",
  address_line_1: "",
  address_line_2: "",
  landmark: "",
  district: "",
  country_name: "",
  city_village: "",
  pincode: "",
  state: "",
  state_code: "",
  country_add: "",
  state_link: "",

  delivery_boy_id: "",
  add_id: "",
  bank_id: "",
  kyc_doc_id: "",
  cert_doc_id: "",
};

const title = "Delivery Workforce";
const backUrl = "/logistics/pls";
const apiUrl = pls_url;
const steps = [
  "Profile",
  "Address",
  "Bank Account",
  "Kyc Document",
  "Kyc Certificate",
];

const NewPLS = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(0);
  const [complete, setComplete] = useState(edit ? 100 : -1);
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const history = useHistory();
  const params = useParams();
  const toast = useToast();

  useEffect(() => {
    if ((edit || view) && params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/");
          setData(res.data);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    }
  }, [params.id, edit, view]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(country_url);
        setCountry(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(state_url);
        setState(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const get_id_by_step = (step) => {
    if (step === 0) return data.delivery_boy_id;
    if (step === 1) return data.add_id;
    if (step === 2) return data.bank_id;
    if (step === 3) return data.kyc_doc_id;
    if (step === 4) return data.cert_doc_id;
  };

  const set_id_by_step = (step, id) => {
    if (step === 0) {
      set("delivery_boy_id", id);
      set("delivery_boy", id);
    }
    if (step === 1) set("add_id", id);
    if (step === 2) set("bank_id", id);
    if (step === 3) set("kyc_doc_id", id);
    if (step === 4) set("cert_doc_id", id);
  };

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        setLoading(true);
        e.preventDefault();
        const api_url = get_api_by_step(step);
        const id = get_id_by_step(step);
        try {
          if (edit || step <= complete) {
            api
              .patch(api_url + id + "/", data_by_step(data, step), {
                headers: {
                  "Content-Type":
                    step < 1 || step > 2
                      ? "multipart/form-data"
                      : "application/json",
                },
              })
              .then((res) => {
                set_id_by_step(step, res.data.id);
                setComplete(step);
                if (step === steps.length - 1) {
                  toast.open("Created delivery boy successfully.", "success");
                  history.push(backUrl);
                }
                setStep((c) => (c + 1 < steps.length ? c + 1 : c));
                setLoading(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setLoading(false);
              });
          } else {
            api
              .post(api_url, data_by_step(data, step), {
                headers: {
                  "Content-Type":
                    step < 1 || step > 2
                      ? "multipart/form-data"
                      : "application/json",
                },
              })
              .then((res) => {
                set_id_by_step(step, res.data.id);
                setComplete(step);
                if (step === steps.length - 1) {
                  toast.open("Created successfully.", "success");
                  history.push(backUrl);
                }
                setStep((c) => (c + 1 < steps.length ? c + 1 : c));
                setLoading(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setLoading(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setLoading(false);
        }
      }}
    >
      <div id={step < 3 ? "twoside-form" : ""}>
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <Stepper
          step={step}
          setter={setStep}
          steps={steps}
          allowedTill={complete}
        />
        {step === 3 && (
          <PLSDocDashboard
            delivery_boy_id={data.delivery_boy_id}
            next={() => {
              setStep(step + 1);
              setComplete(step);
            }}
          />
        )}
        {step === 4 && (
          <PLSCertDashboard
            delivery_boy_id={data.delivery_boy_id}
            next={() => {
              toast.open("Created successfully.", "success");
              history.push(backUrl);
            }}
          />
        )}
        {step < 3 && (
          <div className="twoside-form">
            {step === 0 && (
              <div className="formside">
                <InputText
                  label="First name"
                  placeholder="First name"
                  disabled={view}
                  required
                  value={data.first_name}
                  onChange={(v) => set("first_name", v)}
                  error={errors["first_name"]}
                />
                <InputText
                  label="Last name"
                  placeholder="Last name"
                  disabled={view}
                  required
                  value={data.last_name}
                  onChange={(v) => set("last_name", v)}
                  error={errors["last_name"]}
                />
                <InputNumber
                  label="Mobile number"
                  placeholder="Mobile number"
                  disabled={view}
                  required
                  value={data.mobile_no}
                  onChange={(v) => set("mobile_no", v)}
                  error={errors["mobile_no"]}
                />
                <InputDate
                  label="Birth date"
                  placeholder="Birth date"
                  disabled={view}
                  required
                  value={data.birth_date}
                  onChange={(v) => set("birth_date", v)}
                  error={errors["birth_date"]}
                  noTime
                />
                <InputImage
                  label="Profile image"
                  placeholder="Profile image"
                  disabled={view}
                  required
                  value={data.profile_img}
                  onChange={(v) => set("profile_img", v)}
                  error={errors["profile_img"]}
                />
                <InputSelect
                  label="Country"
                  placeholder="Country"
                  required
                  disabled={view}
                  value={idToname(data.country, country)}
                  options={country ? country.map((d) => d.printable_name) : []}
                  onChange={(v) => set("country", nameToid(v, country))}
                  error={errors["country"]}
                />
              </div>
            )}
            {step === 1 && (
              <>
                <div className="formside">
                  <InputText
                    label="Contact name"
                    placeholder="Contact name"
                    required
                    disabled={view}
                    value={data.contact_name}
                    onChange={(v) => set("contact_name", v)}
                    error={errors["contact_name"]}
                  />
                  <InputNumber
                    label="Mobile number"
                    placeholder="Mobile number"
                    required
                    disabled={view}
                    value={data.contact_number}
                    onChange={(v) => set("contact_number", v)}
                    error={errors["contact_number"]}
                  />
                  <InputText
                    label="Email id"
                    placeholder="Email id"
                    type="email"
                    required
                    disabled={view}
                    value={data.contact_email_id}
                    onChange={(v) => set("contact_email_id", v)}
                    error={errors["contact_email_id"]}
                  />
                  <InputText
                    label="Address 1"
                    placeholder="Room no, floor, building name, etc."
                    required
                    disabled={view}
                    value={data.address_line_1}
                    onChange={(v) => set("address_line_1", v)}
                    error={errors["address_line_1"]}
                  />
                  <InputText
                    label="Address 2"
                    placeholder="Street name, area, district, etc."
                    required
                    disabled={view}
                    value={data.address_line_2}
                    onChange={(v) => set("address_line_2", v)}
                    error={errors["address_line_2"]}
                  />
                  <InputText
                    label="Landmark"
                    placeholder="Landmark"
                    required
                    disabled={view}
                    value={data.landmark}
                    onChange={(v) => set("landmark", v)}
                    error={errors["landmark"]}
                  />
                </div>
                <div className="formside">
                  <InputText
                    label="City"
                    placeholder="City"
                    required
                    disabled={view}
                    value={data.city_village}
                    onChange={(v) => set("city_village", v)}
                    error={errors["city_village"]}
                  />
                  <InputNumber
                    label="Pincode"
                    placeholder="Pincode"
                    required
                    disabled={view}
                    value={data.pincode}
                    onChange={(v) => set("pincode", v)}
                    error={errors["pincode"]}
                  />
                  <InputSelect
                    label="State"
                    placeholder="State"
                    required
                    disabled={view}
                    value={idToname(data.state_link, state)}
                    options={state ? state.map((d) => d.printable_name) : []}
                    onChange={(v) => set("state_link", nameToid(v, state))}
                    error={errors["state_link"]}
                  />
                  <InputSelect
                    label="Country"
                    placeholder="Country"
                    required
                    disabled={view}
                    value={idToname(data.add_country, country)}
                    options={
                      country ? country.map((d) => d.printable_name) : []
                    }
                    onChange={(v) => set("add_country", nameToid(v, country))}
                    error={errors["add_country"]}
                  />
                  <InputText
                    label="Building premises number"
                    placeholder="Building premises number"
                    disabled={view}
                    value={data.building_premises_number}
                    onChange={(v) => set("building_premises_number", v)}
                    error={errors["building_premises_number"]}
                  />
                  <InputText
                    label="Building premises name"
                    placeholder="Building premises name"
                    disabled={view}
                    value={data.building_premises_name}
                    onChange={(v) => set("building_premises_name", v)}
                    error={errors["building_premises_name"]}
                  />
                </div>
              </>
            )}
            {step === 2 && (
              <div className="formside">
                <InputText
                  label="Account holder name"
                  placeholder="Account holder name"
                  required
                  disabled={view}
                  value={data.account_holder_name}
                  onChange={(v) => set("account_holder_name", v)}
                  error={errors["account_holder_name"]}
                />
                <InputText
                  label="Bank name"
                  placeholder="Bank name"
                  required
                  disabled={view}
                  value={data.bank_name}
                  onChange={(v) => set("bank_name", v)}
                  error={errors["bank_name"]}
                />
                <InputNumber
                  label="Account number"
                  placeholder="Account number"
                  required
                  disabled={view}
                  value={data.account_number}
                  onChange={(v) => set("account_number", v)}
                  error={errors["account_number"]}
                />
                <InputText
                  label="IFSC code"
                  placeholder="IFSC code"
                  required
                  disabled={view}
                  value={data.ifsc_code}
                  onChange={(v) => set("ifsc_code", v)}
                  error={errors["ifsc_code"]}
                />
                <InputText
                  label="UPI id"
                  placeholder="UPI id"
                  required
                  disabled={view}
                  value={data.upi_id_number}
                  onChange={(v) => set("upi_id_number", v)}
                  error={errors["upi_id_number"]}
                />
              </div>
            )}
          </div>
        )}
        {!view && step < 3 && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              {steps.length - 1 === step ? (
                <button className="btn">Save</button>
              ) : (
                <button
                  className="btn"
                  disabled={loading}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                    width: "auto",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  {loading ? <LoadingIcon height={30} width={30} /> : null}
                  Save & Next
                </button>
              )}
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewPLS;

function data_by_step(data, step) {
  const newData = { ...data };
  if (step === 0) {
    return objToFormdata(newData);
  }
  if (step === 1) {
    newData.country = newData.country_add;
    return newData;
  }
  if (step === 2) {
    return {
      bank_name: data.back_name,
      account_holder_name: data.account_holder_name,
      account_number: data.account_number,
      ifsc_code: data.ifsc_code,
      upi_id_number: data.upi_id_number,
      delivery_boy: data.delivery_boy,
    };
  }

  return newData;
}

function get_api_by_step(step) {
  if (step === 0) return pls_url;
  if (step === 1) return pls_add_url;
  if (step === 2) return pls_bank_url;
  if (step === 3) return pls_docs_url;
  if (step === 4) return pls_docs_url;
}

function objToFormdata(data) {
  const form_data = new FormData();
  for (const key in data) {
    if (data[key] === "") continue;
    if (data[key] === undefined) continue;
    if (Object.prototype.toString.call(data[key]) === "[object Array]") {
      data[key].forEach((d) => {
        form_data.append(key, d);
      });
    } else {
      form_data.append(key, data[key]);
    }
  }
  return form_data;
}
