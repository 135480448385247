import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const Policy = () => {
  return (
    <CardsPage
      items={items.find((item) => item.title === "Policy").childrens}
    />
  );
};

export default Policy;
