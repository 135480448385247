import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./generate_bill.scss";
import "./more_info_modal.scss";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { order_list_url } from "../../../api/urls";

const ChangeOrderStatus = ({
  data,
  refetch,
  actionRoute,
  actionName,
  statusCode,
  options,
}) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const toast = useToast();

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title" style={{ width: "100px" }}>
          {actionName}
        </div>
      }
      className="modal-window small-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <h1>
            <b>Are you sure?</b>
          </h1>
        </div>
        <div className="delete-modal-actions">
          <button
            className="btn-red"
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </button>
          <button
            className="btn"
            disabled={saving}
            onClick={() => {
              setSaving(true);
              if (data) {
                try {
                  api
                    .patch(
                      `${order_list_url}${data.id}/${actionRoute}/`,
                      statusCode
                        ? {
                            status_code: statusCode,
                          }
                        : options
                        ? options
                        : {}
                    )
                    .then((_res) => {
                      if (refetch) {
                        refetch();
                        toast.open("Action performed successfully", "success");
                        setOpen(false);
                      }
                      setSaving(false);
                    })
                    .catch((err) => {
                      unknownError(err);
                      setOpen(false);
                      setSaving(false);
                    });
                } catch (err) {
                  unknownError(err);
                  setOpen(false);
                  setSaving(false);
                }
              }
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeOrderStatus;
