import Users from "../../pages/users/users";
import InternalUser from "../../pages/users/internal_users/internal_users";
import NewInternalUser from "../../pages/users/internal_users/new_internal_user";
import TagonCustomers from "../../pages/users/tagon_customers/tagon_customers";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";
import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import { NewB2B } from "../../pages/users/tagon_customers/new_b2b";

export const RouteUsers = [
  {
    path: "/users",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.UserCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <Users />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/internal_users",
    content: (
      <ProtectedElement
        module={modules.InternalUser}
        perm={perms.viewList}
        page
      >
        <InternalUser />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/internal_users/new",
    content: (
      <ProtectedElement module={modules.InternalUser} perm={perms.add} page>
        <NewInternalUser />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/internal_users/edit/:id",
    content: (
      <ProtectedElement module={modules.InternalUser} perm={perms.edit} page>
        <NewInternalUser edit />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/internal_users/view/:id",
    content: (
      <ProtectedElement module={modules.InternalUser} perm={perms.view} page>
        <NewInternalUser view />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/internal_users/copy/:id",
    content: (
      <ProtectedElement module={modules.InternalUser} perm={perms.add} page>
        <NewInternalUser edit />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/tagon_customers",
    content: (
      <ProtectedElement
        module={modules.TagonCustomer}
        perm={perms.viewList}
        page
      >
        <TagonCustomers />
      </ProtectedElement>
    ),
  },
  {
    path: "/users/tagon_customers/make_b2b/:id",
    content: (
      <ProtectedElement
        module={modules.B2BVerification}
        perm={perms.viewList}
        page
      >
        <NewB2B />
      </ProtectedElement>
    ),
  },
];
