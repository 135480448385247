export function TitleLine({ children, left, right, color = "black" }) {
  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      {left && (
        <div style={{ backgroundColor: color, height: "1px", flex: 1 }} />
      )}
      <div style={{ color: color, fontSize: "17px", fontWeight: "bold" }}>
        {children}
      </div>
      {right && (
        <div style={{ backgroundColor: color, height: "1px", flex: 1 }} />
      )}
    </div>
  );
}
