import { useSelector } from "react-redux";
import Unauthorized from "../../pages/unauthorized/unauthorized";

export function ProtectedElement({ children, module, perm, page }) {
  // if (page) return children;
  // return null;
  const permissions = useSelector((state) => state.user.permissions);
  if (!isAuthorized(permissions, module, perm)) {
    if (page) {
      // return children;
      return <Unauthorized />;
    }
    return (
      <div className="no-perm-wrapper">
        <div className="no-perm">{children}</div>
      </div>
    );
  }
  return children;
}

export function isAuthorized(permissions, module, perm) {
  // return true;

  let ok = false;

  const checkModule = (module, permission) => {
    if (module === permission?.code) {
      permission?.perms?.forEach((pm) => {
        if (pm === perm) {
          ok = true;
        }
        if (Array.isArray(perm)) {
          perm.forEach((p) => {
            if (p === pm) {
              ok = true;
            }
          });
        }
      });
    }
  };

  if (Array.isArray(permissions)) {
    permissions?.forEach((permission) => {
      if (Array.isArray(module)) {
        module.forEach((module) => {
          checkModule(module, permission);
        });
      } else {
        checkModule(module, permission);
      }
    });
  }

  // console.log(ok, module, perm);
  return ok;
}
