export function FormSteps({
  steps,
  currentStep,
  setCurrentStep,
  canGoToStep,
  submit,
}) {
  return (
    <div
      style={{
        border: "1px solid",
        borderColor: "#dedede",
        backgroundColor: "white",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid",
          borderColor: "#dedede",
          padding: "10px",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {steps.map((step, index) => (
          <div
            key={index}
            className={currentStep === index ? "color-blue" : ""}
            style={{
              color:
                currentStep === index
                  ? undefined
                  : canGoToStep(index)
                  ? undefined
                  : "#dedede",
              fontWeight: "500",
              // flex: 1,
              display: "flex",
              paddingX: "10px",
              alignItems: "center",
              justifyContent: "center",
              cursor: canGoToStep(index) ? "pointer" : "not-allowed",
            }}
            onClick={async () => {
              if (canGoToStep(index)) {
                if (submit) {
                  const valid = await submit();
                  if (valid) {
                    setCurrentStep(index);
                  }
                } else {
                  setCurrentStep(index);
                }
              }
            }}
          >
            {step.title}
          </div>
        ))}
      </div>
      <div key={currentStep} style={{ padding: "40px", overflow: "auto" }}>
        {steps[currentStep].body}
      </div>
    </div>
  );
}
