import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./generate_bill.scss";
import "./more_info_modal.scss";
import { na } from "../../../utils/na";
import Loading from "../../../components/Loading/Loading";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import { inventory_list_url, order_list_url } from "../../../api/urls";
import Button from "../../../components/Button/Button";
import { useToast } from "../../../components/Toast";
import { sellerAddressFormatter } from "../../../utils/addresssFormatter";
import { objToParams } from "../../../utils/objToParams";

const ChangePickAddModal = ({ data, actionPath, actionName, refetch }) => {
  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    const get = async () => {
      setSelected(data?.inv_location);
      try {
        const res = await api
          .get(
            inventory_list_url +
              "?" +
              objToParams({
                product: data.product,
                seller: data.seller_data,
                color: data.hexcolor,
                size: data.size_label,
                qty: data.quantity,
              })
          )
          .catch((e) => {
            unknownError(e);
          });
        setAddresses(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [open, data]);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title" style={{ width: "100px" }}>
          {actionName}
        </div>
      }
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Display
            data={addresses}
            modalCloser={() => setOpen(false)}
            actionPath={actionPath}
            closer={() => setOpen(false)}
            refetch={refetch}
            selected={selected}
            setSelected={setSelected}
            orderId={data.id}
            statusCode={data.status_code}
          />
        </div>
      </div>
    </Modal>
  );
};

const Display = ({
  data,
  modalCloser,
  actionPath,
  closer,
  refetch,
  selected,
  setSelected,
  orderId,
  statusCode,
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  if (!data) {
    return (
      <div
        style={{
          flex: 1,
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (data.length === 0) {
    return <div>No stock available</div>;
  }
  return (
    <>
      <div className="address-box-wrapper">
        {data.map((d, key) => {
          return (
            <div
              className={
                "address-box" + (selected === d?.id ? " border-blue" : "")
              }
              key={key}
              onClick={() => !loading && setSelected(d.id)}
              style={{ fontSize: "13px" }}
            >
              <div>
                <b>{d?.seller_address?.contact_name}</b>
              </div>
              {sellerAddressFormatter(d?.seller_address)}
              <div>
                <b>Facility GSTIN number : </b>
                {na(d?.seller_address?.facility_gstin_number)}
              </div>
              <div>
                <b>Facility name : </b>
                {na(d?.seller_address?.facility_name)}
              </div>
              <div>
                <b>Stock : </b>
                {na(d?.stock)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="p-10" />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => {
            setLoading(true);
            try {
              api
                .patch(`${order_list_url}${orderId}/${actionPath}/`, {
                  id: selected,
                })
                .then(() => {
                  setLoading(false);
                  toast.open("Pick address changed successfully", "success");
                  closer();
                  refetch();
                })
                .catch((err) => {
                  unknownError(err);
                  setLoading(false);
                });
            } catch (err) {
              unknownError(err);
            }
          }}
          isLoading={loading}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ChangePickAddModal;
