import ProductList from "../../pages/products/product_list/product_list";
import NewProduct from "../../pages/products/product_list/new_product";
import { modules, perms } from "../../api/codes";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteProducts = [
  {
    path: "/products",
    content: (
      <ProtectedElement module={modules.Product} perm={perms.viewList} page>
        <ProductList isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/products/new",
    content: (
      <ProtectedElement module={modules.Product} perm={perms.add} page>
        <NewProduct isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/products/edit/:id",
    content: (
      <ProtectedElement module={modules.Product} perm={perms.edit} page>
        <NewProduct edit isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/products/view/:id",
    content: (
      <ProtectedElement module={modules.Product} perm={perms.view} page>
        <NewProduct view isGlobal={false} />
      </ProtectedElement>
    ),
  },
  {
    path: "/products/copy/:id",
    content: (
      <ProtectedElement module={modules.Product} perm={perms.add} page>
        <NewProduct copy isGlobal={false} />
      </ProtectedElement>
    ),
  },
];
