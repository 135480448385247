import "./SectionCard.scss";
import { Link } from "react-router-dom";

const SectionCard = ({ Icon, title, href }) => {
  return (
    <Link to={href}>
      <div className="card">
        <div className="icon-wrapper">
          <Icon />
        </div>
        <div style={{ textTransform: "capitalize" }}>{title}</div>
      </div>
    </Link>
  );
};

export default SectionCard;
