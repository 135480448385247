import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import Policy from "../../pages/policy/policy";
import NewPolicyContent from "../../pages/policy/policy_content/new_policy_content";
import PolicyContent from "../../pages/policy/policy_content/policy_content";
import NewPolicyTitle from "../../pages/policy/policy_title/new_policy_title";
import PolicyTitle from "../../pages/policy/policy_title/policy_title";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RoutePolicy = [
  {
    path: "/policy",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.PolicyCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <Policy />
      </ProtectedElement>
    ),
  },
  ...makeRoute(
    "policy_title",
    PolicyTitle,
    NewPolicyTitle,
    modules.PolicyTitle
  ),
  ...makeRoute(
    "policy_content",
    PolicyContent,
    NewPolicyContent,
    modules.PolicyContent
  ),
];

function makeRoute(route, Dashboard, Form, module, readOnly) {
  if (readOnly) {
    return [
      {
        path: `/policy/${route}`,
        content: (
          <ProtectedElement module={module} perm={perms.viewList} page>
            <Dashboard isGlobal={false} />
          </ProtectedElement>
        ),
      },
    ];
  }
  return [
    {
      path: `/policy/${route}`,
      content: (
        <ProtectedElement module={module} perm={perms.viewList} page>
          <Dashboard isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/policy/${route}/new`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/policy/${route}/edit/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.edit} page>
          <Form edit isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/policy/${route}/view/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.view} page>
          <Form view isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/policy/${route}/copy/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} />
        </ProtectedElement>
      ),
    },
  ];
}
