import "./page_not_found.scss";

const PageNotFound = () => {
  return (
    <div className="page_not_found">
      <h1 className="heading">404</h1>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
