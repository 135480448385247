import {
  closeAreYouSure,
  openAreYouSure,
} from "../components/AreYouSure/ModalProvider";

export function areYouSure(ifYes, message = "Are you sure ?") {
  openAreYouSure(
    <div style={{ padding: "20px", width: "300px" }}>
      <div style={{ fontWeight: "bold" }}>{message}</div>
      <div className="p-10" />
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          gap: "10px",
          width: "min-content",
        }}
      >
        <div
          className="button btn-red"
          style={{
            padding: "3px 10px",
            width: "80px",
          }}
          onClick={() => {
            closeAreYouSure();
          }}
        >
          No
        </div>
        <div
          className="button"
          style={{ padding: "3px 10px", width: "80px" }}
          onClick={async () => {
            await ifYes();
            closeAreYouSure();
          }}
        >
          Yes
        </div>
      </div>
    </div>
  );
}
