import { Header, Icon, Input } from "semantic-ui-react";
import { modules, perms } from "../../api/codes";
import { useEffect, useState, useRef } from "react";
import { ProtectedElement } from "../../components/ProtectedElement/ProtectedElement";
import "./manage_tickets.scss";
import { DashboardImage } from "../../components/DashboardImage/DashboardImage";
import axios from "axios";
import { api } from "../../api/api";
import {
  department_url,
  internal_users_url,
  ticket_url,
  unassigned_ticket_url,
} from "../../api/urls";
import unknownError from "../../utils/unknownError";
import Pagination from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import { useFetch } from "../../utils/useFetch";
import { ChevronDown, Mail } from "lucide-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const currentRoute = "/manage_tickets";
const disabled = false;
const module = modules.ManageTickets;

export const statusEnums = [
  {
    id: "OPEN",
    printable_name: "Open",
  },
  {
    id: "CLOSE",
    printable_name: "Closed",
  },
  {
    id: "RESOLVED",
    printable_name: "Resolved",
  },
  {
    id: "WAITING FOR PARTNER RESPONSE",
    printable_name: "Waiting for partner response",
  },
  {
    id: "WAITING FOR CUSTOMER RESPONSE",
    printable_name: "Waiting for customer response",
  },
];

export const priorityEnums = [
  {
    id: "LOWEST",
    printable_name: "Lowest",
    color: "#78AEFF",
  },
  {
    id: "LOW",
    printable_name: "Low",
    color: "#78AEFF",
  },
  {
    id: "MEDIUM",
    printable_name: "Medium",
    color: "#FFD540",
  },
  {
    id: "HIGH",
    printable_name: "High",
    color: "#EA4335",
  },
  {
    id: "HIGHEST",
    printable_name: "Highest",
    color: "#EA4335",
  },
];

export const typeOfTicketEnums = [
  {
    id: "Order related",
    printable_name: "Order related",
    extra_fields: [
      {
        name: "Order id",
        key: "order_id",
        type: "text", // text, number, date
        required: true,
      },
    ],
    user_type: "tagon app", // tagon app, Seller app, Delivery app
  },
  {
    id: "Damaged product during return, replace",
    printable_name: "Damaged product during return, replace",
    extra_fields: [
      {
        name: "Order id",
        key: "order_id",
        type: "text", // text, number, date
        required: true,
      },
    ],
    user_type: "Seller app", // tagon app, Seller app, Delivery app
  },
  {
    id: "Request for new feature",
    printable_name: "Request for new feature",
    user_type: "Seller app",
  },
  {
    id: "Request for brand",
    printable_name: "Request for brand",
    extra_fields: [
      {
        name: "Brand name",
        key: "brand_name",
        type: "text",
        required: true,
      },
      {
        name: "Brand website link",
        key: "brand_website_link",
        type: "text",
      },
    ],
    user_type: "Seller app",
  },
  {
    id: "Request for category",
    printable_name: "Request for category",
    extra_fields: [
      {
        name: "First category",
        key: "first_category",
        type: "text",
        required: true,
      },
      {
        name: "Second category",
        key: "second_category",
        type: "text",
        required: true,
      },
      {
        name: "Third category",
        key: "third_category",
        type: "text",
        required: true,
      },
    ],
    user_type: "Seller app",
  },
  {
    id: "Didn’t Get Payment Settlement",
    printable_name: "Didn’t Get Payment Settlement",
    extra_fields: [
      {
        name: "Payment settlement date",
        key: "payment_settlement_date",
        type: "date",
        required: true,
      },
    ],
    user_type: "Seller app",
  },
  {
    id: "Delivery Issue",
    printable_name: "Delivery Issue",
    user_type: "Delivery app",
  },
];

function TabSelector({ active, setActive, options }) {
  return (
    <div className="tab-selector">
      {options.map((option, index) => (
        <div
          key={index}
          className={`tab-item ${active === index ? "active" : ""}`}
          onClick={() => setActive(index)}
        >
          {option.title}
        </div>
      ))}
    </div>
  );
}

export default function ManageTickets() {
  const title = "Manage Tickets";
  const module = modules.ManageTickets;
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const [refetcher, setRefetcher] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  const apiUrl = activeTab === 0 ? ticket_url : unassigned_ticket_url;

  const tabOptions = [
    { title: "Assigned Tickets" },
    { title: "Unassigned Tickets" },
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          apiUrl + "?limit=" + limit + "&offset=" + offset,
          {
            cancelToken: source.token,
          }
        );
        setData(res?.data);
        setCount(res?.data?.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, limit, offset, activeTab, apiUrl]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  const erpUsersData = useFetch(internal_users_url);
  const departmentData = useFetch(department_url);

  return (
    <>
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
          <ProtectedElement module={module} perm={perms.add}>
            <Link to={currentRoute + "/new"}>
              <button className="btn">Add New</button>
            </Link>
          </ProtectedElement>
        </div>
      </div>

      <TabSelector
        active={activeTab}
        setActive={setActiveTab}
        options={tabOptions}
      />

      {data ? (
        <div className="ticket-list">
          {data.results?.map((item) => (
            <TicketCard
              key={item.id}
              ticket={item}
              erpUsersData={erpUsersData}
              departmentData={departmentData}
              refetch={refetch}
              activeTab={activeTab}
            />
          ))}
        </div>
      ) : (
        <Loading />
      )}
      <Pagination
        count={count}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        setLimit={setLimit}
        pages={[10, 25, 50, 100]}
      />
    </>
  );
}

function TicketCard({
  ticket: d,
  erpUsersData,
  departmentData,
  refetch,
  activeTab,
}) {
  const getPriorityColor = (priority) => {
    return priorityEnums.find((p) => p.id === priority)?.color;
  };

  return (
    <div
      className="ticket-card"
      style={{ borderLeft: `3px solid ${getPriorityColor(d.priority)}` }}
    >
      <Link
        to={`${currentRoute}/view/${d.id}?tab=${activeTab}`}
        className="ticket-card-header ticket-card-header-in-table"
      >
        <DashboardImage height="40px" width="40px" circle src={d.image} />
        <div className="ticket-card-header-info">
          <div className="ticket-card-header-info-title">
            <Mail className="ticket-card-header-info-title-icon" />
            <div>{d.type_of_ticket}</div>
            <div className="ticket-card-header-info-title-id">#{d.id}</div>
          </div>
          <div className="ticket-card-header-info-description">
            <div>{d.full_name}</div>
            <div>•</div>
            <div>Created {moment(d.created_at).fromNow()}</div>
            <div>•</div>
            <div>
              Deadline {moment(d.deadline).fromNow()}
              <span
                className={`deadline-indicator ${
                  moment().isAfter(d.deadline) ? "overdue" : "active"
                }`}
              />
            </div>
          </div>
          {d.tags && (
            <div className="ticket-card-header-info-tags">
              {d.tags.split(",").map((tag, index) => (
                <span key={index} className="tag">
                  {tag.trim()}
                </span>
              ))}
            </div>
          )}
        </div>
      </Link>
      <div className="ticket-card-body">
        <div className="ticket-card-body-item">
          <div className="item-label">Raised by</div>
          <div className="item-value">{d.raised_full_name}</div>
        </div>
        <div className="ticket-card-body-item">
          <div className="item-label">Assigned to</div>
          <ProtectedElement module={module} perm={perms.ticketChangeAssignee}>
            <AssignedToDropdown
              ticketId={d.id}
              assignedUniqueId={d.assigned_uniqueid}
              erpUsersData={erpUsersData}
              refetch={refetch}
              apiUrl={ticket_url}
            />
          </ProtectedElement>
        </div>
        <div className="ticket-card-body-item">
          <div className="item-label">Priority</div>
          <PriorityDropdown
            ticketId={d.id}
            currentPriority={d.priority}
            priorityData={{
              data: priorityEnums,
            }}
            refetch={refetch}
            apiUrl={ticket_url}
          />
        </div>
        <div className="ticket-card-body-item">
          <div className="item-label">Status</div>
          <ProtectedElement module={module} perm={perms.ticketChangeStatus}>
            <StatusDropdown
              ticketId={d.id}
              currentStatus={d.status}
              statusData={{
                data: statusEnums,
              }}
              refetch={refetch}
              apiUrl={ticket_url}
            />
          </ProtectedElement>
        </div>
        <div className="ticket-card-body-item">
          <div className="item-label">Department</div>
          <DepartmentDropdown
            ticketId={d.id}
            currentDepartment={d.department}
            departmentData={departmentData}
            refetch={refetch}
            apiUrl={ticket_url}
          />
        </div>
        {/* <div className="ticket-card-body-item">
          <div className="item-label">Escalate</div>
          <div className="item-value">None</div>
        </div> */}
      </div>
    </div>
  );
}

function Dropdown({
  options,
  value,
  onChange,
  placeholder = "None",
  isLoading,
  isEditable,
  showColorSquare,
  color,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div
        className={`dropdown-header ${isEditable ? "editable" : ""}`}
        onClick={() => isEditable && setIsOpen(!isOpen)}
      >
        <span>
          {isLoading ? (
            "Loading..."
          ) : value ? (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {showColorSquare && (
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: color,
                    borderRadius: "2px",
                  }}
                />
              )}
              {value.label}
            </div>
          ) : (
            placeholder
          )}
        </span>
        {isEditable && <ChevronDown size={16} />}
      </div>
      {isOpen && !isLoading && isEditable && (
        <div className="dropdown-menu">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <ul>
            {filteredOptions.map((option) => (
              <li
                key={option.value}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {showColorSquare && (
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: priorityEnums.find(
                        (p) => p.id === option.value
                      )?.color,
                      borderRadius: "2px",
                      marginRight: "8px",
                      display: "inline-block",
                    }}
                  />
                )}
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

function AssignedToDropdown({
  ticketId,
  assignedUniqueId,
  erpUsersData,
  refetch,
  apiUrl,
}) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (erpUsersData?.data) {
      const assignedUser = erpUsersData?.data?.find(
        (item) => item.id.toString() === assignedUniqueId.toString()
      );
      setSelectedUser(assignedUser);
    }
  }, [erpUsersData?.data, assignedUniqueId]);

  const userOptions = erpUsersData?.data
    ? erpUsersData?.data?.map((user) => ({
        value: user.id,
        label: user.username,
      }))
    : [];

  const handleUserChange = async (option) => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await api.patch(`${apiUrl}${ticketId}/`, {
        assigned_uniqueid: option.value,
      });
      const user = erpUsersData?.data?.find((u) => u.id === option.value);
      setSelectedUser(user);
      refetch();
    } catch (err) {
      unknownError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dropdown
      options={userOptions}
      value={
        selectedUser
          ? { value: selectedUser.id, label: selectedUser.username }
          : null
      }
      onChange={handleUserChange}
      isLoading={isLoading}
      isEditable={!disabled}
    />
  );
}

function PriorityDropdown({
  ticketId,
  currentPriority,
  priorityData,
  refetch,
  apiUrl,
}) {
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedPriority(currentPriority);
  }, [currentPriority]);

  const priorityOptions = priorityData?.data
    ? priorityData?.data?.map((priority) => ({
        value: priority.id,
        label: priority.printable_name,
      }))
    : [];

  const handlePriorityChange = async (option) => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await api.patch(`${apiUrl}${ticketId}/`, {
        priority: option.value,
      });
      setSelectedPriority(option.value);
      refetch();
    } catch (err) {
      unknownError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dropdown
      options={priorityOptions}
      value={
        selectedPriority &&
        priorityData?.data?.find((p) => p.id === selectedPriority)
          ? {
              value: selectedPriority,
              label: priorityData?.data?.find((p) => p.id === selectedPriority)
                ?.printable_name,
            }
          : null
      }
      onChange={handlePriorityChange}
      isLoading={isLoading}
      isEditable={!disabled}
      showColorSquare={true}
      color={priorityData?.data?.find((p) => p.id === selectedPriority)?.color}
    />
  );
}

function StatusDropdown({
  ticketId,
  currentStatus,
  statusData,
  refetch,
  apiUrl,
}) {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedStatus(currentStatus);
  }, [currentStatus]);

  const statusOptions = statusData?.data
    ? statusData?.data?.map((status) => ({
        value: status.id,
        label: status.printable_name,
      }))
    : [];

  const handleStatusChange = async (option) => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await api.patch(`${apiUrl}${ticketId}/`, {
        status: option.value,
      });
      setSelectedStatus(option.value);
      refetch();
    } catch (err) {
      unknownError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dropdown
      options={statusOptions}
      value={
        selectedStatus && statusData?.data?.find((s) => s.id === selectedStatus)
          ? {
              value: selectedStatus,
              label: statusData?.data?.find((s) => s.id === selectedStatus)
                ?.printable_name,
            }
          : null
      }
      onChange={handleStatusChange}
      isLoading={isLoading}
      isEditable={!disabled}
    />
  );
}

function DepartmentDropdown({
  ticketId,
  currentDepartment,
  departmentData,
  refetch,
  disabled,
  apiUrl,
}) {
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedDepartment(currentDepartment);
  }, [currentDepartment]);

  const departmentOptions = departmentData?.data
    ? departmentData?.data?.map((department) => ({
        value: department.id,
        label: department.printable_name,
      }))
    : [];

  const handleDepartmentChange = async (option) => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await api.patch(`${apiUrl}${ticketId}/`, {
        department: option.value,
        //  assigned_uniqueid: null,
      });
      setSelectedDepartment(option.value);
      refetch();
    } catch (err) {
      unknownError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dropdown
      options={departmentOptions}
      value={
        selectedDepartment
          ? {
              value: selectedDepartment,
              label: departmentData?.data?.find(
                (d) => d.id === selectedDepartment
              ).printable_name,
            }
          : null
      }
      onChange={handleDepartmentChange}
      isLoading={isLoading}
      isEditable={!disabled}
    />
  );
}
