import { useEffect, useState } from "react";
import unknownError from "../../utils/unknownError";
import { category_chart_url } from "../../api/urls";
import { api } from "../../api/api";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import placeholder from "../../images/no-image.jpg";
import Tree from "react-d3-tree";
import BackButton from "../../components/BackButton/BackButton";
import { Header } from "semantic-ui-react";
import { modules } from "../../api/codes";

const module = modules.CategoryChart;

export function CategoryChart() {
  const nodeSize = { x: 300, y: 800 };

  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -100,
    y: -100,
  };
  const [data, setData] = useState();
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(category_chart_url, {
          cancelToken: source.token,
          headers: {
            module: module,
          },
        });
        const result = {
          name: "Categories",
          children: res.data.map((d) => ({
            name: d.first.printable_name,
            attributes: {
              image: d.first.cat02_url,
            },
            children: d.first.second.map((d) => ({
              name: d.second.printable_name,
              attributes: {
                image: d.second.cat02_url,
              },
              children: d.second.third.map((d) => ({
                name: d.printable_name,
                attributes: {
                  image: d.cat03_url,
                },
              })),
            })),
          })),
        };
        setData(result);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <BackButton />
      <Header>Category Chart</Header>
      {!data ? (
        <Loading />
      ) : (
        <div style={{ height: "100%" }}>
          <Tree
            data={data}
            nodeSize={nodeSize}
            // pathFunc="step"
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
            }
            zoom={0.2}
            collapsible={false}
            hasInteractiveNodes={false}
            pathClassFunc={(link, _) => {
              if (!link?.source?.parent) {
                return "tree-hidden-link";
              }
              return "";
            }}
            orientation="vertical"
          />
        </div>
      )}
    </>
  );
}

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}) => (
  <>
    <foreignObject {...foreignObjectProps}>
      {nodeDatum.name !== "Categories" && (
        <div
          style={{
            backgroundColor: "#d4d4d8",
            borderRadius: "5px",
            padding: "10px",
            width: "170px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {nodeDatum?.attributes?.image ? (
            <img
              width="150px"
              src={nodeDatum.attributes.image}
              style={{ borderRadius: "5px" }}
              alt="category_image"
            />
          ) : (
            <img
              width="150px"
              src={placeholder}
              alt="category_image"
              style={{ borderRadius: "5px" }}
            />
          )}
          <div style={{ fontSize: "15px" }}>{nodeDatum.name}</div>
        </div>
      )}
    </foreignObject>
  </>
);
