import { z } from "zod";

const stringRequired = z.string().min(1, "This field is required");

// const MAX_FILE_SIZE = 500000;
// const ACCEPTED_IMAGE_TYPES = [
//   "image/jpeg",
//   "image/jpg",
//   "image/png",
//   "image/webp",
// ];

const imageRequired = z
  .any()
  .refine((file) => !!file?.name, "This field is required.");
// .refine((file) => file?.size <= MAX_FILE_SIZE, `Max file size is 5MB.`)
// .refine(
//   (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
//   ".jpg, .jpeg, .png and .webp files are accepted."
// );

const fileRequired = z
  .any()
  .refine((file) => !!file?.name, "This field is required.");
// .refine((file) => file?.size <= MAX_FILE_SIZE, `Max file size is 5MB.`);

const dateRequired = z.coerce.date("This field is requied");

const numberRequired = z.number("This field is requied");

export const validators = {
  stringRequired,
  imageRequired,
  dateRequired,
  numberRequired,
  fileRequired,
};
