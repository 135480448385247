export const nameToid = (value, ids, param_key) => {
  const key = param_key ? param_key : "printable_name";
  if (!value) {
    return undefined;
  }
  if (!ids) {
    return "";
  }
  return ids.filter((d) => d[key] === value)[0]?.id;
};

export const nameToidMany = (values, ids, key) => {
  if (!values) {
    return undefined;
  }
  if (!ids) {
    return "";
  }
  return values.map((d) => nameToid(d, ids, key));
};
