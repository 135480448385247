import moment from "moment";
import "./track.scss";

const Track = ({ close, data }) => {
  return (
    <div className="track-wrapper-wrapper">
      <div className="track-wrapper">
        <div style={{ paddingTop: "20px" }} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          {data?.order_logs?.map((d, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <div className="circle-side">
                <div className="circle">
                  {idx !== data.order_logs.length - 1 && (
                    <div className="bar">&nbsp;</div>
                  )}
                </div>
              </div>
              <div className="text-side">
                <div className="title">{d.status}</div>
                <div className="des">Platform : {d.platform}</div>
                <div className="des">
                  on {moment(d.created_at).format("llll")}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Track;
