import { Header } from "semantic-ui-react";
import BackButton from "../../../components/BackButton/BackButton";
import Tabs from "../../../components/Tabs/Tabs";
import { useEffect, useState } from "react";
import SubTabs from "../../../components/SubTabs/SubTabs";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import {
  assign_batch_url,
  awb_list_url,
  batch_list_url,
  delivery_boy_list_url,
  order_list_url,
} from "../../../api/urls";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import { InputCheckBox } from "../../../components/Inputs/Inputs";
import moment from "moment";
import { TextChip } from "../../../components/TextChip/TextChip";
import { useToast } from "../../../components/Toast";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { ModalProvider, useModal } from "../../../components/Modaler";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
import { userAddressFormatter } from "../../../utils/addresssFormatter";
import { na, na2, namt } from "../../../utils/na";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";

const title = "Assigned Orders";
const module = modules.AssignedOrders;

const tabs = [
  {
    title: "Delivery batch",
    subtabs: [
      {
        title: "AWB List",
        component: AWBList,
      },
      {
        title: "Generated batches",
        component: BatchList,
      },
      {
        title: "Assigned batches",
        component: AssignedBatchList,
      },
    ],
  },
  {
    title: "Return/Replace batch",
    subtabs: [
      {
        title: "AWB List",
        component: () => <AWBList pickup />,
      },
      {
        title: "Generated batches",
        component: () => <BatchList pickup />,
      },
      {
        title: "Assigned batches",
        component: () => <AssignedBatchList pickup />,
      },
    ],
  },
  {
    title: "Reschedule batch",
    component: () => <RescheduleAWBList />,
  },
];

export function AssignedOrders() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  useEffect(() => {
    setActiveSubTab(0);
  }, [activeTab]);
  const Component = tabs?.[activeTab]?.subtabs
    ? tabs?.[activeTab]?.subtabs?.[activeSubTab]?.component || null
    : tabs?.[activeTab]?.component;
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "10px",
          flexShrink: 0,
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            overflow: "auto",
          }}
        >
          <Tabs options={tabs} active={activeTab} setActive={setActiveTab} />
          <div style={{ padding: "2px" }} />
          {tabs[activeTab].subtabs && (
            <SubTabs
              options={tabs[activeTab].subtabs}
              active={activeSubTab}
              setActive={setActiveSubTab}
            />
          )}
        </div>
      </div>
      <div style={{ padding: "5px" }} />
      {Component && <Component />}
    </>
  );
}

function AWBList({ pickup }) {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const modaler = useModal();
  const columns = [
    {
      headerName: "",
      field: "check",
      renderCell: (params) => (
        <ProtectedElement module={module} perm={perms.generateBatch}>
          <InputCheckBox
            size="small"
            value={
              selectedOrders.find((s) => s.id === params.row.id) ? true : false
            }
            onChange={(v) => {
              setSelectedOrders((prev) => {
                if (v) {
                  return [...prev, params.row];
                } else {
                  const newOrders = [
                    ...prev.filter((s) => s.id !== params.row.id),
                  ];
                  return newOrders;
                }
              });
            }}
          />
        </ProtectedElement>
      ),
    },
    {
      headerName: "AWB No.",
      field: "awb_no",
    },
    {
      headerName: "Created",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Pick-up address",
      field: "created_at",
      wrap: true,
      renderCell: (params) => params.row?.pickup_address?.address,
    },
    {
      headerName: "Delivery address",
      field: "created_at",
      wrap: true,
      renderCell: (params) => params.row?.shipping_address?.address,
    },
    {
      headerName: "Payment method",
      field: "payment_method",
    },
    {
      headerName: "Earning",
      field: "earning",
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => (
        <TextChip text={params.value} color={`#FF4500`} />
      ),
    },
    {
      headerName: "view",
      field: "shipping_label",
      renderCell: (params) => (
        <a target="_blank" href={params.value} rel="noreferrer">
          <div className="btns download-btn">
            <div className="purple-title">View AWB</div>
          </div>
        </a>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => (
        <ActionDropdown
          ViewButton={({ setOpen }) => (
            // <ProtectedElement module={module} perm={perms.edit}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
                modaler.opener(<OrderList awbNo={params.row.awb_no} />, true);
              }}
            >
              <div className="icon-btn yellow-icon">
                <EyeIcon />
              </div>
              <div className="yellow-title">View orders</div>
            </div>
            // </ProtectedElement>
          )}
        />
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(
    awb_list_url +
      `?filter=${JSON.stringify({
        dlvr_prtnr_data__code: "dlvboys",
        ...(pickup
          ? {
              pickup: true,
              is_cancelled: false,
              rescheduled: false,
              delivery_batch: null,
            }
          : {
              pickup: false,
              is_cancelled: false,
              rescheduled: false,
              delivery_batch: null,
            }),
      })}`
  );
  return (
    <>
      <TableComponent
        loading={data.loading}
        rows={data.data?.results}
        columns={columns}
      />
      {selectedOrders.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            Selected :{" "}
            <div
              className="color-blue"
              style={{
                display: "inline",
                fontWeigth: "500",
              }}
            >
              {selectedOrders.length}
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <button
              className="btn-out"
              type="button"
              onClick={() => {
                setSelectedOrders([]);
              }}
            >
              Clear
            </button>
            <GenerateBatch
              data={selectedOrders}
              refetch={data.refetch}
              onSuccess={() => setSelectedOrders([])}
            />
          </div>
        </div>
      )}
      {pagination}
    </>
  );
}

function GenerateBatch({ data, refetch, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return (
    <button
      className="btn"
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        try {
          await api.post(batch_list_url, data);
          refetch();
          toast.open("Batch created sucessfully", "success");
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            toast.open(e?.response?.data.data, "success");
          } else {
            unknownError();
          }
        }
        setLoading(false);
      }}
    >
      Generate batch
    </button>
  );
}

function BatchList({ pickup }) {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const columns = [
    {
      headerName: "",
      field: "check",
      renderCell: (params) => (
        <ProtectedElement module={module} perm={perms.assignBatch}>
          <InputCheckBox
            size="small"
            value={
              selectedOrders.find((s) => s.id === params.row.id) ? true : false
            }
            onChange={(v) => {
              setSelectedOrders((prev) => {
                if (v) {
                  return [...prev, params.row];
                } else {
                  const newOrders = [
                    ...prev.filter((s) => s.id !== params.row.id),
                  ];
                  return newOrders;
                }
              });
            }}
          />
        </ProtectedElement>
      ),
    },
    {
      headerName: "Batch Code",
      field: "batch_code",
      renderCell: (params) => params.value,
    },
    // {
    //   headerName: "Products Qty",
    //   field: "orders",
    //   renderCell: (params) => params.value?.length,
    // },
    {
      headerName: "Date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Time",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("h:mm a"),
    },
    {
      headerName: "Earning",
      field: "batch_earning",
    },
    {
      headerName: "view",
      field: "batchQRcode",
      renderCell: (params) => (
        <a target="_blank" href={params.value} rel="noreferrer">
          <div className="btns download-btn">
            <div className="purple-title">View QR</div>
          </div>
        </a>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => (
        <ActionDropdown
          EditButton={({ setOpen }) => (
            <ProtectedElement module={module} perm={perms.editBatch}>
              <div
                className="option"
                onClick={() => {
                  setOpen(false);
                  modaler.opener(
                    <EditBatch
                      batchId={params.row.id}
                      batchCode={params.row.batch_code}
                    />,
                    true
                  );
                }}
              >
                <div className="icon-btn green-icon">
                  <EditIcon />
                </div>
                <div className="green-title">Edit Batch</div>
              </div>
            </ProtectedElement>
          )}
        />
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(
    batch_list_url +
      `?filter=${JSON.stringify({
        delivery_agent: null,
        ...(pickup
          ? {
              is_rev: true,
            }
          : {
              is_rev: false,
            }),
      })}`
  );
  const modaler = useModal();
  return (
    <>
      <TableComponent
        loading={data.loading}
        rows={data.data?.results}
        columns={columns}
      />
      {selectedOrders.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            Selected :{" "}
            <div
              className="color-blue"
              style={{
                display: "inline",
                fontWeigth: "500",
              }}
            >
              {selectedOrders.length}
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <button
              className="btn-out"
              type="button"
              onClick={() => {
                setSelectedOrders([]);
              }}
            >
              Clear
            </button>
            <button
              className="btn"
              onClick={() => {
                modaler.opener(
                  <AssignBatch
                    data={selectedOrders}
                    refetch={data.refetch}
                    onSuccess={() => {
                      setSelectedOrders([]);
                      modaler.closer();
                    }}
                  />,
                  true
                );
              }}
            >
              Assign batch
            </button>
          </div>
        </div>
      )}
      {pagination}
    </>
  );
}

function AssignBatch({ data: batches, refetch, onSuccess }) {
  const apiUrl = delivery_boy_list_url;
  const title = "Delivery personnel list";
  const toast = useToast();
  const columns = [
    {
      headerName: "Image",
      field: "profile_img",
      flex: 1,
      renderCell: (params) => (
        <DashboardImage width="50px" src={params.value} />
      ),
    },
    {
      headerName: "Name",
      field: "first_name",
      flex: 1,
      renderCell: (params) =>
        `${params?.row?.first_name} ${params?.row?.last_name}`,
    },
    {
      headerName: "Mobile number",
      field: "mobile_no",
      flex: 1,
      renderCell: (params) => params?.row?.mobile_no,
    },
    {
      headerName: "Pincode",
      field: "form_data.mobile_number",
      flex: 1,
      renderCell: (params) => "Todo",
    },
    {
      headerName: "Status",
      field: "online",
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: "100px", margin: "auto" }}>
          {params.value ? (
            <TextChip text="Online" color="success" />
          ) : (
            <TextChip text="Offline" color="error" />
          )}
        </div>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => <AssignToDeliveryBoy data={params.row} />,
    },
  ];

  const AssignToDeliveryBoy = ({ data }) => {
    const [loading, setLoading] = useState(false);
    return (
      <button
        className="btn"
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          try {
            await api.post(assign_batch_url, {
              boy: data.id,
              batch: batches.map((b) => b.batch_code),
            });
            refetch();
            toast.open("Batch assined to delivery boy sucessfully", "success");
            if (onSuccess) {
              onSuccess();
            }
          } catch (e) {
            if (e?.response?.status === 400 && e?.response?.data?.data) {
              toast.open(e?.response?.data?.data, "error");
            } else {
              unknownError();
            }
          }
          setLoading(false);
        }}
      >
        Assign batch
      </button>
    );
  };

  const { data, pagination } = useFetchWithPagination(apiUrl);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "1000px",
        maxWidth: "100%",
        padding: "20px",
        height: "80vh",
        overflow: "auto",
        paddingTop: "0px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Header>{title}</Header>
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </div>
  );
}

function AssignedBatchList({ pickup }) {
  const modaler = useModal();
  const columns = [
    {
      headerName: "Batch Code",
      field: "batch_code",
      renderCell: (params) => params.value,
    },
    // {
    //   headerName: "Products Qty",
    //   field: "orders",
    //   renderCell: (params) => params.value?.length,
    // },
    {
      headerName: "Date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Time",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("h:mm a"),
    },
    {
      headerName: "Earning",
      field: "batch_earning",
    },
    {
      headerName: "view",
      field: "batchQRcode",
      renderCell: (params) => (
        <a target="_blank" href={params.value} rel="noreferrer">
          <div className="btns download-btn">
            <div className="purple-title">View QR</div>
          </div>
        </a>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => (
        <ActionDropdown
          EditButton={({ setOpen }) => (
            <ProtectedElement module={module} perm={perms.editBatch}>
              <div
                className="option"
                onClick={() => {
                  setOpen(false);
                  modaler.opener(
                    <ModalProvider>
                      <EditBatch
                        batchId={params.row.id}
                        batchCode={params.row.batch_code}
                      />
                    </ModalProvider>,
                    true
                  );
                }}
              >
                <div className="icon-btn green-icon">
                  <EditIcon />
                </div>
                <div className="green-title">Edit Batch</div>
              </div>
            </ProtectedElement>
          )}
        />
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(
    batch_list_url +
      `?filter=${JSON.stringify({
        delivery_agent__isnull: false,
        ...(pickup
          ? {
              is_rev: true,
            }
          : {
              is_rev: false,
            }),
      })}`
  );
  return (
    <>
      <TableComponent
        loading={data.loading}
        rows={data.data?.results}
        columns={columns}
      />
      {pagination}
    </>
  );
}

function EditBatch({ batchId, batchCode }) {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const modaler = useModal();
  const columns = [
    {
      headerName: "",
      field: "check",
      renderCell: (params) => (
        <InputCheckBox
          size="small"
          value={
            selectedOrders.find((s) => s.id === params.row.id) ? true : false
          }
          onChange={(v) => {
            setSelectedOrders((prev) => {
              if (v) {
                return [...prev, params.row];
              } else {
                const newOrders = [
                  ...prev.filter((s) => s.id !== params.row.id),
                ];
                return newOrders;
              }
            });
          }}
        />
      ),
    },
    {
      headerName: "AWB No.",
      field: "awb_no",
    },
    {
      headerName: "Created date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Pick-up address",
      field: "created_at",
      wrap: true,
      renderCell: (params) => params.row?.pickup_address?.address,
    },
    {
      headerName: "Delivery address",
      field: "created_at",
      wrap: true,
      renderCell: (params) => params.row?.shipping_address?.address,
    },
    {
      headerName: "Payment method",
      field: "payment_method",
    },
    {
      headerName: "Earning",
      field: "earning",
    },
    {
      headerName: "view",
      field: "shipping_label",
      renderCell: (params) => (
        <a target="_blank" href={params.value} rel="noreferrer">
          <div className="btns download-btn">
            <div className="purple-title">View AWB</div>
          </div>
        </a>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => (
        <TextChip text={params.value} color={`#FF4500`} />
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => (
        <ActionDropdown
          ViewButton={({ setOpen }) => (
            // <ProtectedElement module={module} perm={perms.edit}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
                modaler.opener(<OrderList awbNo={params.row.awb_no} />, true);
              }}
            >
              <div className="icon-btn yellow-icon">
                <EyeIcon />
              </div>
              <div className="yellow-title">View orders</div>
            </div>
            // </ProtectedElement>
          )}
        />
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(
    awb_list_url +
      `?filter=${JSON.stringify({
        delivery_batch: batchId,
      })}`
  );
  return (
    <div
      style={{
        padding: "20px",
        height: "100%",
        maxHeight: "90vh",
        width: "800px",
        maxWidth: "90vw",
        overflow: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Header>AWB List</Header>
        <Header>Batch code : {batchCode}</Header>
      </div>
      <div className="p-10" />
      <TableComponent
        loading={data.loading}
        rows={data.data?.results}
        columns={columns}
      />
      {selectedOrders.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            Selected :{" "}
            <div
              className="color-blue"
              style={{
                display: "inline",
                fontWeigth: "500",
              }}
            >
              {selectedOrders.length}
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <button
              className="btn-out"
              type="button"
              onClick={() => {
                setSelectedOrders([]);
              }}
            >
              Clear
            </button>
            <RemoveAWBSFromBatch
              data={selectedOrders}
              refetch={data.refetch}
              onSuccess={() => setSelectedOrders([])}
              batchId={batchId}
            />
          </div>
        </div>
      )}
      {pagination}
    </div>
  );
}

function RemoveAWBSFromBatch({ data, refetch, onSuccess, batchId }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return (
    <button
      className="btn"
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        try {
          await api.patch(batch_list_url + batchId + "/changebatch/", {
            scanned_order: data.map((d) => d.id),
          });
          refetch();
          toast.open("Batch edited sucessfully", "success");
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            toast.open(e?.response?.data.data, "success");
          } else {
            unknownError();
          }
        }
        setLoading(false);
      }}
    >
      Remove
    </button>
  );
}

function OrderList({ awbNo }) {
  const columns = [
    {
      headerName: "Order details",
      renderCell: ({ row: d }) => (
        <div className="flex-column wrap" style={{ textAlign: "left" }}>
          <div className="wrap">
            <b>Order id : </b>
            {na2(
              d.order_id,
              <span style={{ color: "#AD00FF" }}>{d.order_id}</span>
            )}
          </div>
          <div>
            <b>Order date : </b>
            {na2(d.created, moment(d.created).format("DD-MM-YYYY"))}
          </div>
          <div>
            <b>Time : </b>
            {na2(d.created, moment(d.created).format("hh:mm A"))}
          </div>
          <div>
            <b>Buyer name : </b>
            {na(d?.customer_name)}
          </div>
          <div>
            <b>Seller name : </b>
            {na(d?.prod_json?.seller_name)}
          </div>
          <div className="wrap">
            <b>Payment mode : </b>
            {na(d.payment_method)}
          </div>
          <div>
            <b>Business type : </b>
            {d.is_company ? "B2B" : "B2C"}
          </div>
        </div>
      ),
    },
    {
      headerName: "Product details",
      renderCell: ({ row: d }) => (
        <div className="flex-column" style={{ textAlign: "left" }}>
          <div className="wrap">
            <b>Product title : </b>
            {na(d?.prod_json?.title)}
          </div>
          <div className="wrap">
            <b>Product description : </b>
            {na(d?.prod_json?.short_description)}
          </div>
          <div>
            <b>Size : </b>
            {na(d.size)}
          </div>
          <div>
            <b>Color : </b>
            {na(d.color)}
          </div>
          <div>
            <b>Quantity : </b>
            {na(d.quantity)}
          </div>
          <div>
            <b>Selling price : </b>
            {namt(d.subtotal_after_tax_amt, data.currency_symbol)}
          </div>
          <div>
            <b>Paid amount : </b>
            {namt(d.total_amount_paid_amt, data.currency_symbol)}
          </div>
          <div>
            <b>Total order value : </b>
            {namt(d.total_amount_paid_amt, data.currency_symbol)}
          </div>
        </div>
      ),
    },
    {
      headerName: "Customer details",
      renderCell: ({ row: d }) => (
        <div className="flex-column" style={{ textAlign: "left" }}>
          {d?.shipping_address?.name && <div>{d.shipping_address.name}</div>}
          <div className="wrap">{userAddressFormatter(d.shipping_address)}</div>
          {d?.shipping_address?.primary_mobile && (
            <div>Mobile No : +{d?.shipping_address?.primary_mobile}</div>
          )}
          <TextChip text={d.action_name} color={"#" + d.icon_color_code} />
          {/* <div
            className="active-green no-wrap"
            style={{
              backgroundColor: "#" + d.icon_color_code,
              width: "100%",
              padding: "5px 20px",
              letterSpacing: "0.5px",
            }}
          >
            {na(d.action_name)}
          </div> */}
        </div>
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(
    order_list_url + `?filter=${JSON.stringify({ awb_no: awbNo })}`
  );
  return (
    <div
      style={{
        padding: "20px",
        height: "100%",
        maxHeight: "90vh",
        width: "800px",
        maxWidth: "90vw",
        overflow: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Header>Order List</Header>
        <Header>AWB No : {awbNo}</Header>
      </div>
      <div className="p-10" />
      <TableComponent
        loading={data.loading}
        rows={data.data?.results}
        columns={columns}
      />
      {pagination}
    </div>
  );
}

function RescheduleAWBList() {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const modaler = useModal();
  const columns = [
    {
      headerName: "",
      field: "check",
      renderCell: (params) => (
        <ProtectedElement module={module} perm={perms.moveToList}>
          <InputCheckBox
            size="small"
            value={
              selectedOrders.find((s) => s.id === params.row.id) ? true : false
            }
            onChange={(v) => {
              setSelectedOrders((prev) => {
                if (v) {
                  return [...prev, params.row];
                } else {
                  const newOrders = [
                    ...prev.filter((s) => s.id !== params.row.id),
                  ];
                  return newOrders;
                }
              });
            }}
          />
        </ProtectedElement>
      ),
    },
    {
      headerName: "AWB No.",
      field: "awb_no",
    },
    {
      headerName: "Created date",
      field: "created_at",
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Pick-up address",
      field: "created_at",
      wrap: true,
      renderCell: (params) => params.row?.pickup_address?.address,
    },
    {
      headerName: "Delivery address",
      field: "created_at",
      wrap: true,
      renderCell: (params) => params.row?.shipping_address?.address,
    },
    {
      headerName: "Payment method",
      field: "payment_method",
    },
    {
      headerName: "Earning",
      field: "earning",
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => (
        <TextChip text={params.value} color={`#FF4500`} />
      ),
    },
    {
      headerName: "view",
      field: "shipping_label",
      renderCell: (params) => (
        <a target="_blank" href={params.value} rel="noreferrer">
          <div className="btns download-btn">
            <div className="purple-title">View AWB</div>
          </div>
        </a>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => (
        <ActionDropdown
          ViewButton={({ setOpen }) => (
            <div
              className="option"
              onClick={() => {
                setOpen(false);
                modaler.opener(<OrderList awbNo={params.row.awb_no} />, true);
              }}
            >
              <div className="icon-btn yellow-icon">
                <EyeIcon />
              </div>
              <div className="yellow-title">View orders</div>
            </div>
          )}
        />
      ),
    },
  ];
  const { data, pagination } = useFetchWithPagination(
    awb_list_url +
      `?filter=${JSON.stringify({
        dlvr_prtnr_data__code: "dlvboys",
        rescheduled: true,
      })}`
  );
  return (
    <>
      <TableComponent
        loading={data.loading}
        rows={data.data?.results}
        columns={columns}
      />
      {selectedOrders.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            Selected :{" "}
            <div
              className="color-blue"
              style={{
                display: "inline",
                fontWeigth: "500",
              }}
            >
              {selectedOrders.length}
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <button
              className="btn-out"
              type="button"
              onClick={() => {
                setSelectedOrders([]);
              }}
            >
              Clear
            </button>
            <RemoveAWBSFromReschedule
              data={selectedOrders}
              refetch={data.refetch}
              onSuccess={() => setSelectedOrders([])}
            />
          </div>
        </div>
      )}
      {pagination}
    </>
  );
}

function RemoveAWBSFromReschedule({ data, refetch, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return (
    <button
      className="btn"
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        try {
          await api.post(awb_list_url, {
            awb: data.map((d) => d.id),
          });
          refetch();
          toast.open("Move AWBS from reschedule to AWB list", "success");
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            toast.open(e?.response?.data.data, "success");
          } else {
            unknownError();
          }
        }
        setLoading(false);
      }}
    >
      Move to List
    </button>
  );
}
