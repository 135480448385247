import { modules, perms } from "../../api/codes";
import Coupons from "../../pages/coupons/coupons";
import NewCoupons from "../../pages/coupons/new_coupons";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteCoupons = [
  {
    path: "/coupons",
    content: (
      <ProtectedElement module={modules.Coupon} perm={perms.viewList} page>
        <Coupons />
      </ProtectedElement>
    ),
  },
  {
    path: "/coupons/new",
    content: (
      <ProtectedElement module={modules.Coupon} perm={perms.add} page>
        <NewCoupons />
      </ProtectedElement>
    ),
  },
  {
    path: "/coupons/edit/:id",
    content: (
      <ProtectedElement module={modules.Coupon} perm={perms.edit} page>
        <NewCoupons edit />
      </ProtectedElement>
    ),
  },
  {
    path: "/coupons/view/:id",
    content: (
      <ProtectedElement module={modules.Coupon} perm={perms.view} page>
        <NewCoupons view />
      </ProtectedElement>
    ),
  },
  {
    path: "/coupons/copy/:id",
    content: (
      <ProtectedElement module={modules.Coupon} perm={perms.add} page>
        <NewCoupons copy />
      </ProtectedElement>
    ),
  },
];
