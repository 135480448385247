import CardsPage from "../../components/CardsPage/CardsPage";
import { items } from "../../data/routes";

const Masters = () => {
  return (
    <CardsPage
      items={items.find((item) => item.title === "Masters").childrens}
    />
  );
};

export default Masters;
