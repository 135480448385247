import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputCheckBox,
  InputSelect,
  InputSelectMultiple,
  InputText,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { country_url, tax_code_url, tax_percent_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { useFetch } from "../../../utils/useFetch";
import { idTonameMany } from "../../../utils/idToname";
import { nameToidMany } from "../../../utils/nameToid";
import Loading from "../../../components/Loading/Loading";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";

const initialState = {
  tax_percent_range_start: "",
  tax_percent_range_end: "",
  tax_percent_value: "",
  tax_name: "",
  tax_percent: "",
  tax_on_tax: false,
  service_tax_code: false,
  tax_code: "",
  country: "",
  tax_on_tax_percent: undefined,
};

const module = modules.HsnPercent;

const title = "HSN Percent";
const backUrl = "/masters/tax_percent";
const apiUrl = tax_percent_url;

const NewTaxPercent = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const { data: TaxPercents } = useFetch(tax_percent_url);
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };
      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputSelect
          label="Tax name"
          placeholder="Tax name"
          disabled={view}
          value={data.tax_name}
          options={["GST", "CESS", "VAT"]}
          error={errors["tax_name"]}
          required
          onChange={(v) => set("tax_name", v)}
        />
        <APIDropdown
          label="HSN code"
          placeholder="HSN code"
          required
          disabled={view}
          value={data.tax_code}
          url={tax_code_url}
          onChange={(v) => set("tax_code", v)}
          error={errors["tax_code"]}
          module={module}
        />
        <InputText
          label="Tax percent"
          placeholder="Tax percent"
          disabled={view}
          value={data.tax_percent}
          error={errors["tax_percent"]}
          required
          onChange={(v) => set("tax_percent", v)}
        />
        <APIDropdown
          label="Country"
          placeholder="Country"
          required
          disabled={view}
          value={data.country}
          url={country_url}
          onChange={(v) => set("country", v)}
          error={errors["country"]}
          module={module}
        />
        <InputCheckBox
          label="Tax on tax"
          disabled={view}
          value={data.tax_on_tax}
          onChange={(v) => set("tax_on_tax", v)}
        />
        {!data.tax_on_tax &&
          TaxPercents &&
          TaxPercents?.filter((c) => c.tax_on_tax)?.length > 0 && (
            <InputSelectMultiple
              label="Tax on tax percent"
              placeholder="Tax on tax percent"
              options={
                TaxPercents
                  ? TaxPercents.filter((c) => c.tax_on_tax).map(
                      (c) => c.tax_code_name
                    )
                  : []
              }
              value={idTonameMany(
                data.tax_on_tax_percent,
                TaxPercents?.filter((c) => c.tax_on_tax),
                "tax_code_name"
              )}
              onChange={(v) =>
                set(
                  "tax_on_tax_percent",
                  nameToidMany(
                    v,
                    TaxPercents?.filter((c) => c.tax_on_tax),
                    "tax_code_name"
                  )
                )
              }
              disabled={view}
              error={errors["tax_on_tax_percent"]}
            />
          )}
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewTaxPercent;
