import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import {
  InputImage,
  InputNumber,
  InputText,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import Modal from "../../../components/Modal/Modal";
import { useToast } from "../../../components/Toast";
import { logistics_parter_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { urlTofile } from "../../../utils/urlTofile";
import objToFormdata from "../../../utils/objToFormdata";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";

const initialState = {};

const EditPartnerProfileModal = ({ trigger, refetch, editData, seller_id }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();

  useEffect(() => {
    if (editData) {
      const asyncSet = async () => {
        const newData = { ...editData };
        newData.company_img = await urlTofile(newData.company_img);
        setData(newData);
      };
      asyncSet();
    }
  }, [editData]);

  useEffect(() => {
    if (open === false && !editData) {
      setData({ ...initialState });
      setErrors({});
    }
  }, [open, setData, setErrors, editData]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const [isSaving, setIsSaving] = useState(false);

  const view = false;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className="modal-window"
      overlayClassName="no-overlay"
    >
      <div className="modal-content">
        <div className="modal-heading">Logistic Partner Profile</div>
        <div className="modal-form">
          <form
            id="form-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              setIsSaving(true);
              const newData = { ...data };
              try {
                if (editData) {
                  api
                    .patch(
                      logistics_parter_url + data.id + "/",
                      objToFormdata(newData),
                      { headers: modules.LogisticPartner }
                    )
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Edited data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                } else {
                  api
                    .post(logistics_parter_url, objToFormdata(newData), {
                      headers: modules.LogisticPartner,
                    })
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Added data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                }
              } catch (err) {
                unknownError(err);
                setIsSaving(false);
              }
            }}
          >
            <div className="twoside-form">
              <div className="formside">
                <InputText
                  label="Code"
                  placeholder="Code"
                  disabled={view}
                  required
                  value={data.code}
                  onChange={(v) => set("code", v)}
                  error={errors["code"]}
                />
                <InputText
                  label="Printable name"
                  placeholder="Printable name"
                  disabled={view}
                  required
                  value={data.printable_name}
                  onChange={(v) => set("printable_name", v)}
                  error={errors["printable_name"]}
                />
                <InputText
                  label="Company name"
                  placeholder="Company name"
                  disabled={view}
                  required
                  value={data.company_name}
                  onChange={(v) => set("company_name", v)}
                  error={errors["company_name"]}
                />
                <InputText
                  label="Website"
                  placeholder="Website"
                  disabled={view}
                  value={data.website}
                  onChange={(v) => set("website", v)}
                  error={errors["website"]}
                />
                <InputImage
                  label="Company image"
                  placeholder="Company image"
                  disabled={view}
                  value={data.company_img}
                  onChange={(v) => set("company_img", v)}
                  error={errors["company_img"]}
                />
                <InputText
                  label="Account manager name"
                  placeholder="Account manager name"
                  disabled={view}
                  value={data.account_manager_name}
                  onChange={(v) => set("account_manager_name", v)}
                  error={errors["account_manager_name"]}
                />
                <InputNumber
                  label="Account manager mobile number"
                  placeholder="Account manager mobile number"
                  disabled={view}
                  value={data.account_manager_mob_no}
                  onChange={(v) => set("account_manager_mob_no", v)}
                  error={errors["account_manager_mob_no"]}
                />
                <InputText
                  label="Account manager email id"
                  placeholder="Account manager email id"
                  type="email"
                  disabled={view}
                  value={data.account_manager_email_id}
                  onChange={(v) => set("account_manager_email_id", v)}
                  error={errors["account_manager_email_id"]}
                />
              </div>
              <div className="formside">
                <InputText
                  label="Customer care name"
                  placeholder="Customer care name"
                  disabled={view}
                  value={data.customer_care_name}
                  onChange={(v) => set("customer_care_name", v)}
                  error={errors["customer_care_name"]}
                />
                <InputNumber
                  label="Customer care mobile number"
                  placeholder="Customer care mobile number"
                  disabled={view}
                  value={data.customer_care_mobile_number}
                  onChange={(v) => set("customer_care_mobile_number", v)}
                  error={errors["customer_care_mobile_number"]}
                />
                <InputText
                  label="Customer care email id"
                  placeholder="Customer care email id"
                  type="email"
                  disabled={view}
                  value={data.customer_care_email_id}
                  onChange={(v) => set("customer_care_email_id", v)}
                  error={errors["customer_care_email_id"]}
                />
                <InputText
                  label="PAN number"
                  placeholder="PAN number"
                  disabled={view}
                  value={data.PAN_number}
                  onChange={(v) => set("PAN_number", v)}
                  error={errors["PAN_number"]}
                />
                <InputText
                  label="TAN number"
                  placeholder="TAN number"
                  disabled={view}
                  value={data.TAN_number}
                  onChange={(v) => set("TAN_number", v)}
                  error={errors["TAN_number"]}
                />
                <InputText
                  label="CIN number"
                  placeholder="CIN number"
                  disabled={view}
                  value={data.CIN_number}
                  onChange={(v) => set("CIN_number", v)}
                  error={errors["CIN_number"]}
                />
                <InputText
                  label="Transporter ID (for E-way bill)"
                  placeholder="Transporter ID (for E-way bill)"
                  disabled={view}
                  required
                  value={data.trnsprtr_id}
                  onChange={(v) => set("trnsprtr_id", v)}
                  error={errors["trnsprtr_id"]}
                />
              </div>
            </div>
            <InputToggle
              label="Active"
              disabled={view}
              value={data.is_active}
              error={errors["is_active"]}
              onChange={(v) => set("is_active", v)}
            />
            <div style={{ padding: "10px" }} />
          </form>
        </div>
        <div className="modal-actions">
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="form-wrapper"
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditPartnerProfileModal;
