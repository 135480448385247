import { useState } from "react";

export function useSteps(steps, { freeMoving = false }) {
  const [step, setStep] = useState(0);
  const [complete, setComplete] = useState(-1);
  return {
    step,
    setStep: (paramStep) => {
      if ((paramStep <= complete + 1 || freeMoving) && paramStep < steps) {
        setStep(paramStep);
      }
    },
    canGoToStep: (paramStep) => {
      return (paramStep <= complete || freeMoving) + 1 && paramStep < steps;
    },
    next: () => {
      if ((step <= complete + 1 || freeMoving) && step + 1 < steps) {
        const currentStep = step;
        setComplete(currentStep);
        setStep(currentStep + 1);
      }
    },
    prev: () => {
      if (step - 1 >= 0) {
        setStep(step - 1);
      }
    },
    complete,
    setComplete,
  };
}
