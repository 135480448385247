import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { InputText } from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { country_url, seller_registration_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";

const initialState = {
  country: "",
  mobile_number: "",
  account_id: "",
  email_id: "",
  password: "",
  confirm_password: "",
};

const module = modules.AnnualTurnover;

const title = "Register Seller";
const backUrl = "/sellers/sellers";
const apiUrl = seller_registration_url;

const NewSeller = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        if (newData.password !== newData.confirm_password) {
          toast.open("Password and Confirm Password do not match", "error");
          setIsSaving(false);
          return;
        }
        newData.mobile_number = `${newData.country}${newData.mobile_number}`;
        newData.code = newData.mobile_number;
        newData.printable_name = newData.mobile_number;
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", newData, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data", "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response?.data?.data) {
                  toast.open(err.response.data.data, "error");
                } else if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, newData, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data", "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response?.data?.data) {
                  toast.open(err.response.data.data, "error");
                } else if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <APIDropdown
          label="Country"
          placeholder="Country"
          required
          disabled={view}
          value={data.country}
          url={country_url}
          parameterForSet="country_mobile_code"
          onChange={(v) => set("country", v)}
          error={errors["country"]}
        />
        <InputText
          label="Mobile number"
          placeholder="Mobile number"
          disabled={view}
          value={data.mobile_number}
          error={errors["mobile_number"]}
          required
          onChange={(v) => set("mobile_number", v)}
        />
        {/* <InputText
          label="Account ID"
          placeholder="Account ID"
          disabled={view}
          value={data.account_id}
          error={errors["account_id"]}
          required
          onChange={(v) => set("account_id", v)}
        /> */}
        <InputText
          type="email"
          label="Email ID"
          placeholder="Email ID"
          disabled={view}
          value={data.email_id}
          error={errors["email_id"]}
          required
          onChange={(v) => set("email_id", v)}
        />
        <InputText
          type="password"
          label="Password"
          placeholder="Password"
          disabled={view}
          value={data.password}
          error={errors["password"]}
          required
          onChange={(v) => set("password", v)}
        />
        <InputText
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          disabled={view}
          value={data.confirm_password}
          error={errors["confirm_password"]}
          required
          onChange={(v) => set("confirm_password", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewSeller;
