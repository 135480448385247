import moment from "moment";
import { seller_url } from "../../../api/urls";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { TextChip } from "../../../components/TextChip/TextChip";
import { Link } from "react-router-dom";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ReactComponent as UserIcon } from "../../../images/user.svg";

export function SellerProfileList() {
  const apiUrl = seller_url;
  const title = "Profile KYC";
  const module = modules.ProfileKYC;
  const currentRoute = "profile_kyc";
  const columns = [
    {
      headerName: "Image",
      renderCell: (params) => (
        <DashboardImage
          width="50px"
          height="50px"
          src={params?.row?.company_image}
          circle
        />
      ),
    },
    {
      headerName: "Name",
      renderCell: (params) =>
        params.row.firstname
          ? `${params?.row?.firstname} ${params?.row?.lastname}`
          : "-",
    },
    {
      headerName: "Mobile number",
      field: "mobile_number",
    },
    {
      headerName: "Upload date",
      field: "created_at",
      flex: 1,
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Verified ?",
      field: "seller_request_status",
      renderCell: (params) => <TextChip text={params.value} color="#FF6F31" />,
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <ProtectedElement module={module} perm={perms.add}>
                <Link to={currentRoute + `/verify/${params.row.id}`}>
                  <div
                    className="option"
                    onClick={() => {
                      closer();
                    }}
                  >
                    <div className="icon-btn purple-icon">
                      <UserIcon />
                    </div>
                    <div className="purple-title">Verify profile</div>
                  </div>
                </Link>
              </ProtectedElement>
            );
          }}
        />
      ),
    },
  ];

  const { data, pagination } = useFetchWithPagination(apiUrl);
  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}
