import { modules, perms } from "../../api/codes";
import { items } from "../../data/routes";
import { AssignedOrders } from "../../pages/logisctics/assigned_orders/assigned_orders";
import PLSChoice from "../../pages/logisctics/delivered_to/delivered_to";
import NewPLSChoice from "../../pages/logisctics/delivered_to/new_delivered_to";
import PLSFaq from "../../pages/logisctics/faq/faq";
import NewPLSFaq from "../../pages/logisctics/faq/new_faq";
import Logistics from "../../pages/logisctics/logisctics";
import { NewPendingRegistration } from "../../pages/logisctics/pending_registrations/new_pending_registrations";
import { PendingRegistrationDashboard } from "../../pages/logisctics/pending_registrations/pending_registrations";
import { VerifyDeliveryBoy } from "../../pages/logisctics/pending_registrations/verify_user";
import NewPLS from "../../pages/logisctics/pls/new_pls";
import PLS from "../../pages/logisctics/pls/pls";
import { PLSUpdateDocuments } from "../../pages/logisctics/pls/update_documents";
import NewPLSPrivacy from "../../pages/logisctics/privacy/new_privacy";
import PLSPrivacy from "../../pages/logisctics/privacy/privacy";
import NewPLSTerms from "../../pages/logisctics/terms/new_terms";
import PLSTerms from "../../pages/logisctics/terms/terms";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteLogistics = [
  {
    path: "/logistics",
    content: (
      <ProtectedElement
        module={items
          .find((item) => item.module === modules.DeliveryCards)
          .childrens.map((ch) => ch.module)}
        perm={perms.viewList}
        page
      >
        <Logistics />
      </ProtectedElement>
    ),
  },
  {
    path: "/logistics/pending_registrations",
    content: (
      <ProtectedElement
        module={modules.PendingRegistrations}
        perm={perms.viewList}
        page
      >
        <PendingRegistrationDashboard />
      </ProtectedElement>
    ),
  },
  {
    path: "/logistics/pending_registrations/new",
    content: (
      <ProtectedElement
        module={modules.PendingRegistrations}
        perm={perms.add}
        page
      >
        <NewPendingRegistration />
      </ProtectedElement>
    ),
  },
  {
    path: "/logistics/pending_registrations/edit/:id",
    content: (
      <ProtectedElement
        module={modules.PendingRegistrations}
        perm={perms.edit}
        page
      >
        <NewPendingRegistration edit />
      </ProtectedElement>
    ),
  },
  {
    path: "/logistics/pending_registrations/view/:id",
    content: (
      <ProtectedElement
        module={modules.PendingRegistrations}
        perm={perms.view}
        page
      >
        <NewPendingRegistration view />
      </ProtectedElement>
    ),
  },
  {
    path: "/logistics/pending_registrations/verify/:id",
    content: (
      <ProtectedElement
        module={modules.PendingRegistrations}
        perm={perms.verifyProfileDeliveryBoy}
        page
      >
        <VerifyDeliveryBoy />
      </ProtectedElement>
    ),
  },
  {
    path: "/logistics/assigned_orders",
    content: (
      <ProtectedElement
        module={modules.AssignedOrders}
        perm={perms.viewList}
        page
      >
        <AssignedOrders />
      </ProtectedElement>
    ),
  },
  ...makeRoute("pls", PLS, NewPLS, modules.RegisteredUsers),
  ...makeRoute(
    "pls_privacy",
    PLSPrivacy,
    NewPLSPrivacy,
    modules.DeliveryPrivacy
  ),
  {
    path: "/logistics/pls/update_docs/:id",
    content: (
      <ProtectedElement module={modules.RegisteredUsers} perm={perms.add} page>
        <PLSUpdateDocuments />
      </ProtectedElement>
    ),
  },
  ...makeRoute("pls_faq", PLSFaq, NewPLSFaq, modules.DeliveryFaq),
  ...makeRoute("delivered_to", PLSChoice, NewPLSChoice, modules.DeliveryChoice),
  ...makeRoute("pls_terms", PLSTerms, NewPLSTerms, modules.DeliveryTerms),
];

function makeRoute(route, Dashboard, Form, module, readOnly) {
  if (readOnly) {
    return [
      {
        path: `/logistics/${route}`,
        content: (
          <ProtectedElement module={module} perm={perms.viewList} page>
            <Dashboard isGlobal={false} />
          </ProtectedElement>
        ),
      },
    ];
  }
  return [
    {
      path: `/logistics/${route}`,
      content: (
        <ProtectedElement module={module} perm={perms.viewList} page>
          <Dashboard isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/logistics/${route}/new`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/logistics/${route}/edit/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.edit} page>
          <Form edit isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/logistics/${route}/view/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.view} page>
          <Form view isGlobal={false} />
        </ProtectedElement>
      ),
    },
    {
      path: `/logistics/${route}/copy/:id`,
      content: (
        <ProtectedElement module={module} perm={perms.add} page>
          <Form isGlobal={false} />
        </ProtectedElement>
      ),
    },
  ];
}
