import React, { useEffect, useMemo, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import { Redirect, useLocation } from "react-router-dom";
import "./login.scss";

import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as LoadingIcon } from "../../images/loading.svg";
// import des from "../../images/login.png";
import { login } from "../../redux/userStore";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputText } from "../../components/Inputs/Inputs";
import { apiPublic } from "../../api/api";
import { user_login_url } from "../../api/urls";
import unknownError from "../../utils/unknownError";
import { items } from "../../data/routes";
import { isAuthorized } from "../../components/ProtectedElement/ProtectedElement";
import { perms } from "../../api/codes";
import { Eye, EyeOff } from "lucide-react";

const Login = () => {
  const history = useHistory();
  const [username, setusername] = useState("");
  const [userError, setUserError] = useState("");
  const [password, setpassword] = useState("");
  const [passError, setPassError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const permissions = useSelector((state) => state.user.permissions);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const backUrl =
    searchParams.get("back_url") || getFirstAuthenticatedModule(permissions);

  function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      apiPublic
        .post(user_login_url, {
          username: username,
          password: password,
        })
        .then((res) => {
          setLoading(false);
          dispatch(
            login({
              accessToken: res.data.auth.access,
              refreshToken: res.data.auth.refresh,
              username: username,
              pwd_temp: res.data.user.pwd_temp,
              permissions: res?.data?.user?.permissions,
            })
          );
          history.push(backUrl);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          setUserError("Wrong username");
          setPassError("Wrong password");
        });
    } catch (err) {
      unknownError(err);
    }
  }

  useEffect(() => {
    setUserError("");
  }, [username]);

  useEffect(() => {
    setPassError("");
  }, [password]);

  const access = useSelector((state) => state.user.accessToken);
  const pwd_temp = useSelector((state) => state.user.pwd_temp);
  if (access && pwd_temp === false) {
    return <Redirect to={backUrl} relative={false} replace />;
  }

  return (
    <div className="login-page">
      <div id="segment-dimension">
        {/* <Segment id="segment-dimension2" basic={true}>
          <img id="women-with-laptop" alt="des" src={des} />
          <h2>
            <b>Welcome To TagOn</b>
          </h2>
        </Segment> */}

        <Segment id="segment-dimension3" basic={true}>
          <form id="form-dimension" onSubmit={handleSubmit}>
            <div id="segment-dimension4">
              <Logo
                className="logo"
                color="var(--blue)"
                height={60}
                width={130}
              />
            </div>
            <InputText
              label="Username"
              placeholder="Enter your username"
              value={username}
              onChange={(v) => setusername(v)}
              error={userError}
              required
            />
            <div style={{ padding: "10px" }} />
            <InputText
              label="Password"
              placeholder="Enter your password"
              value={password}
              type={passwordType}
              onChange={(v) => setpassword(v)}
              required
              error={passError}
              icon={
                <div
                  onClick={() => {
                    setPasswordType(
                      passwordType === "password" ? "text" : "password"
                    );
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                  }}
                >
                  {passwordType === "password" ? (
                    <EyeOff size={20} />
                  ) : (
                    <Eye size={20} />
                  )}
                </div>
              }
            />
            <div style={{ padding: "5px" }} />
            <Button
              secondary
              type="submit"
              id="button-global"
              fluid
              disabled={loading}
            >
              {loading ? (
                <LoadingIcon
                  className="submit-loading"
                  height={50}
                  width={50}
                />
              ) : (
                ""
              )}
              Sign In
            </Button>
          </form>
        </Segment>
      </div>
    </div>
  );
};

function getFirstAuthenticatedModule(permissions) {
  let path = "";
  items.forEach((item) => {
    if (!path && isAuthorized(permissions, item?.module, perms.viewList)) {
      path = item.path;
    }
  });
  return path || "/masters";
}

export default Login;
