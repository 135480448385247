import { useEffect, useState } from "react";
import { useFetch } from "./useFetch";
import Pagination from "../components/Pagination/Pagination";

export function useFetchWithPagination(url, options = { perPage: 20 }) {
  const { perPage } = options;
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(perPage);
  const [offset, setOffset] = useState(0);
  const data = useFetch(
    url + (url.includes("?") ? "&" : "?") + `limit=${limit}&offset=${offset}`
  );

  useEffect(() => {
    if (data.data?.count) {
      setCount(data.data.count);
    }
  }, [data.data?.count]);

  if (!count) {
    return {
      data: data,
      pagination: null,
    };
  }
  return {
    data: data,
    pagination: (
      <Pagination
        count={count}
        setLimit={setLimit}
        setOffset={setOffset}
        pages={[perPage, perPage * 2, perPage * 3, perPage * 4]}
      />
    ),
  };
}
