import moment from "moment";
import { delivery_boy_temp_url } from "../../../api/urls";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { TextChip } from "../../../components/TextChip/TextChip";
import { Link } from "react-router-dom";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { ReactComponent as EyeIcon } from "../../../images/eye.svg";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import DeleteConfirmModal from "../../../components/ActionDropdown/DeleteConfirmModal";

export function PendingRegistrationDashboard() {
  const apiUrl = delivery_boy_temp_url + "?status=Pending";
  const title = "Pending registration";
  const module = modules.PendingRegistrations;
  const currentRoute = "pending_registrations";
  const columns = [
    {
      headerName: "Image",
      field: "form_data.photo",
      flex: 1,
      renderCell: (params) => (
        <DashboardImage
          width="70px"
          height="70px"
          src={params?.row?.form_data?.photo}
          circle
        />
      ),
    },
    {
      headerName: "Name",
      field: "form_data.first_name",
      flex: 1,
      renderCell: (params) =>
        `${params?.row?.form_data?.first_name} ${params?.row?.form_data?.last_name}`,
    },
    {
      headerName: "Applied date",
      field: "created_at",
      flex: 1,
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Mobile number",
      field: "form_data.mobile_number",
      flex: 1,
      renderCell: (params) => params?.row?.form_data?.mobile_number,
    },
    {
      headerName: "Verified ?",
      field: "form_status",
      flex: 1,
      renderCell: (params) => <TextChip text={params.value} color="#FF6F31" />,
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          DeleteButton={({ setOpen }) => (
            <DeleteConfirmModal
              data={params.row}
              dropdownCloser={() => setOpen(false)}
              route={apiUrl}
              setTableData={() => data.refetch()}
              module={module}
            />
          )}
          EditButton={({ setOpen }) => (
            <ProtectedElement module={module} perm={perms.edit}>
              <Link to={currentRoute + "/edit/" + params.row.id}>
                <div
                  className="option"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <div className="icon-btn green-icon">
                    <EditIcon />
                  </div>
                  <div className="green-title">Edit</div>
                </div>
              </Link>
            </ProtectedElement>
          )}
          ViewButton={({ setOpen }) => (
            <ProtectedElement module={module} perm={perms.view}>
              <Link to={currentRoute + "/view/" + params.row.id}>
                <div
                  className="option"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <div className="icon-btn yellow-icon">
                    <EyeIcon />
                  </div>
                  <div className="yellow-title">View</div>
                </div>
              </Link>
            </ProtectedElement>
          )}
          options={(closer) => {
            return (
              <ProtectedElement
                module={module}
                perm={perms.verifyProfileDeliveryBoy}
              >
                <Link to={currentRoute + `/verify/${params.row.id}`}>
                  <div
                    className="option"
                    onClick={() => {
                      closer();
                    }}
                  >
                    <div className="icon-btn purple-icon">
                      <UserIcon />
                    </div>
                    <div className="purple-title">Verify profile</div>
                  </div>
                </Link>
              </ProtectedElement>
            );
          }}
        />
        // <div className="blue-link">
        //   <Link to={currentRoute + `/verify/${params.row.id}`}>
        //     Verify profile
        //   </Link>
        // </div>
      ),
    },
  ];

  const { data, pagination } = useFetchWithPagination(apiUrl);
  const [search, setSearch] = useState("");
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
          <ProtectedElement module={module} perm={perms.add}>
            <Link to={currentRoute + "/new"}>
              <button className="btn">Add New</button>
            </Link>
          </ProtectedElement>
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}
