import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputDate,
  InputImage,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../components/Inputs/Inputs";
import BackButton from "../../components/BackButton/BackButton";
import { api } from "../../api/api";
import { useToast } from "../../components/Toast";
import { horoscope_title_url } from "../../api/urls";
import unknownError from "../../utils/unknownError";
import { urlTofile } from "../../utils/urlTofile";
import objToFormdata from "../../utils/objToFormdata";
import { moveUpToError } from "../../utils/moveUpToError";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  horoscope_name: "",
  horoscope_image: "",
  start_date: "",
  end_date: "",
};

const title = "Horoscope";
const backUrl = "/horoscope";
const apiUrl = horoscope_title_url;

const NewHoroscopeTitle = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();

  useEffect(() => {
    if ((edit || view) && params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/");
          res.data.horoscope_image = await urlTofile(res.data.horoscope_image);
          setData(res.data);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", objToFormdata(data))
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
              });
          } else {
            api
              .post(apiUrl, objToFormdata(data))
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
              });
          }
        } catch (err) {
          unknownError(err);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          required
          onChange={(v) => set("description", v)}
        />
        <InputText
          label="Horoscope name"
          placeholder="Horoscope name"
          disabled={view}
          value={data.horoscope_name}
          error={errors["horoscope_name"]}
          required
          onChange={(v) => set("horoscope_name", v)}
        />
        <InputImage
          label="Horoscope image"
          placeholder="Horoscope image"
          disabled={view}
          value={data.horoscope_image}
          error={errors["horoscope_image"]}
          required
          onChange={(v) => set("horoscope_image", v)}
        />
        <InputDate
          label="Start date"
          placeholder="Start date"
          disabled={view}
          value={data.start_date}
          error={errors["start_date"]}
          required
          onChange={(v) => set("start_date", v)}
        />
        <InputDate
          label="End date"
          placeholder="End date"
          disabled={view}
          value={data.end_date}
          error={errors["end_date"]}
          required
          onChange={(v) => set("end_date", v)}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button className="btn" onClick={() => moveUpToError()}>
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewHoroscopeTitle;
