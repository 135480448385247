import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Main from "./Main";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { setup as apiSetup } from "./api/api";
import { ToastProvider } from "./components/Toast";
import { ModalProvider } from "./components/Modaler";
import { AreYouSureProvider } from "./components/AreYouSure";

function App() {
  useEffect(() => {
    const resize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");
    };
    resize();
    window.addEventListener("resize", resize);
    window.addEventListener("orientationchange", resize);
    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("orientationchange", resize);
    };
  }, []);
  return (
    <Provider store={store}>
      <ToastProvider>
        <ModalProvider>
          <AreYouSureProvider>
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </AreYouSureProvider>
        </ModalProvider>
      </ToastProvider>
    </Provider>
  );
}

apiSetup(store);

export default App;
