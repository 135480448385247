import { useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../images/delete.svg";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import Modal from "../../../components/Modal/Modal";
import { useToast } from "../../../components/Toast";
import { modules } from "../../../api/codes";

const DeleteConfirmModal = ({
  data,
  setTableData,
  dropdownCloser,
  route,
  refetch,
  appentToUrl,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toast = useToast();
  return (
    <Modal
      open={modalOpen}
      onOpen={() => {
        setModalOpen(true);
      }}
      trigger={
        <div className="i-btn btn-red">
          <DeleteIcon width={10} height={10} />
        </div>
      }
      className="modal-window small-modal"
    >
      <div className="modal-content">
        <div className="modal-form">
          <h1>
            <b>Are you sure?</b>
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <button
            className="btn-red"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            No
          </button>
          <button
            className="btn"
            onClick={() => {
              if (data) {
                try {
                  api
                    .delete(
                      route + data.id + "/" + (appentToUrl ? appentToUrl : ""),
                      { headers: { module: modules.LogisticPartner } }
                    )
                    .then((_res) => {
                      if (setTableData) {
                        setTableData((v) => v.filter((d) => d.id !== data.id));
                        toast.open(
                          "Deleted data with id: " + data.id,
                          "success"
                        );
                      }
                      if (refetch) {
                        refetch();
                        toast.open(
                          "Deleted data with id: " + data.id,
                          "success"
                        );
                      }
                    })
                    .catch((err) => unknownError(err));
                } catch (err) {
                  unknownError(err);
                }
              }
              setModalOpen(false);
              dropdownCloser(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmModal;
