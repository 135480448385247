import { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../api/api";
import unknownError from "./unknownError";
import { createToast } from "../components/Toast/ToastProvider";

export function useFetch(url, module, run, method = "get", showError) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [refetcher, setRefetcher] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (
      url &&
      url !== undefined &&
      url !== "undefined" &&
      (run === undefined || run === true)
    ) {
      setLoading(true);
      setData(undefined);
      try {
        api[method](url, {
          cancelToken: source.token,
          headers: { module: module },
        })
          .then((res) => {
            setLoading(false);
            res.data && setData(res.data);
          })
          .catch((e) => {
            setLoading(false);
            if (e?.response?.status === 400 && e?.response?.data) {
              createToast(JSON.stringify(e?.response?.data), "error");
            } else {
              unknownError(e);
            }
          });
      } catch (e) {
        setLoading(false);
        if (e?.response?.status === 400 && e?.response?.data) {
          createToast(JSON.stringify(e?.response?.data), "error");
        } else {
          unknownError(e);
        }
      }
    }
    return () => {
      source.cancel();
    };
  }, [url, refetcher, module, run, method]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return { data, loading, refetch };
}
