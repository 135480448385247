import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputCheckBox,
  InputImage,
  InputNumber,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { continent_url, country_url } from "../../../api/urls";
import { urlTofile } from "../../../utils/urlTofile";
import unknownError from "../../../utils/unknownError";
import objToFormdata from "../../../utils/objToFormdata";
import Loading from "../../../components/Loading/Loading";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  name: "",
  flag: "",
  rate_of_change_product: "",
  rate_of_change_service: "",
  currency_name: "",
  currency_code: "",
  currency_symbol: "",
  timezone_name: "",
  timezone: "",
  alpha_two_code: "",
  alpha_three_code: "",
  country_mobile_code: "",
  numeric_code: "",
  country_regex: "",
  referal_code_text: "",
  wallet_active: false,
  upi_active: false,
  point_to_cash_coversion: "",
  reference_amount_to_new_user: "",
  reference_amount_to_existing_user: "",
  point_to_user: false,
  to_existing_user: false,
  reference_point_to_new_user: "",
  reference_point_to_existing_user: "",
  amount_to_new_user: "",
  bank_structure: "",
  continent: "",
};

const module = modules.Country;

const title = "Country";
const backUrl = "/masters/country";
const apiUrl = country_url;

const NewCountry = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          res.data.flag = await urlTofile(res.data.flag);
          res.data.bank_structure = JSON.stringify(res.data.bank_structure);
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", objToFormdata(data), {
                headers: {
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, objToFormdata(data), {
                headers: {
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="twoside-form">
        <div className="formside">
          <InputText
            label="Code"
            placeholder="Code"
            disabled={view}
            value={data.code}
            error={errors["code"]}
            required
            onChange={(v) => set("code", v)}
          />
          <InputText
            label="Printable name"
            placeholder="Printable name"
            disabled={view}
            value={data.printable_name}
            error={errors["printable_name"]}
            required
            onChange={(v) => set("printable_name", v)}
          />
          <InputTextBox
            label="Description"
            placeholder="Description"
            disabled={view}
            value={data.description}
            error={errors["description"]}
            required
            onChange={(v) => set("description", v)}
          />
          <InputText
            label="Country name"
            placeholder="Country name"
            disabled={view}
            value={data.name}
            error={errors["name"]}
            required
            onChange={(v) => set("name", v)}
          />
          <InputNumber
            label="Rate of change product"
            placeholder="Rate of change product"
            disabled={view}
            value={data.rate_of_change_product}
            error={errors["rate_of_change_product"]}
            required
            onChange={(v) => set("rate_of_change_product", v)}
          />
          <InputNumber
            label="Rate of change service"
            placeholder="Rate of change service"
            disabled={view}
            value={data.rate_of_change_service}
            error={errors["rate_of_change_service"]}
            required
            onChange={(v) => set("rate_of_change_service", v)}
          />
          <InputText
            label="Currency name"
            placeholder="Currency name"
            disabled={view}
            value={data.currency_name}
            error={errors["currency_name"]}
            required
            onChange={(v) => set("currency_name", v)}
          />
          <InputText
            label="Currency code"
            placeholder="Currency code"
            disabled={view}
            value={data.currency_code}
            error={errors["currency_code"]}
            required
            onChange={(v) => set("currency_code", v)}
          />
          <InputText
            label="Currency symbol"
            placeholder="Currency symbol"
            disabled={view}
            value={data.currency_symbol}
            error={errors["currency_symbol"]}
            required
            onChange={(v) => set("currency_symbol", v)}
          />
          <InputText
            label="Time zone name"
            placeholder="Time zone name"
            disabled={view}
            value={data.timezone_name}
            error={errors["timezone_name"]}
            required
            onChange={(v) => set("timezone_name", v)}
          />
          <InputText
            label="Time zone"
            placeholder="Time zone"
            disabled={view}
            value={data.timezone}
            error={errors["timezone"]}
            required
            onChange={(v) => set("timezone", v)}
          />
          <InputText
            label="Country mobile code"
            placeholder="Country mobile code"
            disabled={view}
            value={data.country_mobile_code}
            error={errors["country_mobile_code"]}
            required
            onChange={(v) => set("country_mobile_code", v)}
          />
          <InputText
            label="Country regex"
            placeholder="Country regex"
            disabled={view}
            value={data.country_regex}
            error={errors["country_regex"]}
            required
            onChange={(v) => set("country_regex", v)}
          />
        </div>
        <div className="formside">
          <InputText
            label="Numeric code"
            placeholder="Numeric code"
            disabled={view}
            value={data.numeric_code}
            error={errors["numeric_code"]}
            required
            onChange={(v) => set("numeric_code", v)}
          />
          <InputText
            label="Bank structure"
            placeholder="Bank structure"
            disabled={view}
            value={data.bank_structure}
            error={errors["bank_structure"]}
            required
            onChange={(v) => set("bank_structure", v)}
          />
          <InputImage
            label="Flage image"
            placeholder="Flage image"
            disabled={view}
            value={data.flag}
            error={errors["flag"]}
            required
            onChange={(v) => set("flag", v)}
          />
          <div className="two-inputs">
            <InputText
              label="Alpha two code"
              placeholder="Alpha two code"
              disabled={view}
              value={data.alpha_two_code}
              error={errors["alpha_two_code"]}
              required
              onChange={(v) => set("alpha_two_code", v)}
            />
            <InputText
              label="Alpha three code"
              placeholder="Alpha three code"
              disabled={view}
              value={data.alpha_three_code}
              error={errors["alpha_three_code"]}
              required
              onChange={(v) => set("alpha_three_code", v)}
            />
          </div>
          <InputText
            label="Referral code text"
            placeholder="Referral code text"
            disabled={view}
            value={data.referal_code_text}
            error={errors["referal_code_text"]}
            required
            onChange={(v) => set("referal_code_text", v)}
          />
          <InputNumber
            label="Point to cash conversion"
            placeholder="Point to cash conversion"
            disabled={view}
            value={data.point_to_cash_coversion}
            error={errors["point_to_cash_conversion"]}
            required
            onChange={(v) => set("point_to_cash_conversion", v)}
          />
          <InputNumber
            label="Reference amount to new user"
            placeholder="Reference amount to new user"
            disabled={view}
            value={data.reference_amount_to_new_user}
            error={errors["reference_amount_to_new_user"]}
            required
            onChange={(v) => set("reference_amount_to_new_user", v)}
          />
          <InputNumber
            label="Reference amount to existing user"
            placeholder="Reference amount to existing user"
            disabled={view}
            value={data.reference_amount_to_existing_user}
            error={errors["reference_amount_to_existing_user"]}
            required
            onChange={(v) => set("reference_amount_to_existing_user", v)}
          />
          <InputNumber
            label="Reference point to new user"
            placeholder="Reference point to new user"
            disabled={view}
            value={data.reference_point_to_new_user}
            error={errors["reference_point_to_new_user"]}
            required
            onChange={(v) => set("reference_point_to_new_user", v)}
          />
          <InputNumber
            label="Reference point to existing user"
            placeholder="Reference point to existing user"
            disabled={view}
            value={data.reference_point_to_existing_user}
            error={errors["reference_point_to_existing_user"]}
            required
            onChange={(v) => set("reference_point_to_existing_user", v)}
          />
          <InputNumber
            label="Amount to new user"
            placeholder="Amount to new user"
            disabled={view}
            value={data.amount_to_new_user}
            error={errors["amount_to_new_user"]}
            required
            onChange={(v) => set("amount_to_new_user", v)}
          />
          <APIDropdown
            label="Continents"
            placeholder="Continents"
            required
            disabled={view}
            value={data.continent}
            url={continent_url}
            onChange={(v) => set("continent", v)}
            error={errors["continent"]}
            module={module}
          />
          <div className="two-inputs">
            <InputCheckBox
              label="Wallet active"
              value={data.wallet_active}
              disabled={view}
              onChange={(v) => set("wallet_active", v)}
              error={errors["wallet_active"]}
            />
            <InputCheckBox
              label="Point to user"
              value={data.point_to_user}
              disabled={view}
              onChange={(v) => set("point_to_user", v)}
              error={errors["point_to_user"]}
            />
          </div>
          <div className="two-inputs">
            <InputCheckBox
              label="UPI active"
              value={data.upi_active}
              disabled={view}
              onChange={(v) => set("upi_active", v)}
              error={errors["upi_active"]}
            />
            <InputCheckBox
              label="To existing user"
              value={data.to_existing_user}
              disabled={view}
              onChange={(v) => set("to_existing_user", v)}
              error={errors["to_existing_user"]}
            />
          </div>
        </div>
      </div>
      <InputToggle
        label="Active"
        disabled={view}
        value={data.is_active}
        error={errors["is_active"]}
        onChange={(v) => set("is_active", v)}
      />
      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                history.push(backUrl);
              }}
            >
              Cancel
            </button>
            <button
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </>
      )}
      <div style={{ padding: "10px" }} />
    </form>
  );
};

export default NewCountry;
