import { useEffect, useState } from "react";
import { api } from "../../api/api";
import {
  InputDate,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../components/Inputs/Inputs";
import Modal from "../../components/Modal/Modal";
import { useToast } from "../../components/Toast";
import unknownError from "../../utils/unknownError";
import { horoscope_des_url } from "../../api/urls";
import { generateUID } from "../../utils/generateUID";
import { TableComponent } from "../../components/Table/table";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import "./more_info_modal.scss";
import { moveUpToError } from "../../utils/moveUpToError";

const apiUrl = horoscope_des_url;

const NewHoroscopeDescription = ({
  trigger,
  refetch,
  editData,
  horoscopeTitle,
  horoscopeCode,
  horoscopeInterval,
}) => {
  const initialState = {
    code: "",
    description: "",
    printable_name: "",
    disable: false,
    is_active: false,
    send_notify: false,
    horoscope_date: "",
    horoscope_entry: "",
    horoscope_json: [
      {
        id: generateUID(),
      },
    ],
    horoscope: "",
  };

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (editData) {
      const newData = { ...editData };
      if (!Array.isArray(newData.horoscope_json)) {
        newData.horoscope_json = initialState.horoscope_json;
      }
      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  useEffect(() => {
    if (open === false && !editData) {
      setData({ ...initialState });
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setData, setErrors, editData]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const view = false;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className="modal-window"
      overlayClassName="no-overlay"
    >
      <div className="modal-content">
        <div className="modal-heading">Horoscope description</div>
        <div className="modal-form">
          <form
            id="form-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              setIsSaving(true);
              setErrors({});
              const newData = { ...data };
              newData.horoscope = horoscopeTitle;
              newData.horoscope_entry = horoscopeInterval;
              try {
                if (editData) {
                  api
                    .patch(
                      apiUrl + data.id + `/?sign=${horoscopeCode}`,
                      newData
                    )
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Edited data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        if (!err.response.data.code) {
                          setErrors({
                            horoscope_date:
                              "Data for this date already exists.",
                          });
                        } else {
                          setErrors(err.response.data);
                          moveUpToError();
                        }
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                } else {
                  api
                    .post(apiUrl, newData)
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Added data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        if (!err.response.data.code) {
                          setErrors({
                            horoscope_date:
                              "Data for this date already exists.",
                          });
                        } else {
                          setErrors(err.response.data);
                          moveUpToError();
                        }
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                }
              } catch (err) {
                unknownError(err);
                setIsSaving(false);
              }
            }}
          >
            <div id="form">
              <InputText
                label="Code"
                placeholder="Code"
                disabled={view}
                value={data.code}
                error={errors["code"]}
                required
                onChange={(v) => set("code", v)}
              />
              <InputText
                label="Printable name"
                placeholder="Printable name"
                disabled={view}
                value={data.printable_name}
                error={errors["printable_name"]}
                required
                onChange={(v) => set("printable_name", v)}
              />
              <InputTextBox
                label="General description"
                placeholder="General description"
                disabled={view}
                value={data.description}
                error={errors["description"]}
                required
                onChange={(v) => set("description", v)}
              />
            </div>
            <div className="p-5" />
            <DesTable
              data={data.horoscope_json}
              setData={(v) => set("horoscope_json", v)}
            />
            <div className="p-5" />
            <div id="form">
              <InputDate
                label="Horoscope date"
                placeholder="Horoscope date"
                type="datetime-local"
                disabled={view}
                value={data.horoscope_date}
                error={errors["horoscope_date"]}
                required
                onChange={(v) => set("horoscope_date", v)}
              />
              <InputToggle
                label="Active"
                disabled={view}
                value={data.is_active}
                error={errors["is_active"]}
                onChange={(v) => set("is_active", v)}
              />
            </div>
            <div style={{ padding: "10px" }} />
          </form>
        </div>
        <div className="modal-actions">
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="form-wrapper"
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const DesTable = ({ data, setData }) => {
  const addNew = () => {
    setData([...data, { id: generateUID() }]);
  };
  return (
    <div className="ps-section">
      <div style={{ fontWeight: "bold" }}>Horoscope description</div>
      <button
        type="button"
        className="button"
        style={{ marginLeft: "auto", padding: "5px 10px" }}
        onClick={() => addNew()}
      >
        Add New
      </button>
      <div style={{ padding: "5px" }} />
      <TableComponent
        celled
        disableHeaderPopup
        showbottom={false}
        headers={["Category", "Description", "Action"].map((h) => (
          <div className="center">{h}</div>
        ))}
        body={data.map((d, idx) => [
          <div style={{ minWidth: "150px" }}>
            <InputText
              placeholder="eg. Love, Career"
              value={d.category ? d.category : ""}
              onChange={(v) => {
                const newData = [...data];
                newData[idx].category = v;
                setData(newData);
              }}
            />
          </div>,
          <div style={{ minWidth: "150px" }}>
            <InputTextBox
              placeholder="Description"
              value={d.description ? d.description : ""}
              onChange={(v) => {
                const newData = [...data];
                newData[idx].description = v;
                setData(newData);
              }}
            />
          </div>,
          <div className="center">
            <div
              className="i-btn btn-red"
              onClick={() => {
                if (data.length > 1) {
                  setData(data.filter((x) => x.id !== d.id));
                } else {
                  setData([{ id: generateUID() }]);
                }
              }}
            >
              <DeleteIcon />
            </div>
          </div>,
        ])}
      />
    </div>
  );
};

export default NewHoroscopeDescription;
